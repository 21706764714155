import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';

import { EventsService } from '@coreui//services/event.service'
import { UILayout } from '@coreui//services/ui.layout.service';


@Component({
	selector: 		'appbar-routes',
	inputs: 			['route'],
	encapsulation: ViewEncapsulation.None,

	template:	`

			<div [ngSwitch]=this.uiLayout.windowClass>

				<div *ngSwitchCase="'compact'">
					<sp-appbar-compact></sp-appbar-compact>
				</div>

				<div *ngSwitchCase="'medium'">
					<sp-appbar-medium></sp-appbar-medium>
				</div>

				<div *ngSwitchCase="'expanded'">
					<sp-appbar-medium></sp-appbar-medium>
				</div>
				

			</div>

	`,

})


export class AppbarRoutes {

	uiLayout: UILayout
	private eventsService: EventsService
	_appbarRoutes: string = ""

	private setRoute(route: string): void {
		setTimeout(() => {
			setTimeout(() => {
				this._appbarRoutes = route
			}, 150);
		}, 10);
	}

	constructor(private _eventsService: EventsService, private _uiLayout: UILayout) {
		this.eventsService = _eventsService
		this.uiLayout = _uiLayout
		this.uiLayout.windowClass

		let self = this;
		this.eventsService.on('onRefresh', function (args: any) {
		});
	}

	ngOnInit() {
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {

	}


}
