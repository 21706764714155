import { HttpClient,  HttpResponse, HttpHeaders, HttpEvent, HttpErrorResponse, HttpEventType, HttpRequest} from  '@angular/common/http';
import { Observable, Subject, ReplaySubject, from, of, range, timer, interval  } from 'rxjs';
import { takeWhile, take } from "rxjs/operators";
import { map, filter, switchMap } from 'rxjs/operators';


export class SkyAIDOMService {

  private http: HttpClient;

  private _apiURL: string = "";
  private _apikey: string = "";

  private presigned_url_get_endpoint:   string = "/dom/presigned_url_get";
  private presigned_url_put_endpoint:   string = "/dom/presigned_url_put";
  private add_object_endpoint:   string = "/object";
  private get_all_objects_endpoint:   string = "/objects";
  private trash_objects_endpoint:   string = "/trash_objects";


  constructor(_http: HttpClient) {
    this.http = _http;
  }

  init(apiurl: string, apikey: string) {
    this.apiURL = apiurl;
    this.apikey = apikey;
  }



  presignedurlGet(filename: string, bucket: string): Observable<any> {
    let dat = {
      apikey:       this.apikey,
      filename:     filename,
      bucket:       bucket
    };
    let body = JSON.stringify(dat);
    return this.http.post(this.apiURL + this.presigned_url_get_endpoint, body, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response'
    })
  }

  presignedurlPut(filename: string, bucket: string): Observable<any> {
    let dat = {
      apikey:       this.apikey,
      filename:     filename,
      bucket:       bucket
    };
    let body = JSON.stringify(dat);
    return this.http.post(this.apiURL + this.presigned_url_put_endpoint, body, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response'
    })
  }


  addObject(id: string, objectid: string, objecttype: string, ext: string, size: number): Observable<any> {
    let dat = {
      id:           id,
      apikey:       this.apikey,
      objectid:     objectid,
      objecttype:   objecttype,
      size:         size,
      ext:          ext
    };
    let body = JSON.stringify(dat);
    return this.http.put(this.apiURL + this.add_object_endpoint, body, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response'
    })
  }


  getAllObjects(id: string): Observable<any> {
    let dat = {
      id:           id,
      apikey:       this.apikey,
    };
    let body = JSON.stringify(dat);
    return this.http.post(this.apiURL + this.get_all_objects_endpoint, body, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response'
    })
  }


  trashObjects(id: string, objects: any[]): Observable<any> {
    let dat = {
      id:           id,
      apikey:       this.apikey,
      objects:      objects
    };
    let body = JSON.stringify(dat);
    return this.http.post(this.apiURL + this.trash_objects_endpoint, body, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response'
    })
  }

  public get apiURL(): string {
    return this._apiURL;
  }
  public set apiURL(value: string) {
    this._apiURL = value;
  }
  public get apikey(): string {
    return this._apikey;
  }
  public set apikey(value: string) {
    this._apikey = value;
  }

}
