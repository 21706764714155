import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter,
	HostListener,
	ChangeDetectionStrategy
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'
import { UIColorModel } from '@coreui/services/ui.color.model.service';
import { UINavigation } from '@coreui/services/ui.navigation.service';


@Component({
	selector: 		'sp-sidenav-drawer',
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
	<slider-sheet [direction]="'right'" [size]="320">
      <div right>
        <div class="_sp-navigation-drawer" surfaceContainerLow>
          <div class="header">
			<img [src]="'./assets/spiritans/logos/Spiritan-Logo-Full.png'"/>
		  </div>
		  <div class="menu-container">
			<sp-sidenav-menu-item [id]="'home'" [label]="'Home'" (clicked)=menuClicked($event)></sp-sidenav-menu-item>
			<sp-sidenav-menu-item [id]="'who-we-are'" [label]="'About Us'" (clicked)=menuClicked($event)></sp-sidenav-menu-item>
			<sp-sidenav-menu-item [id]="'home'" [label]="'What We Do'"></sp-sidenav-menu-item>
			<sp-sidenav-menu-item [label]="'Where We Serve'"></sp-sidenav-menu-item>
			<sp-sidenav-menu-item [label]="'Members'"></sp-sidenav-menu-item>
		  </div>
        </div>
      </div>
    </slider-sheet>		

		`,
})


export class SidenavDrawerComponent {

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	uiColorModel: UIColorModel
	uiNavigation: UINavigation
	_surfaceColor: string = ""

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel, private _uiNavigation: UINavigation) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
		this.uiNavigation = _uiNavigation


		let self = this
		this.eventsService.on('onRefresh', function (args: any) {
			self._surfaceColor = self.uiColorModel.surface
		});
	  
	}

	menuClicked($event: any) {
		this.uiNavigation.activeView = $event
		setTimeout(() => {
			this.eventsService.broadcast('onHideSliderSheet', null);
			this.ref.detectChanges();
		}, 150);
	}

	ngOnInit() {
		this._surfaceColor = this.uiColorModel.surface
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
