import {
	Component,
	ViewEncapsulation,
	ViewChild,
	ElementRef,
	HostListener,
	ChangeDetectorRef,
	Input
} from '@angular/core';

import { EventsService } from '../../services/event.service'
import { UILayout } from '../../services/ui.layout.service'

@Component({
	selector: 		'simple-container',
	encapsulation: ViewEncapsulation.None,
	template:	`
		<div class="_application-layout-container" backgroundColor>

			<div class="_background-layer">
				<ng-content select="[background-layer]"></ng-content>
			</div>

			<div class="_centre-wrapper">

				<div class="main-body">

					<div class="_application-body">

						<div class="_body-container">
							<div class="_main-body">
								<ng-content select="[body]"></ng-content>
							</div>
						</div>
	
					</div>	
				
				</div>
			
			</div>

		</div> 
	`,

})


export class SimpleApplicationContainer {

	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		let width = window.innerWidth
		this.uiLayout.detectDeviceWindowClass(width)
		this.uiLayout.detectDeviceWindowMargins()
		this.eventsService.broadcast("onResize", {})
		this.changeDetectorRef.detectChanges()
	}

	@HostListener('document:click', ['$event']) onDocumentClick($event: any) {
		this.eventsService.broadcast("onDocumentClick", 
			{screenX: $event.screenX,
			 screenY: $event.screenY,
			 clientX: $event.clientX,
			 clientY: $event.clientY}
		)
	  }

	private changeDetectorRef: ChangeDetectorRef
    private eventsService: EventsService
	uiLayout: UILayout

	


	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService, private _uiLayout: UILayout) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiLayout = _uiLayout
		let width = window.innerWidth
		this.uiLayout.detectDeviceWindowClass(width)
		this.uiLayout.detectDeviceWindowMargins()

		let self = this;
		this.eventsService.on('onRefresh', function (args: any) {
			let width = window.innerWidth
			self.uiLayout.detectDeviceWindowClass(width)
			self.uiLayout.detectDeviceWindowMargins()
			self.changeDetectorRef.markForCheck()
		});
	}

	ngOnInit() {
		let width = window.innerWidth
		this.uiLayout.detectDeviceWindowClass(width)
		this.uiLayout.detectDeviceWindowMargins()
		this.changeDetectorRef.detectChanges()	
	}

	ngOnDestroy() {
	}



}
