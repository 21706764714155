import {
  Component,
  ViewEncapsulation,
  ElementRef,
  Input,
  ChangeDetectorRef,
  NgZone,
  Inject,
  Renderer2,
} from '@angular/core';

import { EventsService } from '../../services/event.service';

@Component({
  selector: 'slider-sheet',
  encapsulation: ViewEncapsulation.None,
  inputs: ['direction', 'size'],

  template: `
    <scrim-layer (clicked)=clickEvent($event) [close]=_closeScrim>
      <div
        *ngIf="directionValue == 'left'"
        class="slider-sheet left"
        [style.width.px]="sizeValue"
        [style.left.px]="movePixels"
        
      >
        <div class="slider-sheet-wrapper left" 
			[style.height.px]="screenHeight"
			surfaceContainer>
          <ng-content select="[left]"></ng-content>
        </div>
      </div>
	  

      <div
        *ngIf="directionValue == 'right'"
        class="slider-sheet right"
        [style.width.px]="sizeValue"
        [style.right.px]="movePixels"
      >
        <div class="slider-sheet-wrapper right" [style.height.px]="screenHeight"
			surfaceContainer>
          <ng-content select="[right]"></ng-content>
        </div>
      </div>

      <div
        *ngIf="directionValue == 'top'"
        class="slider-sheet top"
        surface-color
      >
        <div
          class="slider-sheet-wrapper top"
          [style.height.px]="sizeValue"
        >
          <ng-content select="[top]"></ng-content>
        </div>
      </div>

      <div
        *ngIf="directionValue == 'bottom'"
        class="slider-sheet bottom"
        [style.bottom.px]="movePixels"
        surface-color
      >
        <div
          class="slider-sheet-wrapper bottom"
          [style.height.px]="sizeValue"
        >
          <ng-content select="[bottom]"></ng-content>
        </div>
      </div>
    </scrim-layer>
  `,
})
export class SliderSheetLayer {
  @Input()
  set direction(value: string) {
    this.setDirection(value);
  }
  set size(value: number) {
    this.setSize(value);
  }

  closeIcon: string = 'dots-vertical';

  screenHeight: number = 100;
  screenWidth: number = 200;

  sizeValue: number = 0;
  newMovePixels: number = 200;
  movePixels: number = 0
  directionValue: string = 'left';
  _closeScrim: boolean = false

  eventsService: EventsService;

  constructor(
    private _eventsService: EventsService,
    public ref: ChangeDetectorRef
  ) {
    this.eventsService = _eventsService;

    let self = this;
    this.eventsService.on('onHideSliderSheet', function (args: any) {
      self.closeSequence();
    });
  }

  closeSequence() {
    this.movePixels = this.newMovePixels;
    this._closeScrim = true
    setTimeout(() => {
      this.eventsService.broadcast('onHideModalOverlay', null);
      this.ref.detectChanges();
    }, 400);
  }

  clickEvent($event: any) {
    if (
      this.isOutOfBounds(
        $event.x,
        $event.y,
        this.directionValue,
        this.sizeValue
      )
    ) {
      this.closeSequence();
    }
  }

  //detect bounds
  isOutOfBounds(x: number, y: number, dir: string, size: number) {
    let isOut = false;
    let windowWidth = window.innerWidth;
    let windowHeight = window.innerHeight;
    //left side
    if (dir == 'left') {
      if (x > size) {
        isOut = true;
      }
    }
    //right side
    if (dir == 'right') {
      if (x < windowWidth - size) {
        isOut = true;
      }
    }
    //bottom
    if (dir == 'bottom') {
      if (y < windowHeight - size) {
        isOut = true;
      }
    }

    if (dir == 'top') {
      if (y > windowHeight - size) {
        isOut = true;
      }
    }

    return isOut;
  }

  setDirection(value: string) {
    this.directionValue = value;
  }

  setSize(value: number) {
	this.sizeValue = value
	this.newMovePixels = value * -1
  }

  updateComponent() {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.ref.detectChanges();
  }

  ngOnInit() {
    this._closeScrim = false
    this.movePixels = this.newMovePixels;
    setTimeout(() => {
      this.movePixels = 0;
      this.ref.detectChanges();
    }, 10);

    this.updateComponent();
    setTimeout(() => {
      this.ref.detectChanges();
    }, 50);
  }
}
