import {
	Component,
	ViewEncapsulation,
	ChangeDetectorRef,

	ViewChild,
	ChangeDetectionStrategy
} from '@angular/core';

import { UILayout } from '@coreui/services//ui.layout.service'
import { EventsService } from '@coreui/services/event.service'
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { TypographyModel } from '@coreui/services/typography.service';

import { TabsSet } from '@coreui/classes/navigation/tabs.class';


@Component({
	selector: 		'technical-document-page-01',
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
	<content-container-scrollable>

		<div class="_technical-document-page">
			<div class="heading" onSurface>Technical Document Page</div>
			<div class="subheading" onSurface>A compelling description about the product or service</div>

			<div class="body">
				<tabs-m3-iconlabel [data]=tabs1></tabs-m3-iconlabel>

				<markdown clipboard bash
						[prompt]="'/>'"
						[data]=markdown>
				</markdown>

				<markdown clipboard css
						[data]=css>
				</markdown>

			</div>
		</div>	



	</content-container-scrollable>

		`,
})


export class TechnicalDocumentPageTemplate {


	private changeDetectorRef: ChangeDetectorRef
	private uiLayout: UILayout
	private uiNavigation: UINavigation
    private eventsService: EventsService
	private typographyModel: TypographyModel

	tabs1: TabsSet = new TabsSet()
	markdown: string = ""
	css: string = ""

	
	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService, 
		private _uiLayout: UILayout,
		private _uiNavigation: UINavigation,
		private _typographyModel: TypographyModel) {
		this.uiLayout = _uiLayout
		this.eventsService = _eventsService
		this.uiNavigation = _uiNavigation
		this.typographyModel = _typographyModel
		this.changeDetectorRef = ref

		this.defineTabs()
		this.markdownInfo()
	}


	defineTabs() {
		this.tabs1.id = "tabs1"
	  	this.tabs1.labelOnly = false
		this.tabs1.addIconLabel("html", "HTML", "language-html5", "language-html5")
		this.tabs1.addIconLabel("scss", "SCSS", "language-css3", "language-css3")
		this.tabs1.addIconLabel("typescript", "Typescript", "language-typescript", "language-typescript")
		this.tabs1.addIconLabel("examples", "Examples", "devices", "devices")
	  	this.tabs1.setActive("html", true)
	  }


	markdownInfo() {
		this.markdown = `
		\`\`\`bash
		lsof -t -i tcp:4200 | xargs kill -9
		\`\`\` `
  

		this.css = `
		\`\`\`css
		.std-button {
			border: none;
			border-radius: 4px;
			display: inline-block;
			height: 36px;
			line-height: 36px;
			padding: 0 2rem;
			background-color: #00cccc;
			color: #ffffff;
			white-space: nowrap;
			font-family: Arial, Helvetica, sans-serif;
		  
			//gt rid of text selection
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		  
			box-sizing: border-box;
		  
			// font
			vertical-align: middle;
			font-size: 16px;
		  
			// Gets rid of tap active state
			-webkit-tap-highlight-color: transparent;
		  
			cursor: pointer;
		  
			i {
			  font-size: 32px;
			  line-height: 36px;
			  height: 36px;
			}
		  
			&.disabled {
			  opacity: 0.5;
			  pointer-events: none;
			}
		  }
				 `


	}

	ngOnInit() {
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
