import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/////////////////////////////////////////////////////////
// Core
////////////////////////////////////////////////////////
import { CoreUIModule } from '@coreui/coreui.module'

import { ColorSwatchCardComponent } from './components/palette/color.swatch.component';
import { GradientSwatchComponent } from './components/gradient/gradient.swatch.component';
import { TonalColorListComponent } from './components/tones/tonal.color.list';

@NgModule({
  declarations: [
    ColorSwatchCardComponent,
    GradientSwatchComponent,
    TonalColorListComponent
  ],

  imports: [
    CommonModule,
    CoreUIModule
  ],

  providers: [
  ],

  exports: [
    ColorSwatchCardComponent,
    GradientSwatchComponent,
    TonalColorListComponent
  ]
})
export class ColorSwatchesModule { }
