import { HttpClient,  HttpResponse, HttpHeaders, HttpEvent, HttpErrorResponse, HttpEventType, HttpRequest} from  '@angular/common/http';
import { Observable, Subject, ReplaySubject, from, of, range, timer, interval  } from 'rxjs';
import { takeWhile, take } from "rxjs/operators";
import { map, filter, switchMap } from 'rxjs/operators';


export class DpLocationV1 {

  private http: HttpClient;
  private _apiURL: string = "";
  private _apikey: string = "";

  private new_location_endpoint:   string = "/dp/v1/location/location";


  constructor(_http: HttpClient) {
    this.http = _http;
  }

  init(apiurl: string, apikey: string) {
    this.apiURL = apiurl;
    this.apikey = apikey;
  }


  newLocation(refid: string, locationRecord: any): Observable<any> {
    let dat = {
      apikey: this.apikey,
      refid: refid,
      data:  locationRecord
    };
    let body = JSON.stringify(dat);
    return this.http.put(this.apiURL + this.new_location_endpoint, body, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response'
    })
  }



  public get apiURL(): string {
    return this._apiURL;
  }
  public set apiURL(value: string) {
    this._apiURL = value;
  }
  public get apikey(): string {
    return this._apikey;
  }
  public set apikey(value: string) {
    this._apikey = value;
  }

}
