
export class NavigationDrawerMenu {

    private _id: string = ""
    private _destinations: any = []
    private _isRootMenu: boolean = true
    private _parentId: string = ""
    
    constructor() {}

    public addDestination(destination: string, label: string, activeIcon: string,
                            inactiveIcon: string) {
        let rec = {
            id: "nr-" + destination,
            destination: destination,
            label: label,
            icon: inactiveIcon,
            activeIcon: activeIcon,
            inactiveIcon: inactiveIcon,
            secondary: "",
            isDisabled: false,
            isHovered: false,
            isPressed: false
        }
        this._destinations.push(rec)
    }

    //Disable/Enable
    public setDisabled(id: string, isDiabled: boolean) {
        let i = 0
        for(i=0;i<this._destinations.length;i++) {
            if(this._destinations[i]["id"] == id) {
                this._destinations[i]["isDisabled"] = isDiabled
            } 
        }
    }



    public setPressed(id: string, isPressed: boolean) {
        let i = 0
        for(i=0;i<this._destinations.length;i++) {
            if(this._destinations[i]["id"] == id) {
                this._destinations[i]["isPressed"] = isPressed
                if(isPressed==true) {
                    this._destinations[i]["icon"] = this._destinations[i]["activeIcon"]
                } else {
                    this._destinations[i]["icon"] = this._destinations[i]["inactiveIcon"]
                }
            } 
        }
    }
    public unPressAll() {
        let i = 0
        for(i=0;i<this._destinations.length;i++) {
            this._destinations[i]["isPressed"] = false
            this._destinations[i]["icon"] = this._destinations[i]["inactiveIcon"]
        }
    }
    public makePressed(id: string, isPressed: boolean) {
        this.unPressAll()
        let dest: string[] = id.split(".")
        let current: string = ""
        let i=0
        for(i=0;i<dest.length;i++) {
            if(i==0) {
                current = dest[i]
            } else {
                current = current + "." + dest[i]
            }
            this.setPressed(current, isPressed)
        }
    }

    
    public isPressed(id: string): boolean {
        let result: boolean = false
        let i = 0
        for(i=0;i<this._destinations.length;i++) {
            if(this._destinations[i]["id"] == id) {
                result = this._destinations[i]["isPressed"]
            } 
        }
        return result
    }




    public makeHover(id: string, isHovered: boolean) {
        let dest: string[] = id.split(".")
        let current: string = ""
        let i=0
        for(i=0;i<dest.length;i++) {
            if(i==0) {
                current = dest[i]
            } else {
                current = current + "." + dest[i]
            }
            this.setHover(current, isHovered)
        }
    }

    public setHover(id: string, isHovered: boolean) {
        let i = 0
        for(i=0;i<this._destinations.length;i++) {
            if(this._destinations[i]["id"] == id) {
                this._destinations[i]["isHovered"] = isHovered
            } 
        }
    }
    public unHoverAll() {
        let i = 0
        for(i=0;i<this._destinations.length;i++) {
            this._destinations[i]["isHovered"] = false
        }
    }


    public isHovered(id: string): boolean {
        let result: boolean = false
        let i = 0
        for(i=0;i<this._destinations.length;i++) {
            if(this._destinations[i]["id"] == id) {
                result = this._destinations[i]["isHovered"]
            } 
        }
        return result
    }

    public setEnabled(id: string, isEnabled: boolean) {
        let i = 0
        for(i=0;i<this._destinations.length;i++) {
            if(this._destinations[i]["id"] == id) {
                this._destinations[i]["isEnabled"] = isEnabled
            } else {
                this._destinations[i]["isEnabled"] = false
            }
        }
    }
    public isEnabled(id: string): boolean {
        let result: boolean = false
        let i = 0
        for(i=0;i<this._destinations.length;i++) {
            if(this._destinations[i]["id"] == id) {
                result = this._destinations[i]["isEnabled"]
            } 
        }
        return result
    }

    public setIcon(id: string, icon: string) {
        let i = 0
        for(i=0;i<this._destinations.length;i++) {
            if(this._destinations[i]["id"] == id) {
                this._destinations[i]["icon"] = icon
            }
        }
    }
    public setLabel(id: string, label: string) {
        let i = 0
        for(i=0;i<this._destinations.length;i++) {
            if(this._destinations[i]["id"] == id) {
                this._destinations[i]["label"] = label
            }
        }
    }




    public getDestination(id: string): string {
        let destination: string = ""
        let i = 0
        for(i=0;i<this._destinations.length;i++) {
            if(this._destinations[i]["id"] == id) {
                destination = this._destinations[i]["destination"]
            }
        }
        return destination
    }


    /////////////////////////////////////////////////////////////////
    // SUB MENU
    ////////////////////////////////////////////////////////////////
    public getTopLevelItems(): any {
        let result: any = []
        let i = 0
        for(i=0;i<this._destinations.length;i++) {
            if(this._destinations[i]["id"].includes(".") == false) {                
                result.push(this._destinations[i])
            } 
        }
        return result
    }

    public hasSubmenu(id: string): boolean {
        let result: boolean = false
        let i = 0
        for(i=0;i<this._destinations.length;i++) {
            if(this._destinations[i]["id"].startsWith(id)) {
                if(this._destinations[i]["id"].charAt(id.length) == ".") {
                    result = true
                }
            } 
        }
        return result
    }


    public getSecondLevelMenu(id: string): any {
        let result: any = []
        let i = 0
        for(i=0;i<this._destinations.length;i++) {
            if(this._destinations[i]["id"].startsWith(id)) {
                if(this._destinations[i]["id"].includes(".")) {
                    let split: string[] = []
                    split = this._destinations[i]["id"].split(".")
                    if((split.length-1) == 1) {
                        result.push(this._destinations[i])
                    }
                }
            } 
        }
        return result
    }
 
    public getThirdLevelItems(id: string): any {
        let result: any = []
        let i = 0
        for(i=0;i<this._destinations.length;i++) {
            if(this._destinations[i]["id"].startsWith(id)) {
                if(this._destinations[i]["id"].includes(".")) {
                    let split: string[] = []
                    split = this._destinations[i]["id"].split(".")
                    if((split.length-1) == 2) {
                        result.push(this._destinations[i])
                    }
                }
            } 
        }
        return result
    }


    public getRootParent(id: string): string {
        let result: string = ""
        let item: string[] = id.split(".")
        result = item[0]
        return result
    }
 

    public get id(): string {
        return this._id
    }
    public set id(value: string) {
        this._id = value
    }
    public get destinations(): any {
        return this._destinations
    }
    public set destinations(value: any) {
        this._destinations = value
    }
    public get isRootMenu(): boolean {
        return this._isRootMenu
    }
    public set isRootMenu(value: boolean) {
        this._isRootMenu = value
    }
    public get parentId(): string {
        return this._parentId
    }
    public set parentId(value: string) {
        this._parentId = value
    }

}