import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  ChangeDetectorRef,
  Inject,
  NgZone,
  OnInit,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'sbutton-large',
  inputs: ['label', 'icon', 'color', 'radius', 'textcolor', 'fullwidth'],
  outputs: ['clicked'],
  changeDetection: ChangeDetectionStrategy.Default,

  template: `
    <div
      class="std-button-large background-color-transition-350 
        {{ btnPressEffect }}
        {{btnWidthCSS}}"
      [style.background]="btnColor"
      [style.color]=txtColor
      (click)="btnClicked()"
      [style.borderRadius.px]="btnRadius"
      font-family
	    elevated [elevation]=1 >
      <span
        *ngIf="btnIcon != ''"
        class="i mdi mdi-{{ btnIcon }} {{ spinClass }}"
      ></span>
      <span [style.marginLeft.px]="txtMarginLeft">{{ this.btnLabel }}</span>
    </div>
  `,
})
export class StdButtonLargeComponent {
  @Output()
  clicked = new EventEmitter();

  @Input()
  set label(value: string) {
    this.setLabel(value);
  }
  set color(value: string) {
    this.setColor(value);
  }
  set icon(value: string) {
    this.setIcon(value);
  }
  set textcolor(value: string) {
    this.setTextColor(value);
  }
  set radius(value: number) {
    this.setRadius(value);
  }
  set fullwidth(value: boolean) {
    this.setFullWidth(value);
  }

  changeDetectorRef: ChangeDetectorRef;

  constructor(public ref: ChangeDetectorRef) {
    this.changeDetectorRef = ref;
  }

  btnLabel: string = '';
  btnIcon: string = '';
  btnColor: string = 'FFEEEE';
  txtColor: string = 'FFFFFF';
  prevBtnColor: string = 'FFEEEE';
  btnPressEffect: string = '';
  btnOpacity: number = 1;
  txtMarginLeft: number = 0;
  btnWidthCSS: string = ""

  spinDirection: string = 'clock';
  spinClass: string = 'anim-spin-clockwise';
  btnRadius: number = 18;

  setLabel(value: string) {
    this.btnLabel = value;
  }

  setColor(value: string) {
    if (value.startsWith('linear-gradient') || value.startsWith('rgb') || value.startsWith('#')) {
      this.btnColor = value;
    } else {
      this.btnColor = '#' + value;
    }
  }

  setIcon(value: string) {
    if (this.btnIcon == 'null') {
      this.btnIcon = value;
    } else {
      this.txtMarginLeft = 8;
      this.changeIcon(value);
    }
  }

  setTextColor(value: string) {
    this.txtColor = value
  }

  setRadius(value: number) {
    this.btnRadius = value;
  }

  setFullWidth(value: boolean) {
    if(value) {
      this.btnWidthCSS = "fullwidth";
    } else {
      this.btnWidthCSS = "";
    }
  }

  btnClicked() {
    this.btnPressEffect = 'button-press-animation';
    this.clicked.emit(true);
    setTimeout(() => {
      this.btnPressEffect = '';
    }, 250);
  }

  changeIcon(v: string) {
    if (this.spinDirection == 'clock') {
      this.spinClass = 'anim-spin-clockwise';
      this.ref.markForCheck();
      setTimeout(() => {
        this.btnIcon = v;
        this.spinClass = '';
      }, 150);
    } else {
      this.spinClass = 'anim-spin-counterclockwise-150';
      this.ref.markForCheck();
      setTimeout(() => {
        this.btnIcon = v;
        this.spinClass = '';
      }, 150);
    }
  }

  ngOnInit() {}

  ngOnChanges() {}

  ngOnDestroy() {}
}
