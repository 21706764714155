import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter,
	HostListener,
	ChangeDetectionStrategy
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'
import { UIColorModel } from '@coreui/services/ui.color.model.service';
import { ColorUtils } from "@coreui/style/color/utils/color.utils.class"

@Component({
	selector: 		'la-sidenav-menu-item',
	inputs: ['id', 'label'],
	outputs: ['clicked', 'hoverenter', 'hoverexit'],
	changeDetection: ChangeDetectionStrategy.Default,
	template: 	`

		<li class="_sp-sn-menu-item" font-family 
			[style.background]=_highlightColor
			(mouseenter)=stateHoveredActive()
			(mouseleave)=stateEnabledActive()
			(click)=handleClick()>
          <div class="label" onSurface>{{_label}}</div>
        </li>

		`,
})


export class SidenavMenuItemComponent {
	@Output()
	clicked = new EventEmitter();
	hoverenter = new EventEmitter();
	hoverexit = new EventEmitter();

	@Input()
	set id(value: string) {
	  this.setId(value);
	}
	set label(value: string) {
		this.setLabel(value);
    }
	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
    private colorUtils: ColorUtils = new ColorUtils()
	uiColorModel: UIColorModel
	
	_highlightColor: string = "rgba(0,0,0,0)"
	_textColor: string = "rgba(0,0,0,0)"

	_id: string = ""
	_label: string = ""
	_touchAnimCSS: string = ""

	_fontWeight: number = 500
	_isActive: boolean = false



	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
	  
		let self = this
		this.eventsService.on('onRefresh', function (args: any) {
			if(self._isActive) {
				self._highlightColor = self.uiColorModel.secondaryContainer
				self._textColor = self.uiColorModel.onSurface
				self._fontWeight = 600
			} else {
				self._highlightColor = "rgba(0,0,0,0)"
				self._textColor = self.uiColorModel.onSurface
			}
	
		});
	}

	setId(value: string) {
		this._id = value
	}


	setLabel(value: string) {
		this._label = value
	}

	setActive(value: boolean) {
		if(value == true) {
			this.statePressedActive()
		} else {
			this.stateDeActive()
		}
	}


	handleClick() {
		this.clicked.emit(this._id)
		setTimeout(() => {
			this.eventsService.broadcast("onHoverExitMenuItem", {})
		}, 1000);

	}

	stateEnabledActive() {
		if(this._isActive != true) {
			this._highlightColor = "rgba(0,0,0,0)"
			this._textColor = this.uiColorModel.onSecondaryContainer
			this._fontWeight = 500
			this.hoverexit.emit(this._id)
		}
	}

	stateHoveredActive() {
		if(this._isActive != true) {
			this._highlightColor = this.uiColorModel.surfaceVariant
			this._textColor = this.uiColorModel.onSurfaceVariant
			this._fontWeight = 600
			this.hoverenter.emit(this._id)
		}
	}

	stateFocusedActive() {
		this._highlightColor = this.uiColorModel.surface
		this._textColor = this.uiColorModel.onSurface
	}

	statePressedActive() {
		this._touchAnimCSS = "nav-rail-icon-touch-animation"
		this._isActive = true
		this._highlightColor = this.uiColorModel.secondaryContainer
		this._textColor = this.uiColorModel.onSurface
		this._fontWeight = 600

		setTimeout(() => {
			this._touchAnimCSS = ""
	    }, 500);

	}

	stateDeActive() {
		this._isActive = false
		this.stateEnabledActive()
	}


	stateEnabledInActive() {
	}


	hoverState() {

	}



	onHoverOn() {
		this._highlightColor = this.uiColorModel.surfaceVariant
		this._textColor = this.uiColorModel.onSurface
	}

	onHoverOff() {
		this._highlightColor = "rgba(0,0,0,0"
		this._textColor = this.uiColorModel.onSecondaryContainer
	}

	ngOnInit() {
		this._highlightColor = "rgba(0,0,0,0"
		this._textColor = this.uiColorModel.onSecondaryContainer
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
