import {
	Component,
	ViewEncapsulation
} from '@angular/core';

import { ApplicationService } from '../services/application.service';
import { EventsService } from '@coreui/services/event.service'
import { UINavigation } from '@coreui/services/ui.navigation.service';

@Component({
	selector: 		'modal-contaimer',
	encapsulation: ViewEncapsulation.None,

	template:	`

			<div [ngSwitch]=this._modal>

				<div *ngSwitchCase="'sidenav-drawer'">
					<sp-sidenav-drawer></sp-sidenav-drawer>
				</div>

				<div *ngSwitchCase="'sample-overlay'">

				</div>

			</div>

	`,

})


export class Modals {

	_modal: string = ""
	private eventsService: EventsService
	applicationService: ApplicationService
	uiNavigation: UINavigation


	constructor(_eventsService: EventsService, 
			private _applicationService: ApplicationService,
			private _uiNavigation: UINavigation) {
		this.eventsService = _eventsService
		this.applicationService = _applicationService
		this.uiNavigation = _uiNavigation

		let self = this;
		self.eventsService.on('onShowModalOverlay', function (args: any) {
			self._modal = args.id
		});

		self.eventsService.on('onHideModalOverlay', function (args: any) {
			self._modal = ""
		});

	}

	ngOnInit() {
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {

	}



}
