import { ApplicationService } from '../services/application.service';
import { EventsService } from '@coreui//services/event.service'
import { UINavigation } from '@coreui/services/ui.navigation.service';

import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';

@Component({
	selector: 		'peachmarketing-webapp',
	inputs: 			['route'],
	encapsulation: ViewEncapsulation.None,

	template:	`

			<div [ngSwitch]=this._route>

				<div *ngSwitchCase="'home'">
					<peachmarketing-main></peachmarketing-main>
				</div>

				<div *ngSwitchCase="'signup'">
				</div>

				<div *ngSwitchCase="'signin'">
					<signin-screen></signin-screen>
				</div>

			</div>

	`,

})


export class TopLevelRoutes {
	@Input()
    set route(value: string) { this.setRoute(value); }

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	applicationService: ApplicationService
	private uiNavigation: UINavigation

	_route: string = "signin"
	_opacity: number = 1

	private setRoute(route: string): void {
		setTimeout(() => {
			this._opacity = 0
			setTimeout(() => {
				this._route = route
				this._opacity = 1
			}, 150);
		}, 10);
	}

	constructor(public ref: ChangeDetectorRef,
				private _applicationService: ApplicationService, 
				private _eventsService: EventsService,
				private _uiNavigation: UINavigation) {
		this.changeDetectorRef = ref		
		this.applicationService = _applicationService
		this.eventsService = _eventsService
		this.uiNavigation = _uiNavigation

		let self = this;
		self.eventsService.on('onSignIn', function (args: any) {
			self._route = "home"
			self.uiNavigation.activeView = "home"
		});
		self.eventsService.on('onSignOut', function (args: any) {
			self._route = "signin"
		});

	}


	ngOnInit() {
		this._route = "home"
		this.uiNavigation.activeView = "home"

	}

	ngOnDestroy() {
	} 

	ngAfterViewInit() {

	}


}
