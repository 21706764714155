import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';
import { HttpClient } from  '@angular/common/http'


import { EventsService } from '@coreui/services/event.service'
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { SkyAISessionService } from '@libraries/skyai-api/api/skyai.session.class'

@Component({
	selector: 		'signin-modal',
	encapsulation: ViewEncapsulation.None,

	template:	`

        <modal [percentage]=false [width]=400 [height]=480 [differentcompact]=true
				[compactwidth]=90 [compactheight]=90 [compactpercentage]=true>
        
			<div class="_signin-flow-container">
				<div class="header">
					<div class="_title-medium" font-family onSurface>Sign In</div>
				</div>
				<div class="body">
					<text-input-filled [label]="'Email'"
						(value)=getUsernameValue($event)
						[trailingicon]="'close-circle-outline'" 
						[supportingtext]=siginSupportingText
						[errormode]=siginErrorMode>
					</text-input-filled>
					<password-input-filled (value)=getPasswordValue($event)
						[supportingtext]=passwordSupportingText
						[errormode]=passwordErrorMode
					>
					</password-input-filled>
					<text-button [label]="'Forgot Password?'"></text-button>
				</div>
				<div class="action-wrapper">
					<tonal-button [label]="'Sign In'" [fullwidth]=true (clicked)=signInClick()></tonal-button>
				</div>
			</div>
        </modal>

	`,

})


export class SignInModalFlow {

	
	private eventsService: EventsService
	uiNavigation: UINavigation

	siginSupportingText: string = ""
	siginErrorMode: boolean = false
	passwordSupportingText: string = ""
	passwordErrorMode: boolean = false

	usernameValue: string = ""
	passwordValue: string = ""
	private skyAISessionService: SkyAISessionService

	constructor(_eventsService: EventsService, _http: HttpClient,
			private _uiNavigation: UINavigation) {
		this.eventsService = _eventsService
		this.uiNavigation = _uiNavigation
		this.skyAISessionService = new SkyAISessionService(_http)
		this.skyAISessionService.init("https://pg8.in/pm/v1", "skyai_root.FVsaDg1rQEIAcRpqQEukPkqxjeOmPb8NeJgn9Sef")
		//FVsaDg1rQEIAcRpqQEukPkqxjeOmPb8NeJgn9Sef
	
	}

	ngOnInit() {
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {

	}

	getUsernameValue(value: string) {
		this.usernameValue = value
	}

	getPasswordValue(value: string) {
		this.passwordValue = value
	}

	signInClick() {
		let rec = this.validatePassword(this.passwordValue)
		if(rec.isvalid == false) {
			this.passwordSupportingText = rec.reason
			this.passwordErrorMode = true

	
			this.skyAISessionService.signin(this.usernameValue, this.passwordValue).subscribe(
				value => {
					console.log(JSON.stringify(value.body.data, null, 4))
					this.skyAISessionService.sessionRegisterSuccessful(value.body.data.session)
					this.eventsService.broadcast("onSignIn", {data: this.skyAISessionService})
				}, 
				err => {
					console.log(err)
					console.log("Error contacting server")
				})

		} else {
			this.passwordSupportingText = ""
			this.passwordErrorMode = false
		}
	}


	validatePassword(password: string): any {
		let isValid: boolean = true
		let reason: string = ""

		var lowerCaseLetters = /[a-z]/g;
		var upperCaseLetters = /[A-Z]/g;
		var numbers = /[0-9]/g;

		if(!password.match(lowerCaseLetters)) { 
			reason = "Password must contain at least one lowercase character"
			isValid = false
		}
		if(!password.match(upperCaseLetters)) { 
			reason = "Password must contain at least one uppercase character"
			isValid = false
		}
		if(!password.match(numbers)) { 
			reason = "Password must contain at least one number"
			isValid = false
		}
		if(password.length < 10) { 
			reason = "Password be at least 10 characters long"
			isValid = false
		}

		let returnRec = {
			isvalid: isValid,
			reason: reason
		}

		return returnRec
	}
}
