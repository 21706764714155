import {
	Component,
	Input
} from '@angular/core';


@Component({
	selector: 		'airline01-trip-details',
	inputs: ['icon', 'isdark', 'perc'],

	template: 	`
		<div class="_airline01-trip-details-card">
			<div class="media">
            	<img [src]="_imgsrc" [draggable]=false/>
				<div class="heading" font-family>Trip Details</div>
			</div>
			<div class="_card-content">
				<div class="flight-info">
					<div class="flight-summary-tile">

						<div class="flight-info-line">
							<div class="departure" onSurface font-family>9 Feb</div>
				
							<div class="flighttime" onSurface font-family>
								<i class="mdi mdi-clock-outline"></i> 2h 15m
							</div> 

							<div class="arrive" onSurface font-family>9 Feb</div>
						</div>

						<div class="flight-times" >
							<div class="origin" onSurface font-family>15:30</div>
			
							<div class="fillspace">
								<travel-icon-progress [icon]=_icon [perc]=50></travel-icon-progress>
							</div> 

							<div class="destination" onSurface font-family>18:45</div>
						</div>

						<div class="flight-info-line">
							<div class="departure" onSurface font-family>DBX, Dubai</div>
				
							<div class="flighttime" onSurface font-family>
							</div> 

							<div class="arrive" onSurface font-family>HKG, Hong Kong</div>
						</div>

					</div>
				</div>

				<div class="divider" onSurfaceBack></div>

				<ul class="list-tiles-container">
					<li class="_trip-timeline-tile">
						<div class="progress-point">
							<div class="circle"></div>
							<div class="initial" onSurfaceBack></div>
						</div>
						<div class="content">
							<div class="top-line" onSurface font-family>
								<div class="time">15:30</div> 
								<div class="info">DBX</div> 
							</div>
							<div class="bottom-line" onSurface font-family>
								<div class="time">1 Feb</div> 
								<div class="info">Dubai</div> 
							</div>
						</div>
					</li>
					<li class="_airline-info-tile">
						<div class="progress-point">
							<div class="line" onSurfaceBack></div>
						</div>
						<div class="circle-wrapper">
							<div class="circle" secondaryContainer>
								<img [src]=_logoSrc/>
							</div>
						</div>
						<div class="content">
							<div class="top-line" onSurface font-family>Qatar Airways</div>
							<div class="bottom-line" onSurface font-family>QA1234</div>
						</div>

					</li>

					
					<li class="_trip-timeline-tile">
						<div class="progress-point">
							<div class="circle">
							</div>
							<div class="final" onSurfaceBack></div>
						</div>
						<div class="content">
							<div class="top-line" onSurface font-family>
								<div class="time">18:45</div> 
								<div class="info">HKG</div> 
							</div>
							<div class="bottom-line" onSurface font-family>
								<div class="time">1 Feb</div> 
								<div class="info">Hong Kong</div> 
							</div>
						</div>
					</li>
				</ul>
			</div>

			
		</div>

	`,

})



export class Airline01TripDetailsCard {

	@Input()
	set icon(value: string) { this.setIcon(value); }
	set perc(value: number) { this.setPerc(value); }
	set isdark(value: boolean) { this.setIsDark(value); }

	_icon: string = "airplane rotate90"
	_perc: number = 50
	_isDark:	boolean = false


	_imgsrc: string = './assets/sample/media/hongkong-aerial.jpg'
	_logoSrc: string = './assets/sample/logos/QatarAirwaysRound.jpg'

	constructor() {
	}

	setIcon(value: string) {
		this._icon = value
	}

	setPerc(value: number) {
		this._perc = value
	}

	setIsDark(value: boolean) {
		this._isDark = value
	}

	ngOnInit() {
	/*	this._perc = 1
		setTimeout(() => {
			this._perc = 100
		}, 1000); */
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
