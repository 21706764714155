import {
	Component,
	ViewEncapsulation,
	ChangeDetectorRef
} from '@angular/core';

import { ApplicationService } from '../services/application.service';
import { EventsService } from '@coreui//services/event.service'
import { TypographyModel } from '@coreui/services/typography.service';
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { UILayout } from '@coreui//services/ui.layout.service';


@Component({
	selector: 		'loyalty-webapp',
	encapsulation: ViewEncapsulation.None,
	template:	`

		<simple-container>
			<div background-layer>
				<background-dynamicclouds [backgroundcolor]=bk [color1]=color1 [color2]=color2 [cloudopacity]=0.15
				[numclouds]=9 [speed]=15000 [interval]=15000></background-dynamicclouds>
			</div>

			<div body>
				<main-routes [route]=this.uiNavigation.activeView></main-routes>
			</div>

		</simple-container>

		<!-- Modals -->
		<modal-contaimer></modal-contaimer>
	`,

})


export class Main {

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	applicationService: ApplicationService
	uiNavigation: UINavigation
	uiLayout: UILayout
	private typographyModel: TypographyModel

	color1: string = "e96625"
	color2: string = "ef2832"
	bk: string = "fefefe"

	constructor(public ref: ChangeDetectorRef,
			private _applicationService: ApplicationService, 
			private _eventsService: EventsService,
			private _uiNavigation: UINavigation,
			private _uiLayout: UILayout,
			private _typographyModel: TypographyModel) {
		this.changeDetectorRef = ref		
		this.applicationService = _applicationService
		this.eventsService = _eventsService
		this.uiNavigation = _uiNavigation
		this.uiLayout = _uiLayout
		this.typographyModel = _typographyModel
		this.uiNavigation.activeView = "intro"
		document.title = "Peach Marketing"
	}

	ngOnInit() {
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

}


