import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter,
	HostListener,
	ChangeDetectionStrategy
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'
import { UIColorModel } from '@coreui/services/ui.color.model.service';


@Component({
	selector: 		'saas-collections-toolbar',
	changeDetection: ChangeDetectionStrategy.Default,
	inputs: ['actionactive'],
	outputs: ['searchvalue', 'clicked'],
	template: 	`
		<div  *ngIf="_actionsMode==false" class="_collection-management-toolbar {{disabledCSS}}">
			<div class="search-wrapper">
				<search-bar (value)=searchValue($event)></search-bar>
			</div>
			<div class="action-items" onSurface>
				<div class="item">
					<icon-button [icon]="'tools'" [activeicon]="'tools'"  (clicked)=selectClicked()></icon-button>				
				</div>
		<!--		<div class="item">
					<icon-button [icon]="'trash-can-outline'" [activeicon]="'trash-can'"  (clicked)=trashClicked()></icon-button>				
				</div> -->
			</div>
			<div class="action-button-wrapper" onSurface>
				<fab [size]="'small'" [round]=true [icon]="'plus'" [activity]=true
					(clicked)=actionClicked() [colormap]="'primary-container'" [activity]=_actionActive></fab>
			</div>
		</div> 

		<div  *ngIf="_actionsMode==true" class="_collection-management-action-bar">
			<div class="actions-wrapper">
				<div class="item">
					<outline-button [label]="'Trash'" [icon]="'trash-can-outline'" (click)=trashItems()></outline-button>
				</div>
				<div class="item">
					<outline-button [label]="'Move'" [icon]="'file-move-outline'"></outline-button>
				</div>
			</div>

			<div class="close-wrapper">
				<div class="close-icon" onSurface>
					<anim-icon [icon]=_closeIcon [color]=_closeIconColor (click)=actionClose()></anim-icon>
				</div>
			</div>
		</div> 

		


`,
})


export class SaasCollectionsToolbarComponent {
	@Output()
	clicked = new EventEmitter();
	searchvalue = new EventEmitter();

	@Input()
	set actionactive(value: boolean) { this.setActionActive(value); }

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	uiColorModel: UIColorModel


	_isSelected: boolean = false
	_isTrash: boolean = false
	_toolbarState: any = {}
	_actionBarHeight: number = 0
	disabledCSS: string = ""
	isDisabled: boolean = false

	_actionsMode: boolean = false
	_actionActive: boolean = false

	_closeIcon: string = "apps"
	_closeIconColor: string = "000000"

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
	
		let self = this
		this.eventsService.on('onRefresh', function (args: any) {
			self._closeIconColor = self.uiColorModel.onSurface
		});

	}


	setActionActive(val: boolean) {
		this._actionActive = val	
	}

	searchValue($event: any) {
		this.searchvalue.emit($event)
	}

	selectClicked() {
		if(this._isSelected) {
			this._actionsMode = false
			this._isSelected = false
			this.clicked.emit("select-off")
		} else {
			this._actionsMode = true
			this._isSelected = true
			setTimeout(() => {
				this._closeIcon = "close"
			}, 10);
	
		//	this.setDisabled(true)
			this.clicked.emit("select-on")
		}
		this.changeDetectorRef.detectChanges()
	}

	trashClicked() {
		if(this.isDisabled == false) {
			if(this._isTrash) {
				this._isTrash = false
				this.clicked.emit("trash-off")
			} else {
				this._isTrash = true
				this.clicked.emit("trash-on")
			}
		}
	}

	actionClicked() {
		if(this.isDisabled == false) {
			this.clicked.emit("action-clicked")
		}
	}

	setDisabled(val: boolean) {
		if(val==true) {
			this.isDisabled = true
			this.disabledCSS = "disabled"
		} else {
			this.isDisabled = false
			this.disabledCSS = ""
		}
	}


	actionClose() {
		this._closeIcon = "apps"
		setTimeout(() => {
			this._isSelected = false
			this._actionsMode = false  
			this.clicked.emit("select-off")
			this.changeDetectorRef.detectChanges()
		}, 250);

	}


	trashItems() {
		this.clicked.emit("trash-selected")
	}


	ngOnInit() {
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}



/*

		<div class="_collection-management-action-bar"
			[style.height.px]=_actionBarHeight>

			<div class="item">
				<outline-button [label]="'Trash'" [icon]="'trash-can-outline'" (click)=trashItems()></outline-button>
			</div>
			<div class="item">
				<outline-button [label]="'Move'" [icon]="'file-move-outline'"></outline-button>
			</div>
			<div class="item-close">
				<text-button [label]="'Close'" (click)=actionClose()></text-button>
			</div>
		</div>
*/