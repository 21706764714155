import { Directive, ElementRef, Input, ChangeDetectorRef} from '@angular/core'

import { EventsService } from '../../services/event.service'
import { UIColorModel } from '../../services/ui.color.model.service';
import { ColorUtils } from "../../style/color/utils/color.utils.class"


@Directive({
  selector: '[elevated]',
  inputs: ['elevation'],
})

export class ElevatedSurfaceDirective {
    @Input()
	  set elevation(value: number) { this.setElevation(value) }

    private eventsService: EventsService
    private uiColorModel: UIColorModel
    private colorUtils: ColorUtils = new ColorUtils()
    elevationLevel: number = 0
    changeDetectorRef: ChangeDetectorRef


    constructor(private _eventsService: EventsService, private _uiColorModel: UIColorModel,
      public el: ElementRef, public ref: ChangeDetectorRef) {

      this.changeDetectorRef = ref
      this.eventsService = _eventsService
      this.uiColorModel = _uiColorModel
      this.elevationLevel = 0

      this.updateElement(0)

      let self = this
      this.eventsService.on('onRefresh', function (args: any) {
        self.updateElement(self.elevationLevel)
        self.ref.markForCheck()
      })
  }


  setElevation(value: number) {
    this.updateElement(value)
  }

  
  updateElement(level: number) {
    this.elevationLevel = level
    let shadowColor = this.uiColorModel.shadow
    this.el.nativeElement.style.boxShadow = this.getElementRef(this.el, level, shadowColor)
    this.ref.markForCheck()
  }


  boxShadow(level: number, hex: string) {
    let shadow = "none"
    let rgbstring = this.colorUtils.hexToRGBString(hex)
    if(level == 0) {
      shadow = "none"
    }
    if(level == 1) {
      shadow = "0 1px 3px 0 rgba(" + rgbstring + ", 0.12), 0 1px 2px 0 rgba(" + rgbstring + ", 0.24)"
    }
    if(level == 2) {
      shadow = "0 3px 6px 0 rgba(" + rgbstring + ", 0.16), 0 3px 6px 0 rgba(" + rgbstring + ", 0.23)"
    }
    if(level == 3) {
      shadow = "0 10px 20px 0 rgba(" + rgbstring+ ", 0.19), 0 6px 6px 0 rgba(" + rgbstring + ", 0.19)"
    }
    if(level == 4) {
      shadow = "0 14px 28px 0 rgba(" + rgbstring + ", 0.25), 0 10px 10px 0 rgba(" + rgbstring + ", 0.22)"
    }
    if(level == 5) {
      shadow = "0 19px 38px 0 rgba(" + rgbstring + ", 0.3), 0 15px 12px 0 rgba(" + rgbstring + ", 0.22)"
    }
    return shadow
  }

  getElementRef(el: any, level: number, hex: string) {
    el.nativeElement.style.boxShadow = this.boxShadow(level, hex)
    return el
  }


}


