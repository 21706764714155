import {
	Component,
	ViewEncapsulation,
	ChangeDetectorRef,

	ViewChild,
	ChangeDetectionStrategy
} from '@angular/core';

import { UILayout } from '@coreui/services//ui.layout.service'
import { EventsService } from '@coreui/services/event.service'
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { TypographyModel } from '@coreui/services/typography.service';
import { UIColorModel } from '@coreui/services/ui.color.model.service';

import {MapConstructorOptions, MapKitInitOptions} from 'ngx-apple-maps/lib/declarations';

@Component({
	selector: 		'apple-maps-page',
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
	<content-container-scrollable>
<!--
	<ngx-apple-maps-annotation 
	></ngx-apple-maps-annotation>
	</content-container-scrollable>
-->
</content-container-scrollable>

`,
})


export class AppleMapsPage {
	private changeDetectorRef: ChangeDetectorRef
	private uiLayout: UILayout
	private uiNavigation: UINavigation
    private eventsService: EventsService
	private typographyModel: TypographyModel
	uiColorModel: UIColorModel


	
	options: MapKitInitOptions = {
		// tslint:disable-next-line:max-line-length
		JWT: '',
		language: 'en',
		callback: (data: any) => {
		  console.log('data ', data);
		  // console.log('data ', data);
		}
	  };

	  customAnnotation = {
		latitude: 37.8083396,
		longitude: -122.415727,
		options: {
		  title: 'Custom marker callout',
		  subtitle: 'subtitle 2',
		  color: '#000',
		  selected: false,
		  glyphText: ''
		}
	  };
	
	  annotations = [
		{
		  latitude: 37.8023553,
		  longitude: -122.405742,
		  options: {
			title: 'test 2',
			subtitle: 'subtitle 3',
			color: '#000',
			selected: false,
			glyphText: ''
		  }
		},
		{
		  latitude: 37.779267,
		  longitude: -122.419269,
		  options: {
			title: 'test 2',
			subtitle: 'subtitle 3',
			color: '#FF0000',
			selected: false,
			glyphText: '',
			calloutEnabled: true
		  }
		}
	  ];
	  settings: MapConstructorOptions = {
		isZoomEnabled: true,
		showsZoomControl: true,
		showsUserLocationControl: true,
		showsMapTypeControl: true,
		showsUserLocation: false,
		tracksUserLocation: false,
		center: {
		  latitude: 37.779267,
		  longitude: -122.419269,
		}
	  };
	



	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService, 
		private _uiLayout: UILayout,
		private _uiNavigation: UINavigation,
		private _uiColorModel: UIColorModel,
		private _typographyModel: TypographyModel) {
		this.uiLayout = _uiLayout
		this.eventsService = _eventsService
		this.uiNavigation = _uiNavigation
		this.typographyModel = _typographyModel
		this.uiColorModel = _uiColorModel
		this.changeDetectorRef = ref


		
	}


	ngOnInit() {
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
