export class DpLocationRecord {

  private _refid: string = ""
  private _address: string = ""
  private _formattedAddress: string = ""
  private _streetNo: string = ""
  private _streetName: string = ""
  private _suburb: string = ""
  private _city: string = ""
  private _state: string = ""
  private _stateCode: string = ""
  private _postcode: string = ""
  private _country: string = ""
  private _countryCode2: string = ""
  private _continent: string = ""
  private _continentCode: string = ""
  private _latitude: number = 0
  private _longitude: number = 0
  private _isValidated: boolean = false

  constructor() {
  }

  public toJSON(): any {
    let json = {
      refid: this._refid,
      address: this._address,
      formatted_address: this._formattedAddress,
      street_no: this._streetNo,
      street_name: this._streetName,
      suburb: this._suburb,
      state: this._state,
      state_code: this._stateCode,
      city: this._city,
      country: this._country,
      countrycode2: this._countryCode2,
      continent: this._continent,
      continentcode2: this._continentCode,
      postcode: this._postcode,
      latitude: this._latitude,
      longitude: this._longitude
    }
    return json
  }

  public fromJSON(json: any): void {
    this._refid = json["refid"]
    this._address = json["address"]
    this._formattedAddress = json["formatted_address"]
    this._streetNo = json["street_no"]
    this._streetName = json["street_name"]
    this._suburb = json["suburb"]
    this._state = json["state"]
    this._stateCode = json["state_code"]
    this._city = json["city"]
    this._postcode = json["postcode"]
    this._country = json["country"]
    this._countryCode2 = json["countryCode2"]
    this._continent = json["continent"]
    this.continentCode = json["continentCode"]
    this._latitude = json["latitude"]
    this._longitude = json["longitude"]
  }

  public get refid(): string {
    return this._refid
  }
  public set refid(value: string) {
    this._refid = value
  }  

  public get address(): string {
    return this._address
  }
  public set address(value: string) {
    this._address = value
  }
  public get formattedAddress(): string {
    return this._formattedAddress
  }
  public set formattedAddress(value: string) {
    this._formattedAddress = value
  }
  public get streetNo(): string {
    return this._streetNo
  }
  public set streetNo(value: string) {
    this._streetNo = value
  }
  public get streetName(): string {
    return this._streetName
  }
  public set streetName(value: string) {
    this._streetName = value
  }
  public get suburb(): string {
    return this._suburb
  }
  public set suburb(value: string) {
    this._suburb = value
  }
  public get state(): string {
    return this._state
  }
  public set state(value: string) {
    this._state = value
  }
  public get stateCode(): string {
    return this._stateCode
  }
  public set stateCode(value: string) {
    this._stateCode = value
  }

  public get country(): string {
    return this._country
  }
  public set country(value: string) {
    this._country = value
  }
  public get countryCode2(): string {
    return this._countryCode2
  }
  public set countryCode2(value: string) {
    this._countryCode2 = value
  }
  public get continent(): string {
    return this._continent
  }
  public set continent(value: string) {
    this._continent = value
  }
  public get continentCode(): string {
    return this._continentCode
  }
  public set continentCode(value: string) {
    this._continentCode = value
  }

  public get city(): string {
    return this._city
  }
  public set city(value: string) {
    this._city = value
  }
  public get postcode(): string {
    return this._postcode
  }
  public set postcode(value: string) {
    this._postcode = value
  }
  public get latitude(): number {
    return this._latitude
  }
  public set latitude(value: number) {
    this._latitude = value
  }
  public get longitude(): number {
    return this._longitude
  }
  public set longitude(value: number) {
    this._longitude = value
  }
  public get isValidated(): boolean {
    return this._isValidated
  }
  public set isValidated(value: boolean) {
    this._isValidated = value
  }

}
