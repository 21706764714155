import {
  Component,
  ViewEncapsulation,
  ElementRef,
  Input,
  ChangeDetectorRef,
  NgZone,
  Inject,
  Renderer2,
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'
import { UILayout } from '@coreui/services/ui.layout.service'

@Component({
  selector: 'fullscreen-popup-radar-search',
  encapsulation: ViewEncapsulation.None,
  inputs: ['message'],

  template: `
    <scrim-layer [opacity]=0.8 (clicked)=clickEvent($event) [close]=_closeScrim>
      <div class="_fullscreen-radar-blip-search-popup">
        <div class="heading" font-family>Searching for targets</div>
        <div class="radar">
            <i class="mdi mdi-wifi"></i>
        </div>
      </div>
    </scrim-layer>
  `,
})
export class PopupRadarBlip {
  @Input()
  set message(value: string) {
    this.setMessage(value);
  }

  _closeScrim: boolean = false
  _message: string = ""
  _iconColor: string = "#4BB543"


  eventsService: EventsService;
	uiLayout: UILayout

  constructor(
    private _eventsService: EventsService,
    private _uiLayout: UILayout,
    public ref: ChangeDetectorRef
  ) {
    this.eventsService = _eventsService;
    this.uiLayout = _uiLayout

    let self = this;
    this.eventsService.on('onResize', function (args: any) {
    });
  }

  setMessage(value: string) {
    this._message = value
  }



  closeSequence() {
    this._closeScrim = true
    setTimeout(() => {
      this.eventsService.broadcast('onHideModalOverlay', null);
      this.ref.detectChanges();
    }, 350);
  }

  clickEvent($event: any) {
  }


  ngOnInit() {
    this._closeScrim = false
    setTimeout(() => {
      this.ref.detectChanges();
    }, 50);

    setTimeout(() => {
      this.closeSequence()
    }, 3000);
    
  }

  ngOnDestroy() {
	}

}
