import {
	Component,
	ViewEncapsulation,
	ChangeDetectorRef,

	ViewChild,
	ChangeDetectionStrategy
} from '@angular/core';

import { UILayout } from '@coreui/services//ui.layout.service'
import { EventsService } from '@coreui/services/event.service'
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { TypographyModel } from '@coreui/services/typography.service';

import { PaletteDataSample } from '@libraries/color-swatches/classes/palette.data.class';


@Component({
	selector: 		'gradients-collection-page',
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
	<saas-page-container>
		<div id="scroll-to-top" class="_saas-page-header">
			<div class="heading" onSurface>Gradients</div>
		</div>

		<saas-collections-toolbar (searchvalue)=search($event) (clicked)=toolbarAction($event)></saas-collections-toolbar>
		<gradient-collection-anim [data]=_searchResults></gradient-collection-anim>
		<scroll-top-button [scrollshowvalue]=144 (clicked)=scrollToTop()></scroll-top-button>

	</saas-page-container>

		`,
})


export class GradientsCollectionPage {
  
	private changeDetectorRef: ChangeDetectorRef
	private uiLayout: UILayout
	private uiNavigation: UINavigation
    private eventsService: EventsService
	private typographyModel: TypographyModel


	_gradientData: any = {}
	_searchResults: any = {}
	

	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService, 
		private _uiLayout: UILayout,
		private _uiNavigation: UINavigation,
		private _typographyModel: TypographyModel) {
		this.uiLayout = _uiLayout
		this.eventsService = _eventsService
		this.uiNavigation = _uiNavigation
		this.typographyModel = _typographyModel
		this.changeDetectorRef = ref
	}

	scrollToTop() {
		let textElement: any = document.getElementById("scroll-to-top")
		textElement.scrollIntoView({behavior: "smooth"})
	}

	toolbarAction($event: any) {
		//alert($event)
		if($event == "select-on")
			this.eventsService.broadcast("selectModeOn", {})	

		if($event == "select-off")
			this.eventsService.broadcast("selectModeOff", {})	

		if($event == "action-clicked")
			this.eventsService.broadcast("onShowRightSlider", {id: "add-gradient"})

	}



	search($event: any) {
		console.log($event)
		this._searchResults = this.searchResults($event)
	}



    searchResults(term: string): any {
        let results: any = []
        let i = 0
        for(i=0;i<this._gradientData.length;i++) {
			let name = this._gradientData[i]["name"].toLowerCase()
            if(name.includes(term.toLowerCase())) {
                results.push(this._gradientData[i])
				console.log("added " + this._gradientData[i]["name"])
            }
        }
        return results
    }


	addGradient() {
	}


	ngOnInit() {
		let pp = new PaletteDataSample()
		this._gradientData = pp.gradients().getGradients()
		this._searchResults = pp.gradients().getGradients()

		this.changeDetectorRef.detectChanges()
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	//	this.uiLayout.showInnerHeader = false
		this.uiLayout.showRightSliderSheet = false
	}

}
