import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter,
	ChangeDetectionStrategy
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'


@Component({
	selector: 		'file-upload-progress-card',
	inputs: ['heading', 'filesize', 'uploaded', 'indeterminate', 'showleadingicon'],
	outputs: ['controlclick'],
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
	<progress-perc-twoline-card [label]=_label
		[heading]=_heading
		[progresscolor]=_progresscolor
		[indeterminate]=_isIndeterminate
		[icon]=_icon
		[iconanimated]=true
		[iconcss]=_iconCSS
		[perc]=this._percentage
		[showcontrol]=true
		[showleadingicon]=_showLeadingIcon
		[controlicon]=_controlIcon
		(controlclick)=controlClicked($event)>
	</progress-perc-twoline-card>

								`,
})


export class FileUploadProgressCard {
	@Output()
	controlclick = new EventEmitter();

	@Input()
	set label(value: string) { this.setLabel(value); }
	set heading(value: string) { this.setHeading(value); }
	set filesize(value: number) { this.setFilesize(value); }
	set uploaded(value: number) { this.setUploaded(value); }
	set indeterminate(value: boolean) { this.setIndeterminate(value); }
	set showleadingicon(value: boolean) { this.setShowLeadingIcon(value); }



	private changeDetectorRef: ChangeDetectorRef;
	private eventsService: EventsService

	_label:							string = ""
	_heading:						string = "&nbsp;"
	_percentage: 				number = 0
	_filesize: 					number = 0
	_uploaded: 					number = 0
	_isIndeterminate:		boolean = false
	_showLeadingIcon:	  boolean = false

	_progresscolor: 		string = "00CCCC"
	_icon: 							string = ""
	_iconColor: 				string = ""
	_iconCSS:						string = ""
	_controlIcon:				string = ""

	

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService) {
		this.eventsService = _eventsService;
		this.changeDetectorRef = ref;

	}


	setLabel(value: string): void {
		this._label = value
	}

	setHeading(value: string) {
		this._heading = value
	}

	setFilesize(value: number) {
		this._filesize = value
	}

	setIndeterminate(value: boolean) {
		this._isIndeterminate = value;
	}

	setShowLeadingIcon(value: boolean) {
		this._showLeadingIcon = value
	}

	setUploaded(value: number) {
		if(value >= this._filesize && this._filesize != 0) {
			this._uploaded = this._filesize
			this._isIndeterminate = false
			this.doneState()
		} else {
			this._uploaded = value
			this.activeState()
		}
		this.updateLabel()
	}

	activeState() {
		this._icon = "cloud-upload-outline"
	//	this._progresscolor = "2196F3"
		this._controlIcon = "close-circle-outline"
	}

	doneState() {
		setTimeout(() => {
			this._icon = "cloud-check-outline"
	//		this._progresscolor = "75e900"
			this._controlIcon = "check-circle-outline"
		}, 350);
	}


	controlClicked($event: any) {
		this.controlclick.emit(true);
	}


	updateLabel() {
		if(this._isIndeterminate == true) {
			this._label = "??? of " + this.formatBytes(this._filesize, 2)
			this._percentage = -1
		} else {
			let pc = this._uploaded / this._filesize * 100
			this._percentage = Math.round(pc)
			this._label = this.formatBytes(this._uploaded, 2) + " of " + this.formatBytes(this._filesize, 2)
		}
	}



	ngOnInit() {
	//	this.onSurface = this.coreService.stylemodel().getActiveColorModel()["onsurface"]["hex"]
		this.updateLabel()
		this.activeState()
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}



	formatBytes(bytes: number, decimals = 2) {
	    if (bytes === 0) return '0';
	    const k = 1024;
	    const dm = decimals < 0 ? 0 : decimals;
	    const sizes = [' B', ' KB', ' MB', ' GB', ' TB', ' PB', ' EB', ' ZP', ' YB'];
	    const i = Math.floor(Math.log(bytes) / Math.log(k));
	    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}


}
