import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';

import { ApplicationService } from '../../services/application.service';
import { EventsService } from '@coreui/services/event.service';
import { UIColorModel } from '@coreui/services/ui.color.model.service';


@Component({ 
	selector: 		'sessions-list-page',
	encapsulation: ViewEncapsulation.None,
	template:	`
            <content-container-scrollable>
				
				<sessions-list></sessions-list>
			</content-container-scrollable>

	`,

})


export class SessionsListPage {

	private eventsService: EventsService;
	private changeDetectorRef: ChangeDetectorRef;
	containerHeight: number = 100
	uiColorModel: UIColorModel
	applicationService: ApplicationService

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService, 
			private _applicationService: ApplicationService,
			private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.applicationService = _applicationService
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
	}

	ngOnInit() { 
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
	}
  

}
