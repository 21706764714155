import {
  Component,
  ViewEncapsulation,
  ViewChild,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  NgZone,
  Input,
} from "@angular/core";

import { EventsService } from "@coreui//services/event.service";
import { UIColorModel } from "@coreui/services/ui.color.model.service";
import { UILayout } from '@coreui//services/ui.layout.service';

@Component({
  selector: "imagegallery-upload-sidesheet",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  template: `
    <side-sheet>


      <div class="actions"
        [style.borderTop]="_border">
        <div class="button">
          <outline-button
            [label]="'Cancel'"
            (clicked)="close()"
          ></outline-button>
        </div>
      </div>
    </side-sheet>
      
  `,
})

export class ImageGalleryUploadSidesheet {
  private eventsService: EventsService;
  private uiLayout: UILayout
  private changeDetectorRef: ChangeDetectorRef;
  uiColorModel: UIColorModel;



  
  _closeIcon: string = "plus";
  _border: string = "";
  _containerHeight: number = 100

  constructor(
    public ref: ChangeDetectorRef,
    private _uiLayout: UILayout,
    _eventsService: EventsService,
    private _uiColorModel: UIColorModel
  ) {
    this.eventsService = _eventsService;
    this.uiColorModel = _uiColorModel;
    this.uiLayout = _uiLayout
    this.changeDetectorRef = ref;

    let self = this;
    this.eventsService.on("onRefresh", function (args: any) {
      self.borderColor();
    });
  }

  borderColor() {
    this._border = "1px solid " + this.uiColorModel.outline;
  }

  calculateContainerHeight() {
    this._containerHeight = window.innerHeight - 64
  }

  openSequence() {
    setTimeout(() => {
      this._closeIcon = "close";
    }, 300);
  }

  close() {
    this._closeIcon = "plus";
    setTimeout(() => {
      this.eventsService.broadcast("onHideRightSlider", {});
    }, 300);
  }

  ngOnInit() {
    this.calculateContainerHeight()
    this.borderColor();
    this.openSequence();
  }

  ngOnDestroy() {
    this.close();
  }

  ngAfterViewInit() {}
}
