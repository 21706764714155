import {
	Component,
	ViewEncapsulation,
	ChangeDetectorRef,

	ViewChild,
	ChangeDetectionStrategy
} from '@angular/core';

import { UILayout } from '@coreui/services//ui.layout.service'
import { EventsService } from '@coreui/services/event.service'
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { TypographyModel } from '@coreui/services/typography.service';
import { UIColorModel } from '@coreui/services/ui.color.model.service';
import { PaletteDataSample } from '@libraries/color-swatches/classes/palette.data.class';


@Component({
	selector: 		'list-detail-example-page',
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
		<list-detail-page [width]=280>
			<div list-pane>
				<card [height]=112></card>
				<card [height]=112></card>
				<card [height]=112></card>
			</div>
			<div detail-pane>
			</div>

		</list-detail-page>
 
		`,
})


export class ListDetailExamplePage {
	private changeDetectorRef: ChangeDetectorRef
	private uiLayout: UILayout
	private uiNavigation: UINavigation
    private eventsService: EventsService
	private typographyModel: TypographyModel
	uiColorModel: UIColorModel


	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService, 
		private _uiLayout: UILayout,
		private _uiNavigation: UINavigation,
		private _uiColorModel: UIColorModel,
		private _typographyModel: TypographyModel) {
		this.uiLayout = _uiLayout
		this.eventsService = _eventsService
		this.uiNavigation = _uiNavigation
		this.typographyModel = _typographyModel
		this.uiColorModel = _uiColorModel
		this.changeDetectorRef = ref

	}

	ngOnInit() {
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}


}
