import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


/////////////////////////////////////////////////////////
// Core V4
////////////////////////////////////////////////////////

// Services
import { EventsService } from './services/event.service';
import { ElevationsStyleService } from './services/elevation.style.service';
import { UIColorModel } from './services/ui.color.model.service';
import { TypographyModel } from './services/typography.service';
import { UILayout } from './services/ui.layout.service';
import { UINavigation } from './services/ui.navigation.service';


/* Layout */

import { RowComponent } from './layout/grids/row.component';
import { ColComponent } from './layout/grids/col.component';

import { AppbarTopComponent } from './components/navigation/appbar-top/appbar.top.component';
import { NavigationRailComponent } from './components/navigation/navigation-rail/navigation.rail.component';
import { NavigationIconComponent } from './components/navigation/navigation-icon/navigation.icon.component';
import { NavigationBarComponent } from './components/navigation/navigation-bar/navigation.bar.component';

/* Navigation Drawer */
import { NavigationDrawerComponent } from './components/navigation/navigation-drawer/navigation.drawer.component';
import { NavigationDrawerModalComponent } from './components/navigation/navigation-drawer/navigation.drawer.modal';
import { NavigationDrawerMenuComponent } from './components/navigation/navigation-drawer/navigation.drawer.menu.component';

/* Navigation Drawer - Nested */
import { NavigationDrawerNestedMenu } from './components/navigation/navigation-drawer-menu/navigation-menu.drawer.nested';
import { NavigationDrawerItemMenu } from './components/navigation/navigation-drawer-menu/navigation-menu.drawer.item';
import { NavigationDrawerSubMenu } from './components/navigation/navigation-drawer-menu/navigation-menu.drawer.submenu';


/* Scrim & Slider Sheet */
import { ScrimLayer } from './containers/scrim/scrim.component';
import { SliderSheetLayer } from './containers/slider-sheet/slider.sheet.container';

import { MenuItemNoIconComponent } from './components/menu/menu.item.no.icon.component';
import { MenuItemWIconComponent } from './components/menu/menu.item.icon.component';
import { MenuItemNoIconCollapsibleComponent } from './components/menu/menu.item.collapsible';



import { ViewHeightDirective } from './directives/layout/view.height.directive';
import { DetectScrollDirective } from './directives/layout/detect.scroll.directive';

import { ElevatedSurfaceDirective } from './directives/elevation/elevation.directive';

import { PageContentContainer } from './layout/components/optimised.content.container';
import { ScrollableContentContainer } from './layout/components/scrollable.content.container';
import { ApplicationContainer } from './containers/application/application.container';
import { SimpleApplicationContainer } from './containers/application/simple.application.container';

import { SideSheetContainer } from './containers/side-sheet/side.sheet.container';
import { CardContainer } from './containers/cards/card.container';
import { ModalContainer } from './containers/modal/modal.container';
import { DashboardScreenContainer } from './containers/screen/dash.screen.container';
import { ScreenContentContainer } from './containers/screen/screen.content.container';


// card styles
import { TransformableCardContainer } from './containers/cards/types/transformable.card';
import { MediaCardContainer } from './containers/cards/types/media.card';
import { MobileScreenCardContainer } from './containers/cards/types/mobile.screen.card';

/******
 * 
 */
import { CardAction } from './containers/cards/card.action.section';




/* Avatar */
import { AvatarComponent } from './components/avatar/avatar.component';
import { Avatar40Component } from './components/avatar/avatar40.component';

/* Buttons */
import { StdButtonComponent } from './components/buttons/std.button.component';
import { FabButtonComponent } from './components/buttons/fab.button.component';
import { IconButtonComponent } from './components/buttons/icon.button.component';
import { IconBadgeButtonComponent } from './components/buttons/icon.badge.button.component';
import { TonalButtonComponent } from './components/buttons/tonal.button.component';
import { OutlineButtonComponent } from './components/buttons/outline.button.component';
import { TextButtonComponent } from './components/buttons/text.button.component';
import { StdButtonLargeComponent } from './components/buttons/std.large.button.component';



// Badges
import { BadgeComponent } from './components/badges/badge.component';

// Chips
import { ChipComponent } from './components/chips/chip.component';
import { FilterChipComponent } from './components/chips/filter.chip.component';

// Icons
import { AnimatedIconComponent } from './components/icons/anim.icon.component';

// Progress Bar
import { ProgressBarComponent } from './components/progress/progress-bar/progressbar.component';


// Search bar
import { SearchBarComponent } from './components/navigation/search/search.bar.component';
import { SearchBarContainerComponent } from './components/navigation/search/search.with.container.component';

// Tabs
import { TabsM3Component } from './components/tabs/tabs.component';
import { TabsM3IconLabelComponent } from './components/tabs/tabs.iconlabel.component';

// Directives
 
// Typography
import { FontFamilyDirective } from './directives/typography/font.family.directive';
import { DisplayFontFamilyDirective } from './directives/typography/display.font.family.directive';

// Text Divider
import { TextDividerComponent } from './components/text-divider/text.divider.component';



// Color scheme directives
import { ColorPrimaryDirective } from './directives/color/scheme/primary/primary.directive';
import { ColorOnPrimaryDirective } from './directives/color/scheme/primary/onprimary.directive';
import { ColorPrimaryContainerDirective } from './directives/color/scheme/primary/primary.container.directive';
import { ColorOnPrimaryContainerDirective } from './directives/color/scheme/primary/onprimary.container.directive';
import { ColorInversePrimaryDirective } from './directives/color/scheme/primary/inverse.primary.directive';
import { ColorPrimaryForeDirective } from './directives/color/scheme/primary/primary.textcolor.directive';

import { ColorSecondaryDirective } from './directives/color/scheme/secondary/secondary.directive';
import { ColorOnSecondaryDirective } from './directives/color/scheme/secondary/onsecondary.directive';
import { ColorSecondaryContainerDirective } from './directives/color/scheme/secondary/secondary.container.directive';
import { ColorOnSecondaryContainerDirective } from './directives/color/scheme/secondary/onsecondary.container.directive';
import { ColorSecondaryForeDirective } from './directives/color/scheme/secondary/secondary.forecolor.directive';


import { ColorTertiaryDirective } from './directives/color/scheme/tertiary/tertiary.directive';
import { ColorOnTertiaryDirective } from './directives/color/scheme/tertiary/ontertiary.directive';
import { ColorTertiaryContainerDirective } from './directives/color/scheme/tertiary/tertiary.container.directive';
import { ColorOnTertiaryContainerDirective } from './directives/color/scheme/tertiary/ontertiary.container.directive';

import { ColorErrorDirective } from './directives/color/scheme/error/errorcolor.directive';
import { ColorOnErrorDirective } from './directives/color/scheme/error/onerror.directive';
import { ColorErrorContainerDirective } from './directives/color/scheme/error/error.container.directive';
import { ColorOnErrorContainerDirective } from './directives/color/scheme/error/onerror.container.directive';

import { ColorBackgroundDirective } from './directives/color/scheme/background/background.directive';
import { ColorOnBackgroundDirective } from './directives/color/scheme/background/onbackground.directive';


import { ColorSurfaceDirective } from './directives/color/scheme/surface/surface.directive';
import { ColorOnSurfaceDirective } from './directives/color/scheme/surface/onsurface.directive';
import { ColorSurfaceVariantDirective } from './directives/color/scheme/surface/surface.variant.directive';
import { ColorOnSurfaceVariantDirective } from './directives/color/scheme/surface/onsurface.variant.directive';
import { ColorSurfaceBrightDirective } from './directives/color/scheme/surface/surface.bright.directive';
import { ColorSurfaceDimDirective } from './directives/color/scheme/surface/surface.dim.directive';
import { ColorOnSurfaceBackDirective } from './directives/color/scheme/surface/onsurface.back.directive';


import { ColorInverseSurfaceDirective } from './directives/color/scheme/surface/inverse.surface.directive';
import { ColorInverseOnSurfaceDirective } from './directives/color/scheme/surface/inverse.onsurface.directive';

import { ColorSurfaceLowestDirective } from './directives/color/scheme/surface/surface.lowest.directive';
import { ColorSurfaceLowDirective } from './directives/color/scheme/surface/surface.low.directive';
import { ColorSurfaceContainerDirective } from './directives/color/scheme/surface/surface.container.directive';
import { ColorSurfaceHighDirective } from './directives/color/scheme/surface/surface.high.directive';
import { ColorSurfaceHighestDirective } from './directives/color/scheme/surface/surface.highest.directive';

import { ColorShadowDirective } from  './directives/color/scheme/shadow.directive';
import { ColorOutlineDirective } from './directives/color/scheme/outline.directive';
import { ColorOutlineVariantDirective } from './directives/color/scheme/outline.variant.directive';
import { ColorShadowTintDirective } from  './directives/color/scheme/surface.tint.directive';
import { ColorScrimDirective } from  './directives/color/scheme/scrim.directive';

import { BorderBottomOutlineDirective } from  './directives/color/scheme/dividers/border.bottom.divider.directive';
import { BorderTopOutlineDirective } from  './directives/color/scheme/dividers/border.top.divider.directive';
import { BorderLeftOutlineDirective } from  './directives/color/scheme/dividers/border.left.divider.directive';
//import { BorderBottomOutlineDirective } from  './directives/color/scheme/dividers/border.bottom.divider.directive';


/* Text Fields */
import { InputTextFieldFilled } from './components/text-input/input/text.field filled';
import { InputPasswordFieldFilled } from './components/text-input/input/password.field filled';


/* Switch */
import { SwitchComponent } from './components/switch/switch.component';


/* Vertical Tiles */
import { TileSecondaryAction48Component } from './components/tiles-vertical/tiles/tile.secondary.action.48.component';
import { TileSecondaryAction56Component } from './components/tiles-vertical/tiles/tile.secondary.action.56.component';
import { TileSingleItemComponent } from './components/tiles-vertical/tiles/single.item.tile.text.component';
import { TileSingleItemIconComponent } from './components/tiles-vertical/tiles/single.item.tile.icon.component';
import { TileSingleItemAvatarComponent } from './components/tiles-vertical/tiles/single.item.tile.avatar.component';
import { TileSingleItemImageComponent } from './components/tiles-vertical/tiles/single.item.tile.image.component';




///// To Be Updated //////
import { InputTextFieldConventionalComponent } from './components/text-input/input/input.text.field';


@NgModule({
  declarations: [
    ViewHeightDirective,
    DetectScrollDirective,
    ElevatedSurfaceDirective,
    PageContentContainer,
    ScrollableContentContainer,
    ApplicationContainer,
    SimpleApplicationContainer,
    SideSheetContainer,
    ScrimLayer,
    SliderSheetLayer,
    CardContainer,
    ModalContainer,
    TransformableCardContainer,
    MediaCardContainer,
    MobileScreenCardContainer,
    DashboardScreenContainer,
    ScreenContentContainer,

    //layout
    RowComponent,
    ColComponent,
    AppbarTopComponent,
    NavigationRailComponent,
    NavigationIconComponent,
    NavigationBarComponent,

    //Navigation Drawer
    NavigationDrawerComponent,
    NavigationDrawerModalComponent,
    NavigationDrawerMenuComponent,

    NavigationDrawerNestedMenu,
    NavigationDrawerItemMenu,
    NavigationDrawerSubMenu,

    // components

    // Avatar 
    AvatarComponent,
    Avatar40Component,

    //icon
    AnimatedIconComponent,

    // buttons
    StdButtonComponent,
    FabButtonComponent,
    IconButtonComponent,
    IconBadgeButtonComponent,
    TonalButtonComponent,
    OutlineButtonComponent,
    TextButtonComponent,
    StdButtonLargeComponent,

    //badges
    BadgeComponent,
    // Chips
    ChipComponent,
    FilterChipComponent,
    // search bar
    SearchBarComponent,
    SearchBarContainerComponent,

    //tabs
    TabsM3Component,
    TabsM3IconLabelComponent,
    // menu
    MenuItemNoIconComponent,
    MenuItemWIconComponent,
    MenuItemNoIconCollapsibleComponent,
    // progress bar
    ProgressBarComponent,

    // Text Field
    InputTextFieldFilled,
    InputPasswordFieldFilled,
    //text divider
    TextDividerComponent,
    //Switch
    SwitchComponent,
    //Vertical Tiles
    TileSingleItemComponent,
    TileSingleItemIconComponent,
    TileSingleItemAvatarComponent,
    TileSingleItemImageComponent,

    TileSecondaryAction48Component,
    TileSecondaryAction56Component,

    // color scheme
    ColorPrimaryDirective,
    ColorOnPrimaryDirective,
    ColorPrimaryContainerDirective,
    ColorPrimaryContainerDirective,
    ColorOnPrimaryContainerDirective,
    ColorPrimaryForeDirective,
    ColorInversePrimaryDirective,
    ColorSecondaryDirective,
    ColorOnSecondaryDirective,
    ColorSecondaryContainerDirective,
    ColorOnSecondaryContainerDirective,
    ColorSecondaryForeDirective,
    ColorTertiaryDirective,
    ColorOnTertiaryDirective,
    ColorTertiaryContainerDirective,
    ColorOnTertiaryContainerDirective,     
    ColorErrorDirective,
    ColorOnErrorDirective,
    ColorErrorContainerDirective,
    ColorOnErrorContainerDirective,
    ColorBackgroundDirective,
    ColorOnBackgroundDirective,
    ColorSurfaceDirective,
    ColorOnSurfaceDirective,
    ColorSurfaceVariantDirective,
    ColorOnSurfaceVariantDirective,
    ColorSurfaceBrightDirective,
    ColorSurfaceDimDirective,
    ColorOnSurfaceBackDirective,
    ColorInverseSurfaceDirective,
    ColorInverseOnSurfaceDirective,
    ColorSurfaceLowestDirective,
    ColorSurfaceLowDirective,
    ColorSurfaceContainerDirective,
    ColorSurfaceHighDirective,
    ColorSurfaceHighestDirective,
    ColorShadowTintDirective,
    ColorScrimDirective,

    ColorOutlineDirective,
    ColorOutlineVariantDirective,
    ColorShadowDirective,
    BorderBottomOutlineDirective,
    BorderTopOutlineDirective,
    BorderLeftOutlineDirective,

    FontFamilyDirective,
    DisplayFontFamilyDirective,

    InputTextFieldConventionalComponent,

    CardAction
  ],

  imports: [
    CommonModule
  ],

  providers: [
    EventsService,
    ElevationsStyleService,
    UIColorModel,
    TypographyModel,
    UILayout,
    UINavigation
  ],

  exports: [
    ViewHeightDirective,
    DetectScrollDirective,
    ElevatedSurfaceDirective,
    PageContentContainer,
    ScrollableContentContainer,
    ApplicationContainer,
    SimpleApplicationContainer,
    SideSheetContainer,
    ScrimLayer,
    SliderSheetLayer,
    CardContainer,
    ModalContainer,
    TransformableCardContainer,
    MediaCardContainer,
    MobileScreenCardContainer,
    DashboardScreenContainer,
    ScreenContentContainer,

    //layout
    RowComponent,
    ColComponent,
    AppbarTopComponent,

    // components

    // Avatar 
    AvatarComponent,
    Avatar40Component,
    //icon
    AnimatedIconComponent,

    NavigationRailComponent,
    NavigationIconComponent,
    NavigationBarComponent,
    //Navigation Drawer
    NavigationDrawerComponent,
    NavigationDrawerModalComponent,
    NavigationDrawerMenuComponent,

    NavigationDrawerNestedMenu,
    NavigationDrawerItemMenu,
    NavigationDrawerSubMenu,

    // buttons
    StdButtonComponent,
    FabButtonComponent,
    IconButtonComponent,
    IconBadgeButtonComponent,
    TonalButtonComponent,
    OutlineButtonComponent,
    TextButtonComponent,
    StdButtonLargeComponent,

    //badges
    BadgeComponent,

    // Chips
    ChipComponent,
    FilterChipComponent,

    // search bar
    SearchBarComponent,
    SearchBarContainerComponent,

    //tabs
    TabsM3Component,
    TabsM3IconLabelComponent,
    // menu
    MenuItemNoIconComponent,
    MenuItemWIconComponent,
    MenuItemNoIconCollapsibleComponent,

    // progress bar
    ProgressBarComponent,

    // Text Field
    InputTextFieldFilled,
    InputPasswordFieldFilled,
    //text divider
    TextDividerComponent,
    //Switch
    SwitchComponent,

    //Vertical Tiles
    TileSingleItemComponent,
    TileSingleItemIconComponent,
    TileSingleItemAvatarComponent,
    TileSingleItemImageComponent,
    
    TileSecondaryAction48Component,
    TileSecondaryAction56Component,

    // color scheme
    ColorPrimaryDirective,
    ColorOnPrimaryDirective,
    ColorPrimaryContainerDirective,
    ColorPrimaryContainerDirective,
    ColorOnPrimaryContainerDirective,
    ColorPrimaryForeDirective,
    ColorInversePrimaryDirective,
    ColorSecondaryDirective,
    ColorOnSecondaryDirective,
    ColorSecondaryContainerDirective,
    ColorOnSecondaryContainerDirective,
    ColorSecondaryForeDirective,
    ColorTertiaryDirective,
    ColorOnTertiaryDirective,
    ColorTertiaryContainerDirective,
    ColorOnTertiaryContainerDirective,

    ColorErrorDirective,
    ColorOnErrorDirective,
    ColorErrorContainerDirective,
    ColorOnErrorContainerDirective,
    ColorBackgroundDirective,
    ColorOnBackgroundDirective,

    ColorSurfaceDirective,
    ColorOnSurfaceDirective,
    ColorSurfaceVariantDirective,
    ColorOnSurfaceVariantDirective,
    ColorSurfaceBrightDirective,
    ColorSurfaceDimDirective,
    ColorOnSurfaceBackDirective,
    ColorInverseSurfaceDirective,
    ColorInverseOnSurfaceDirective,
    ColorSurfaceLowestDirective,
    ColorSurfaceLowDirective,
    ColorSurfaceContainerDirective,
    ColorSurfaceHighDirective,
    ColorSurfaceHighestDirective,
    ColorShadowTintDirective,
    ColorScrimDirective,
    
    ColorOutlineDirective,
    ColorOutlineVariantDirective,
    ColorShadowDirective,
    BorderBottomOutlineDirective,
    BorderTopOutlineDirective,
    BorderLeftOutlineDirective,

    FontFamilyDirective,
    DisplayFontFamilyDirective,

    InputTextFieldConventionalComponent,

    CardAction
  ]
})
export class CoreUIModule { }
