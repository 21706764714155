import {
    Component,
    ViewEncapsulation,
    ViewChild,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    NgZone,
    Input,
  } from "@angular/core";
  
  import { EventsService } from "@coreui//services/event.service";
  import { UIColorModel } from "@coreui/services/ui.color.model.service";
  import { UILayout } from '@coreui//services/ui.layout.service';
  
  
  @Component({
    selector: "saas-collections-actions-slider",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    template: `
  
      <slider-sheet [direction]="'bottom'" [size]="320">
        <div bottom>
        
          
  
        </div>
      </slider-sheet>		
  
        
    `,
  })
  
  export class SaasCollectionsActionsSlider {
    private eventsService: EventsService;
    private uiLayout: UILayout
    private changeDetectorRef: ChangeDetectorRef;
    uiColorModel: UIColorModel;
  
      
    _closeIcon: string = "plus";
    _border: string = "";
    _containerHeight: number = 100
  
    constructor(
      public ref: ChangeDetectorRef,
      private _uiLayout: UILayout,
      _eventsService: EventsService,
      private _uiColorModel: UIColorModel
    ) {
      this.eventsService = _eventsService;
      this.uiColorModel = _uiColorModel;
      this.uiLayout = _uiLayout
      this.changeDetectorRef = ref;
  
      let self = this;
      this.eventsService.on("onRefresh", function (args: any) {
      });
    }
  
    close() {
        this._closeIcon = "plus";
        setTimeout(() => {
          this.eventsService.broadcast("onHideRightSlider", {});
        }, 300);
      }
    

    ngOnInit() {
    }
  
    ngOnDestroy() {
      this.close();
    }
  
    ngAfterViewInit() {}
  }
  