import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'
import { UIColorModel } from '@coreui/services/ui.color.model.service';
import { ApplicationService } from '../../services/application.service';

import { TimeZonesService } from '@libraries/timezones/timezones.service';


@Component({
	selector: 		'travel-screen',
	encapsulation: ViewEncapsulation.None,

	template:	`

	<content-container-scrollable>

			<airline01-trip-details></airline01-trip-details>	

	</content-container-scrollable>
		
	`,

})


export class TravelScreen {

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	private timeZonesService: TimeZonesService
	uiColorModel: UIColorModel
	applicationService: ApplicationService



	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel, private _applicationService: ApplicationService,
		private _timeZonesService: TimeZonesService) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
		this.applicationService = _applicationService
		this.timeZonesService = _timeZonesService
	}

	ngOnInit() {
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

}
