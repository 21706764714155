import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  ChangeDetectorRef,
  Inject,
  NgZone,
  OnInit,
  EventEmitter,
  HostListener,
  ChangeDetectionStrategy,
} from '@angular/core';

import { EventsService } from '../../../services/event.service';
import { UIColorModel } from '../../../services/ui.color.model.service';
import { NavigationRailSettings } from '../../../classes/navigation/navigation.rail.class';

@Component({
  selector: 'navigation-rail',
  inputs: ['data'],
	outputs: ['clicked', 'hoverenter', 'hoverexit'],
  changeDetection: ChangeDetectionStrategy.Default,

  template: `
    <div class="extended-menu-container {{ _cssShowHide }}" surfaceContainerLow
		      (mouseleave)=menuHoverExit($event)>
      <ul class="menu-container">

        <span *ngFor="let dat of this._data.getLevel2Items(this.activeParentId)">
            <span *ngIf="this.hasSubMenuL2(dat.id)==true">
              <menu-item-collapsible 
                [id]=dat.id
                [label]=dat.label
                [active]=dat.isPressed
                [hovered]=dat.isHovered
                [submenu]=_data.getLevel3Items(dat.id)
                (clicked)="subMenuClicked($event)">
              </menu-item-collapsible>
            </span>
            <span *ngIf="this.hasSubMenuL2(dat.id)==false">
              <menu-item-no-icon 
                [id]=dat.id
                [label]=dat.label
                [active]=dat.isPressed
                (clicked)="subMenuClicked(dat.id)">
              </menu-item-no-icon>

            </span>
        </span>

      </ul>
    </div>
    <div
      class="_navigation-rail"
      surfaceContainerLow
      [style.borderRight]="this._borderColor"
      [style.height.px]=this._containerHeight>
      <div *ngIf="this._data.showMenu" class="menu" (click)="handleMenuClick()">
        <i class="mdi mdi-menu" onSecondaryContainer></i>
      </div>
      <div *ngIf="this._data.showFab==true" class="fab-wrapper">
        <fab [icon]="'lightning-bolt'"></fab>
      </div>
      <div class="destinations">
        <navigation-icon
          *ngFor="let dat of this._data.getLevel1Items()"
          [id]=dat.id
          [icon]=dat.icon
          [label]=dat.label
          [disabled]=dat.isDisabled
          [pressed]=dat.isPressed
          [hovered]=dat.isHovered
          [badgevalue]=dat.badgeValue
          [smallbadge]=dat.smallBadge
          (clicked)=handleClick(dat.id)
          (hoverenter)="handleHoverEnter($event)"
        ></navigation-icon>
      </div>
    </div>
  `,
})
export class NavigationRailComponent {
  @Output()
  clicked = new EventEmitter();
	hoverenter = new EventEmitter();
	hoverexit = new EventEmitter();

  @Input()
  set data(value: NavigationRailSettings) {
    this.setData(value);
  }

  private changeDetectorRef: ChangeDetectorRef;
  private eventsService: EventsService;
  uiColorModel: UIColorModel;

  _showMenu: boolean = false
  _showFAB: boolean = false

  _surfaceColor: string = '';
  _cssShowHide: string = 'hide';
  _cssShadowClass: string = '';
  _cssAnimationClass: string = '';
  _borderColor: string = 'none';
  _data: NavigationRailSettings = new NavigationRailSettings();

  subMenu: any = []
  activeParentId: string = ""
  subMenuItemHighlightColor: string = ""

  _containerHeight: number = 100

  constructor(
    public ref: ChangeDetectorRef,
    private _eventsService: EventsService,
    private _uiColorModel: UIColorModel
  ) {
    this.changeDetectorRef = ref;
    this.eventsService = _eventsService;
    this.uiColorModel = _uiColorModel;

		let self = this;
		this.eventsService.on('onHoverMenuItem', function (args: any) {
			self.handleHoverEnter(args.id)
		});
		this.eventsService.on('onHoverExitMenuItem', function (args: any) {
      self._cssShowHide = 'hide';	
		});
		this.eventsService.on('onDocumentClick', function (args: any) {
      if(args.clientX > 362)
        self._cssShowHide = 'hide';	
		});
		this.eventsService.on('onResize', function (args: any) {
      self._containerHeight = window.innerHeight
		});

    
  }



	////////////////////////////////////
	// Inputs
	////////////////////////////////////
  setData(value: NavigationRailSettings) {
    this._data = value;
    this.changeDetectorRef.detectChanges();
  }


	////////////////////////////////////
	// Handlers
	////////////////////////////////////
	handleClick($event: any) {
    if(this._data.hasSubmenu($event)) {
      this._cssShowHide = 'show';
    } else {
      this._cssShowHide = 'hide';	
      this.clicked.emit($event);
    }
	}

	handleHoverEnter($event: any) {
    if(this._data.hasSubmenu($event)) {
      this.subMenu = this._data.getLevel2Items($event)
      this.activeParentId = $event
      this._cssShowHide = 'show';
    } else {
      this._cssShowHide = 'hide';	
    }
    this.hoverenter.emit($event)
  }

	handleMouseLeave($event: any) {
	}


  handleMenuClick() {
      this.clicked.emit("navigation-rail-menu");
	}
	////////////////////////////////////
	// States & Action
	////////////////////////////////////
  itemEnabled(id: string) {
  }


  itemPressed(id: string) {
  }

  iconHovered(id: string) {
    if(this._data.hasSubmenu(id)) {
      this.subMenu = this._data.getLevel2Items(id)
      this._cssShowHide = 'show';
    } else {
      this._cssShowHide = 'hide';	
    }
  }

  subMenuClicked(id: string) {
    if(this._data.hasSubmenu(id) == false) {
      this._cssShowHide = 'hide';	
      this.clicked.emit(id);
    }
  }


  public hasSubMenuL2(id: string): boolean {
    return this._data.hasSubmenuL2(id) 
  }

  menuClick() {
    if (this._cssShowHide == 'show') {
      this._cssShowHide = 'hide';
    } else {
      this._cssShowHide = 'show';
    }
  }

  menuHoverEnter($event: any) {
    
  }

  menuHoverExit($event: any) {
    if($event.clientX > 322) {
      setTimeout(() => {
        this._cssShowHide = 'hide';	
      }, 250);
      }
  }


  submenuHoverEnter($event: any) {
	this.subMenuItemHighlightColor = this.uiColorModel.surfaceVariant
  }
  submenuHoverLeave($event: any) {
	this.subMenuItemHighlightColor = "rgba(0,0,0,0)"
  }

  ngOnInit() {
    this._containerHeight = window.innerHeight
  }

  ngOnChanges() {}

  ngOnDestroy() {}
}

