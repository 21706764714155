import {
	Component,
	  ViewEncapsulation,
	  Input,
	  Output,
	  ChangeDetectorRef,
	  Inject,
	  NgZone,
	  OnInit,
	  EventEmitter,
	  ChangeDetectionStrategy
  } from '@angular/core';
  
  
  
  @Component({
	  selector: 		'avatar-stack-5',
	inputs: ['dat'],
  
	  encapsulation: ViewEncapsulation.None,
  
	  template:	`
  
		<div class="avatar-stack-wrapper">
			<div class="_avatar-stack-5" (click)="toggleOpen()">
				<span *ngFor="let dat of this.avatars" class="avatar {{isOpenClass}}" surfaceContainerHigh>
					<img [src]=dat.imgsrc/>
				</span>
				<span *ngIf="showTeamDisplayCount==true" class="avatar top"
					font-family onSurface surfaceContainerHigh>+{{teamDisplayCount}}</span>
			</div>
		</div>
  
	  `,
  
  })
  
  
  export class AvatarStack5Component {
  
	@Input()
	set dat(value: any) { this.setDat(value); }
  
	_data:                  any = [];
	avatars:                any = []
	teamDisplayCount:       number = 0
	showTeamDisplayCount:   boolean = false
  
	isOpenClass:            string = ""
  
	_defaultAvatar: string = "./assets/avatars/female-avatar-1.jpg"

	constructor() {
		this.compressed()
	}
  
	setDat(value: any) {
	  this._data = value;
	  let i = 0
	  for(i=0;i<this._data.length;i++) {
		if(this.avatars.length > 4) {
		  this.showTeamDisplayCount = true
		  this.teamDisplayCount = this._data.length - this.avatars.length
		} else {
		  this.avatars.push(this._data[i])
		}
	  }
	}
  
	//////////////////////////////////////////////////////////////////
	// Teams
	//////////////////////////////////////////////////////////////////
	
  
  
	toggleOpen() {
	  if(this.isOpenClass == "") {
		this.isOpenClass = "open"
		this.expanded()
	  } else {
		this.isOpenClass = ""
		this.compressed()
	  }
	}
  
	compressed() {
	  this.avatars = []
	  this.showTeamDisplayCount = false
	  let i = 0
	  for(i=0;i<this._data.length;i++) {
		if(this.avatars.length > 4) {
		  this.showTeamDisplayCount = true
		  this.teamDisplayCount = this._data.length - this.avatars.length
		} else {
		  this.avatars.push(this._data[i])
		}
	  }
	}
  
	expanded() {
	  this.avatars = []
	  this.showTeamDisplayCount = false
	  let i = 0
	  for(i=0;i<this._data.length;i++) {
		if(this.avatars.length > 12) {
		  this.showTeamDisplayCount = true
		  this.teamDisplayCount = this._data.length - this.avatars.length
		} else {
		  this.avatars.push(this._data[i])
		}
	  }
	}
  
  
	  ngOnInit() {
	  }
  
	  ngOnDestroy() {
  
	  }
  
	  ngAfterViewInit() {
  
	  }
  
  
  
  }
  