import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpEventType
} from "@angular/common/http";
import {
  Observable,
} from "rxjs";

import { EventsService } from '@coreui/services/event.service'
import { ApplicationService } from "../../services/application.service";

@Injectable()
export class ImageGalleryUploadService {
  http: HttpClient;
  applicationService: ApplicationService;
  private eventsService: EventsService
  private _filesToUpload: any[] = [];

  private _activeGalleryId: string = "";

  constructor(
    private _http: HttpClient,
    private _applicationService: ApplicationService,
	  private _eventsService: EventsService
  ) {
    this.http = _http
    this.applicationService = _applicationService
  	this.eventsService = _eventsService

  }

  public fileBrowseHandler($event: any): void {
    const element = $event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      const arr = Array.from(fileList);
      arr.forEach((file) => {
        let rec = {
          file: file,
          original_filename: file.name,
          size: file.size,
          progress: 0,
          type: file.type,
          isUploading: false,
          isDone: false,
          url: "",
          objectid: "",
          filename: "",
          uploadedTS: 0
        };
        this._filesToUpload.push(rec);
      });

      this.uploadAllFiles();
    }
  }



  private uploadAllFiles() {
    // for each file...
    let self = this
    for (let dat of this._filesToUpload) {

      if (dat.isDone == false) {
      
        this.readFile(dat.file).then((fileContents) => {
          this.applicationService.dom.presignedurlPut(dat.file.name, "sky01")
          .subscribe(
            (value: any) => {
              console.log(JSON.stringify(value, null, 4));
              let puturl = value.body.url
              let objectid = value.body.objectid
              this.updateObjectId(dat.original_filename, objectid)
              this.upload_file_to_s3(
                puturl,
                fileContents,
                objectid
              ).subscribe(
                (value) => {
                  
                  if (value.type === HttpEventType.UploadProgress) {
                    const percentDone = Math.round(100 * value.loaded / value.total);
                    this.updateProgress(objectid, percentDone)
                    console.log('Progress ' + percentDone + '%    ' +  objectid);
                    this.eventsService.onRefresh()
                } 
            

                  if(value.status == 200) {
                    console.log("File uploaded: " + objectid)	
                    let objecttype = this.detectFileType(dat.file.name)
                    let size = dat.file.size
                    let ext =  dat.file.name.split(".").pop() || ""
                    let gid = "402b3bc3-453e-4199-afd9-e19642efb025"

                    this.applicationService.dpImageGalleryApi.addObject(gid, objectid, objecttype, ext, size).subscribe(
                      (value) => {
                        console.log("Added....")
                        console.log(JSON.stringify(value, null, 4));
                        let id = value.body.data.objectid
                        let url = value.body.data.url
                        this.eventsService.broadcast("onImageGalleryObjectAdded", {id: id, url: url})

                      },	
                      (err) => {
                        console.log(err);
                        console.log("Error contacting server");
                      }
                      );                    
                  }



              },
              (err) => {
                console.log(err);
                console.log("Error contacting server");
              }
            );


            },
            (err: any) => {
              console.log(err);
              console.log("Error contacting server");
            }
          );

        });

      }
    }
  }





  ////////////////////////////////////////
  // Manage the file uploads
  ////////////////////////////////////////
  setAsUploading(id: string) {
	let i=0
	for(i=0;i<this._filesToUpload.length;i++) {
		if(this._filesToUpload[i].objectid == id) {
			this._filesToUpload[i].isUploading = true
		}
	}
  }
  updateProgress(id: string, progress: number) {
    let i=0
    for(i=0;i<this._filesToUpload.length;i++) {
      if(this._filesToUpload[i].objectid == id) {
        let perc = (this._filesToUpload[i].size * progress/100)
        this._filesToUpload[i].progress = perc
        if(perc >= 100) {
          this._filesToUpload[i].progress = this._filesToUpload[i].size
          this._filesToUpload[i].isUploading = false
          this._filesToUpload[i].isDone = true
          let now = Date.now()
          this._filesToUpload[i].uploadedTS = now
        }
      }
    }
  }


  updateObjectId(filename: string, objectId: string) {
    let i=0
    for(i=0;i<this._filesToUpload.length;i++) {
      if(this._filesToUpload[i].original_filename == filename) {
        console.log(this._filesToUpload[i].original_filename + '   ' + filename)
        this._filesToUpload[i].objectid = objectId
      }
    }
  }
   

  getUploadingFileRecord(id: string): any {
	let result: any = {}
	let i=0
	for(i=0;i<this._filesToUpload.length;i++) {
		if(this._filesToUpload[i].objectid == id) {
			result = this._filesToUpload[i]
		}
	}
	return result
  }

  removeAfter3Secs(): void {
    let delList: any[] = []
    let now = Date.now()
    let i=0
    for(i=0;i<this._filesToUpload.length;i++) {
      if(this._filesToUpload[i].uploadedTS != 0 &&
        (now - this._filesToUpload[i].uploadedTS > 3000)) {
          delList.push(i)
      }
    }

    let j=0
    for(j=0;j<delList.length;j++) {
      this._filesToUpload.splice(delList[j],1)
      if(this._filesToUpload.length == 0) {
        this.eventsService.broadcast("onHideModalOverlay", {})
      }
    }

  }
  



  processFileUpload(id: string, uploaded: number, total: number) {
    let perc = Math.round((100 * uploaded) / total);
    console.log(id + "  " + perc + "%   " + uploaded);
    return perc;
  }

  public getTotalUploadingStats() {}

  public formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) return "0";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [
      " B",
      " KB",
      " MB",
      " GB",
      " TB",
      " PB",
      " EB",
      " ZP",
      " YB",
    ];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  detectFileType(filename: string): string {
    let extn = filename.split(".").pop();
    let contentType = "application/octet-stream";
    if (extn == "html") contentType = "text/html";
    if (extn == "css") contentType = "text/css";
    if (extn == "js") contentType = "application/javascript";
    if (extn == "pdf") contentType = "application/pdf";
    if (extn == "png" || extn == "jpg" || extn == "gif" || extn == "jpeg")
      contentType = "image/" + extn;
    return contentType;
  }

  upload_file_to_s3(
    put_url: string,
    file: string | ArrayBuffer,
    filename: string
  ): Observable<any> {
    let fileType = this.detectFileType(filename);

    return this.http.put(put_url, file, {
      reportProgress: true,
      observe: "events",
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Content-Type": fileType,
      }),
    });
  }

  private async readFile(file: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        return resolve((e.target as FileReader).result || "");
      };

      reader.onerror = (e) => {
        console.error(`FileReader failed on file ${file.name}.`);
        return reject(null);
      };

      if (!file) {
        console.error("No file to read.");
        return reject(null);
      }

      reader.readAsArrayBuffer(file);
    });
  }

  public get filesToUpload(): any[] {
    return this._filesToUpload;
  }
  public set filesToUpload(value: any[]) {
    this._filesToUpload = value;
  }


  public get activeGalleryId(): string {
    return this._activeGalleryId;
  }
  public set activeGalleryId(value: string) {
    this._activeGalleryId = value;
  }

}
