import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  ChangeDetectorRef,
  Inject,
  NgZone,
  OnInit,
  EventEmitter,
  HostListener,
  ChangeDetectionStrategy,
} from '@angular/core';

import { EventsService } from '../../../services/event.service';
import { UIColorModel } from '../../../services/ui.color.model.service';
import { NavigationBarSettings } from '../../../classes/navigation/navigation.bar.class';

@Component({
  selector: 'navigation-bar',
  inputs: ['data'],
  outputs: ['clicked'],
  changeDetection: ChangeDetectionStrategy.Default,

  template: `

    <div
      class="_navigation-bar" surfaceContainerLow>
          <div class="item" *ngFor="let dat of this._data.destinations">
              <navigation-icon [id]=dat.id  
              [label]=dat.label 
              [icon]=dat.icon
              [pressed]=dat.isActive
              (click)=btnClicked(dat.id)>
            </navigation-icon>
          </div>
    </div>
  `,
})
export class NavigationBarComponent {
  @Output()
  clicked = new EventEmitter();

  @Input()
  set data(value: NavigationBarSettings) {
    this.setData(value);
  }

  private changeDetectorRef: ChangeDetectorRef;
  private eventsService: EventsService;
  uiColorModel: UIColorModel;
  _surfaceColor: string = '';
  _cssShowHide: string = 'hide';
  _cssShadowClass: string = '';
  _cssAnimationClass: string = '';
  _borderColor: string = 'none';
  _data: NavigationBarSettings = new NavigationBarSettings();

  subMenu: any = []
  activeParentId: string = ""
  subMenuItemHighlightColor: string = ""

  constructor(
    public ref: ChangeDetectorRef,
    private _eventsService: EventsService,
    private _uiColorModel: UIColorModel
  ) {
    this.changeDetectorRef = ref;
    this.eventsService = _eventsService;
    this.uiColorModel = _uiColorModel;
  }

  setData(value: NavigationBarSettings) {
    this._data = value;
    this.changeDetectorRef.detectChanges();
  }

  btnClicked(id: string) {
    let rec = {
      parent: null,
      id: id
    }
    this.clicked.emit(rec);
  }
  

  ngOnInit() {}

  ngOnChanges() {}

  ngOnDestroy() {}
}
