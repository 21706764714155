import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';

import { CoreUIModule } from '../app/coreui/coreui.module'

import { UICoreModule } from '../app/application/ui-core/uicore.module'
import { SpiritansModule } from '../app/application/spiritans/spiritans.module'
import { LoyaltyAppModule } from '../app/application/loyaltyapp/loyaltyapp.module'
import { PeachMarketingModule } from '../app/application/peachmarketing/peachmarketing.module'
/* Layout Templates */
// import { LayoutTemplatesModule } from '@libraries/layout-templates/layout.templates.module'


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CoreUIModule,
    UICoreModule,
    SpiritansModule,
    LoyaltyAppModule,
    PeachMarketingModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
