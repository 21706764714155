
import { NavigationRailSettings } from '@coreui/classes/navigation/navigation.rail.class'
import { NavigationDrawerSettings } from '@coreui/classes/navigation/navigation.drawer.class'
import { NavigationDrawerMenu } from '@coreui/classes/navigation/navigation.drawer.menu.class'
import { NavigationBarSettings } from '@coreui/classes/navigation/navigation.bar.class'



export class NavigationUISettings {
  
    constructor() {
    }

    public defineNavigationRail(): NavigationRailSettings {
        let navrail: NavigationRailSettings = new NavigationRailSettings()
        navrail.showMenu = true
        navrail.addDestination("home", "Home", "home", "home-outline")
        navrail.addDestination("getstarted", "Get Started", "rocket-launch", "rocket-launch-outline")
        navrail.addDestination("layout", "Layout", "page-layout-body", "page-layout-body")
    
        navrail.addDestination("styles", "Style", "palette", "palette-outline")
        navrail.addDestination("styles.color", "Color", "", "")
        navrail.addDestination("styles.typography", "Typograpphy", "", "")
        navrail.addDestination("styles.icons", "Icons", "", "")
        navrail.addDestination("styles.maps", "Maps", "", "")
    
        navrail.addDestination("templates", "Templates", "view-dashboard", "view-dashboard-outline")
        navrail.addDestination("templates.landing", "Landing Pages", "", "")
        navrail.addDestination("templates.landing.landing01", "Landing 01", "", "")
        navrail.addDestination("templates.article", "Article", "", "")
        navrail.addDestination("templates.article.blog01", "Blog Article Page", "", "")
        navrail.addDestination("templates.article.m3", "M3 Style", "", "")
        navrail.addDestination("templates.technicaldoc", "Technical", "", "")
        navrail.addDestination("templates.technicaldoc.01", "Technical Document 01", "", "")
    
    
        navrail.addDestination("collections", "Collections", "view-grid", "view-grid-outline")
        navrail.addDestination("collections.pictures", "Images", "", "")
        navrail.addDestination("collections.pictures.manager", "Images Manager", "", "")
        navrail.addDestination("collections.colors", "Colors", "", "")
        navrail.addDestination("collections.gradients", "Gradients", "", "")
    
        return navrail
      }
  
      public defineNavigationBar(): NavigationBarSettings {
        let navBar: NavigationBarSettings = new NavigationBarSettings()
        navBar.addDestination("home", "Home", "view-dashboard-outline", "view-dashboard")
        navBar.addDestination("discover", "Discover", "map-marker-outline", "map-marker")
        navBar.addDestination("plan", "Plan", "hexagon-multiple-outline", "hexagon-multiple")
        navBar.addDestination("activity", "Activity", "clipboard-pulse-outline", "clipboard-pulse")
        return navBar
      }
    
      public defineNavigationDrawer(): NavigationDrawerSettings {
        let navDrawer: NavigationDrawerSettings = new NavigationDrawerSettings()
        navDrawer.addDestination("home", "Home", "home", "home-outline")
        navDrawer.addDestination("templates.article.m3", "Article Page", "book-account", "book-account-outline")
        navDrawer.addDestination("templates.article.m3", "Article Page", "book-account", "book-account-outline")
        navDrawer.addDestination("templates.technicaldoc.01", "Favourites", "heart",  "heart-outline")
        navDrawer.addDestination("patterns", "Trash", "delete", "delete-outline")
        navDrawer.addDestination("icons", "Icons", "apps", "apps")
        return navDrawer
      }
    
}