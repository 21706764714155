import {
	Component,
	ViewEncapsulation,
	ChangeDetectorRef,

	ViewChild,
	ChangeDetectionStrategy
} from '@angular/core';

import { UILayout } from '@coreui/services//ui.layout.service'
import { EventsService } from '@coreui/services/event.service'
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { TypographyModel } from '@coreui/services/typography.service';
import { ApplicationService } from '../../../services/application.service';


@Component({
	selector: 		'image-collection-page',
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`

		<content-container-scrollable>
			<div id="scroll-to-top" class="_saas-page-header">
				<div class="heading" onSurface>Gradients</div>
			</div>

			<saas-collections-toolbar (searchvalue)=search($event) (clicked)=toolbarAction($event)></saas-collections-toolbar>
			<icon-button [icon]="'heart-outline'" [activeicon]="'heart'" (click)=delete()></icon-button>
			<scroll-top-button [scrollshowvalue]=144 (clicked)=scrollToTop()></scroll-top-button>
			<images-collection-anim [data]=imageData (selected)=getSelected($event)></images-collection-anim>
		</content-container-scrollable>


		`,
})


export class ImageCollectionPage {
	private changeDetectorRef: ChangeDetectorRef
	private uiLayout: UILayout
	private uiNavigation: UINavigation
    private eventsService: EventsService
	private typographyModel: TypographyModel
	applicationService: ApplicationService

	imageData: any[] = []

	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService, 
		private _uiLayout: UILayout,
		private _uiNavigation: UINavigation,
		private _applicationService: ApplicationService,
		private _typographyModel: TypographyModel) {
		this.uiLayout = _uiLayout
		this.eventsService = _eventsService
		this.uiNavigation = _uiNavigation
		this.typographyModel = _typographyModel
		this.applicationService = _applicationService
		this.changeDetectorRef = ref
	}


	ngOnInit() {
	//	this.addImage("1", "https://artbup.com/storage/pictures/pic_5c2689b7b0982_orig.jpeg")
	//	this.addImage("2", "https://artbup.com/storage/pictures/pic_5c2689b7b0982_orig.jpeg")
		this.getAllObjects()
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}


	scrollToTop() {
		let textElement: any = document.getElementById("scroll-to-top")
		textElement.scrollIntoView({behavior: "smooth"})
	}

	toolbarAction($event: any) {
		if($event == "select-on")
			this.eventsService.broadcast("selectModeOn", {})	

		if($event == "select-off")
			this.eventsService.broadcast("selectModeOff", {})	

		if($event == "action-clicked")
			this.eventsService.broadcast("onShowRightSlider", {id: "file-upload"})

	}	

	search($event: any) {
		console.log($event)
	//	this._searchResults = this.searchResults($event)
	}	


	addImage(id: string, src: string) {
		let rec = {
			id:		id,
			imgsrc:	src
		}
		this.imageData.push(rec)
	}

	getAllObjects() {
		let sid = this.applicationService.session.sessionid
		this.applicationService.dom.getAllObjects(sid).subscribe(
			value => {
				this.imageData = value.body.data
				//this.changeDetectorRef.detectChanges()
			}, 
			err => {
				console.log(err)
				console.log("Error contacting server")
		})		
	}

	selected: any[] = []
	getSelected($event: any[]) {
		this.selected = []
		this.selected = $event
	}

	delete() {
		this.eventsService.broadcast("deleteSelected", {})
		let sid = this.applicationService.session.sessionid
		this.applicationService.dom.trashObjects(sid, this.selected).subscribe(
			value => {
				console.log(JSON.stringify(value, null, 4))
			}, 
			err => {
				console.log(err)
				console.log("Error contacting server")
		})		
	}
}
