import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'
import { UIColorModel } from '@coreui/services/ui.color.model.service';
import { ApplicationService } from '../../services/application.service';
import { TimeZonesService } from '@libraries/timezones/timezones.service';

import { DpLocationV1 } from '@libraries/domainprotocols/locationV1/dp.locationv1.class';
import { DpLocationRecord } from '@libraries/domainprotocols/locationV1/dp.location.record.class';
import { HttpClient } from  '@angular/common/http'


@Component({
	selector: 		'home-screen',
	encapsulation: ViewEncapsulation.None,

	template:	`

	<content-container-scrollable>
		<optimised-content-container>

			<div class="_headline-medium" display-font onSurface>Open Protocol Stack</div>
			<div class="_headline-medium" display-font onSurface>Onboarding Protocols</div>
			<div class="_headline-medium" display-font onSurface>Onboarding UX</div>
			<div class="_headline-medium" display-font onSurface>Decentralise... to recentralise</div>
			<div class="_headline-medium" display-font onSurface>Mitigating Coordination Costs</div>
			<div class="_headline-medium" display-font onSurface>Vertical Integration</div>
			<div class="_headline-medium" display-font onSurface>AI vs More 'Augmented' Humans</div>
			<div class="_headline-medium" display-font onSurface>'Portable, Structured Data' as the product </div>

		</optimised-content-container>
	</content-container-scrollable>
	<div class="floating-logo">
		<img [src]="'./assets/loyalty/logos/peach_marketing_logo.png'" />
	</div>
	
	`,

})


export class HomeScreen {

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	uiColorModel: UIColorModel
	applicationService: ApplicationService
	private timeZonesService: TimeZonesService
	http: HttpClient

	goodmorningTextState: string = ""
	goodmorningOpacity: number = 1

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel, private _applicationService: ApplicationService,
		private _timeZonesService: TimeZonesService,
		_http: HttpClient) {
		this.http = _http	
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
		this.applicationService = _applicationService
		this.timeZonesService = _timeZonesService

	}


	w: number = 0
	ngOnInit() {
	//	this.eventsService.broadcast("onShowModalOverlay", {id: "radar-popup"})
	//	this.applicationService.activeBackground = "sunrise"
		this.w = window.innerWidth
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
	}


	pick() {
		this.eventsService.broadcast("onShowModalOverlay", {id: "people"})
	}

	test() {
		let location = new DpLocationRecord()
		location.address = "200 Queen Street"
		location.city = "Brisbane"
		location.country = "Australia"
		location.countryCode2 = "AU"

		let api = new DpLocationV1(this.http)
		api.init("https://pg8.in", "skyai_root.FVsaDg1rQEIAcRpqQEukPkqxjeOmPb8NeJgn9Sef")

		api.newLocation("test001", location.toJSON()).subscribe(
			value => {
				console.log(JSON.stringify(value.body.data, null, 4))
			}, 
			err => {
				console.log(err)
				console.log("Error contacting server")
		})		


		
	}
}
