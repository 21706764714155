import { Component, HostListener, OnInit } from '@angular/core';

declare var device: any;

@Component({
  selector: 'app-root',
  template:	`


  <div [ngSwitch]=this.activeApp>
    <div *ngSwitchCase="'uiguide'">
      <uicore-guide-main></uicore-guide-main>
    </div>

    <div *ngSwitchCase="'spiritans'">
      <spiritans-web-main></spiritans-web-main>
    </div>

    <div *ngSwitchCase="'loyalty-app'">
      <loyalty-webapp></loyalty-webapp>
    </div>

    <div *ngSwitchCase="'peachmarketing-app'">
      <peachmarketing-webapp></peachmarketing-webapp>
    </div>
    

  </div>



      `
})
export class AppComponent {
  title: string = '';

  activeApp: string = ""

  ngOnInit() {
  //  this.activeApp = "peachmarketing-app"

    document.title = this.title
    document.addEventListener("deviceready", function() {
      alert(device.platform);
    }, false);

    document.addEventListener("touchmove", function(e) {
      e.preventDefault();
    }, false);

    document.ondblclick = function(e) {
      e.preventDefault();
    }

    window.onload = (event) => { //triger when every thing is load (files, assets, components' resolver, etc)
      // All good so add clas "splashScreenFade" to hide the splash screen slowly 
      let doc = document.getElementById("splashScreen") as HTMLElement
      doc.classList.add("splashScreenFadeHide")
      //we are listening the "splasn screen" turn transparent and after apply the "display = none"  
      doc.addEventListener('transitionend', (e: any) => {
        doc.style.display = 'none'
        this.activeApp = "peachmarketing-app"
      })
    }

  }

  ngOnDestroy() {
  }

}
