import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter,
	ChangeDetectionStrategy
} from '@angular/core';

import { FormControl, Validators,  ReactiveFormsModule } from '@angular/forms';


@Component({
	selector: 'text-input',
	inputs: ['label', 'placeholder', 'errormsg', 'opacity', 'initalvalue', 'prefix', 'mandatory',
					'radius', 'filled', 'type', 'trailingicon', 'background'],
	outputs: ['value'],
	changeDetection: ChangeDetectionStrategy.Default,


	template: 	`
				<div class="conventional-input-field-wrapper opacity-transition-350" [style.opacity]="this._opacity">
          <div *ngIf="this.labelTxt != ''" class="conventional-input-field-label" font-family onSurface>{{this.labelTxt}} <span *ngIf="isMandatory==true" style="font-weight: 900" error-color>*</span></div>
					<div class="trailing-icon-wrapper" [style.top.px]="topOffset" (click)="toggleShowHidePassword()">
						<i class="mdi {{trailingIcon}}"></i>
					</div>
					<input #ifield class="conventional-input-field" type="{{_type}}" placeholder="{{this.placeHolderTxt}}"
					 				(keyup)="onKey(ifield.value)"
									[style.borderRadius.px]="_radius"
									[style.border]="borderCSS"
									font-family onSurface surfaceContainerHighest>
					<div class="conventional-input-field-feedback-text" body2 error-color>{{this.errorMsg}}</div>
				</div>
								`,

})



export class InputTextFieldConventionalComponent {
	@Output()
	value = new EventEmitter();

	@Input()
	set label(value: string) { this.setLabel(value); }
	set placeholder(value: string) { this.setPlaceholder(value); }
	set errormsg(value: string) { this.setErrorMsg(value); }
	set opacity(value: number) { this.setOpacity(value); }
	set mandatory(value: boolean) { this.setMandatory(value); }

	set type(value: string) { this.setType(value); }

	set initalvalue(value: string) { this.setInitialValue(value); }
	set prefix(value: string) { this.setPrefix(value); }

	set radius(value: number) { this.setBorderRadius(value); }
	set filled(value: boolean) { this.setIsFilled(value); }
	set background(value: string) { this.setBackground(value); }

	set trailingicon(value: string) { this.setTrailingIcon(value); }


	changeDetectorRef: ChangeDetectorRef;
	textValue = new FormControl('', []);
	labelTxt: 							string = "";
	placeHolderTxt: 				string = "";
	_opacity:								number = 1;
	_radius:								number = 2;

	_type:									string = "text";

	errorMsg:								string = ""
	isFocused:							boolean = false;
	prefixValue:						string = "";
	isMandatory:						boolean = false;
	isMandatoryText:				string = "*";

	_isFilled:							boolean = false;
	fillCSS:							string = "";
	bgColor:							string = ""
	bgHex:								string = ""
	bgOpacity:							number = 0
	borderCSS:							string = "1px solid rgba(0,0,0,0.2)";

	trailingIcon:						string = "";
	trailingIconColor:			string = "#000000";
	topOffset:							number = 14;
	//password
	_showPassword:				  boolean = false;
	passwordHideIcon:				string = "mdi-eye-off";
	passwordShowIcon:				string = "mdi-eye";

	constructor(public ref: ChangeDetectorRef) {
		this.changeDetectorRef = ref
	}


	onKey(e: any) {
		this.value.emit(e);
	}

	setLabel(value: string) {
		this.labelTxt = value;
		if(this.labelTxt == "") {
			this.topOffset = 16;
		} else {
			this.topOffset = 54;
		}
	}

	setPlaceholder(val: string) {
		this.placeHolderTxt = val;
	}

	setErrorMsg(val: string) {
		this.errorMsg = val;
	}

	setOpacity(val: number) {
		this._opacity = val;
	}

	setPrefix(val: string) {
		this.prefixValue = val;
	}

	setMandatory(val: boolean) {
		this.isMandatory = val;
	}

	setInitialValue(val: string) {
		this.textValue.setValue(val);
	}

	setBorderRadius(val: number) {
		this._radius = val;
	}

	setIsFilled(val: boolean) {
		this._isFilled = val;
		if(this._isFilled == true) {
			this.bgOpacity = 0.2
			this.fillCSS = this.bgColorString(this.bgOpacity);
			this.borderCSS = "1px solid rgba(0,0,0,0)";
		} else {
			this.bgOpacity = 0
			this.fillCSS = this.bgColorString(this.bgOpacity);
			this.borderCSS = "1px solid rgba(0,0,0,0.2)";
		}
	}

	setBackground(val: string) {
		this.bgHex = val
	}

	bgColorString(opacity: number): string {
		let rgb = this.hexToRgb(this.bgHex)
		let col  = "rgba(" + rgb.r + "," + rgb.b + "," + rgb.g + "," + opacity + ")"
		return col
	}


	setType(val: string) {
		this._type = val;
		if(this._type == "password") {
			this.trailingIcon = this.passwordHideIcon;
		} else {
			this.trailingIcon = "";
		}
	}

	setTrailingIcon(value: string) {
		this.trailingIcon = "mdi-" + value
	}


	toggleShowHidePassword() {
		if(this._type == "password") {
			if(this._showPassword == true) {
				this.hidePassword();
				this._showPassword = false;
			} else {
				this.showPassword();
				this._showPassword = true;
			}
		}
	}

	//password field
	showPassword() {
		this.trailingIcon = this.passwordShowIcon;
		this._type = "text";
	}

	hidePassword() {
		this.trailingIcon = this.passwordHideIcon;
		this._type = "password";
	}



	ngOnInit() {
	}


	ngOnDestroy() {

  }



  private hexToRgb(hex: string): any {
	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16)
	} : null;
};

}
