import {Injectable} from '@angular/core';
import { Observable, Subject, ReplaySubject, from, of, range } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';


@Injectable()
export class EventsService {
    listeners: any;
    eventSubject: any;
    events: Observable<any>;

    eventDefinition: any = [];
    strictMode:      boolean = false;

    constructor() {
        this.listeners = {};
        this.eventSubject = new Subject();
        this.events = this.eventSubject.asObservable();
  
        this.events.subscribe(
            ({name, args}) => {
                if (this.listeners[name]) {
                    for (let listener of this.listeners[name]) {
                        listener(...args);
                    }
                }
            });
    }

    on(name: string, listener: any) {
      if (!this.listeners[name]) {
        this.listeners[name] = [];
      }
      this.listeners[name].push(listener);
    }

    broadcast(name: string, ...args: any) {
      if(this.strictMode == true) {
        let exists = this.eventIdExists(name);
        if(exists == true) {
          this.eventSubject.next({name,args});
        } else {
          console.log("%cError:  Event '" + name + "' doesnt exist", "color: red");
        }

      } else {
        this.eventSubject.next({name,args});
      }

    }


    onRefresh() {
      this.broadcast("onRefresh", {})
    }



    ////////////////////////////////////////////////////////////////////
    // Event Definition
    ////////////////////////////////////////////////////////////////////
    eventDefinitionRecord(name: string, cat: string, subcat: string, desc: string) {
      let rec = {
        name:           name,
        category:       cat,
        subcategory:    subcat,
        description:    desc
      };
      return rec;
    }


    addEventDefinition(name: string, cat: string, subcat: string, desc: string) {
      if(this.eventIdExists(name) == false) {
        let r = this.eventDefinitionRecord(name, cat, subcat, desc);
        this.eventDefinition.push(r);
      }
    }

    eventIdExists(name: string) {
      let exists = false;
      let i =0;
      for(i=0;i<this.eventDefinition.length;i++) {
        if(this.eventDefinition[i]["name"] == name) {
          exists = true;
        }
      }
      return exists;
    }


    printEventDefinition() {
      let i =0;
      for(i=0;i<this.eventDefinition.length;i++) {
        console.log(this.eventDefinition[i]["name"] + "  " + this.eventDefinition[i]["category"]);
      }
    }



    setStrictMode(val: boolean) { this.strictMode = val; }
    isStrictMode() { return this.strictMode; }

}
