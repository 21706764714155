import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter
} from '@angular/core';


@Component({
	selector: 		'color-swatch-card',
	inputs: ['label', 'hex', 'rgb', 'width', 'elevation', 'disabled'],
	outputs: ['clicked'],


	template: 	`
		<div class="_color-swatch-card-01" elevated [elevation]=1
			[style.width.px]=this.cardWidth [style.height.px]=this.cardHeight
			[style.opacity]=cardOpacity surfaceColorBright>
			<div class="swatch-color"	[style.backgroundColor]=_hex [style.height.px]="this.cardHeight - 36"
				(click)="colorClicked()" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()"></div>
			<div class="swatch-content">
				<div class="swatch-title" subtitle1 onSurface>{{this.labelTxt}}</div>
			</div>
		</div>
								`,

})



export class ColorSwatchCardComponent {
	@Output()
	clicked = new EventEmitter();

	@Input()
	set label(value: string) { this.setLabel(value); }
	set hex(value: string) { this.setHex(value); }
	set rgb(value: string) { this.setRGB(value); }
	set width(value: number) { this.setWidth(value); }
	set elevation(value: number) { this.setElevation(value); }
	set disabled(value: boolean) { this.setDisabled(value); }


	labelTxt: string = "Black";
	hexValue: string = "#000000";
	_hex:			string = "000000"
	rgbValue: string = "0,0,0"
	elevationLevel: number = 1;

	cardWidth:	number = 150;
	cardHeight:	number = 168;
	cardOpacity: number = 1;

	constructor() {
	}

	onMouseEnter() {
		this.elevationLevel = 3;
	}
	onMouseLeave() {
		this.elevationLevel = 1;
	}

	colorClicked() {
		this.clicked.emit(this._hex);
	}


	setLabel(value: string) {
		this.labelTxt = value;
	}

	setHex(value: string) {
		if (value.startsWith('linear-gradient') || value.startsWith('rgb') || value.startsWith('#')) {
			this._hex = value
		} else {
			this._hex = '#' + value;
		}	  
	}

	setRGB(value: string) {
		this.rgbValue = value;
	}

	setElevation(value: number) {
		this.elevationLevel = value;
	}

	setDisabled(value: boolean) {
		if(value == true) {
			this.cardOpacity = 0.3;
		} else {
			this.cardOpacity = 1;
		}
	}


	setWidth(value: number) {
		this.cardWidth = value;
		this.cardHeight = this.cardWidth * 1.12;
	}


	ngOnInit() {

	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
