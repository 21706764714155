import {
  Component,
  ViewEncapsulation,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Input,
} from "@angular/core";

import { EventsService } from '@coreui/services/event.service'
import { UIColorModel } from '@coreui/services/ui.color.model.service';
import { UILayout } from "@coreui/services/ui.layout.service";

@Component({
  selector: "discover-place-card",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  inputs: ["heading", "imgsrc", "mediaheight"],
  template: `
   
        <div class="_discover-place-card" (click)=clicked()>
          <div class="media"
            elevated [elevation]=_elevation primaryContainer>
            <img [src]=_imgsrc [draggable]=false/>
          </div>
          <div class="header">
            <div class="icon-wrapper">
              <i class="mdi mdi-star"></i>
            </div>
            <div class="text">
              <div class="title" font-family>Discover</div>
              <div class="subtitle" font-family>A new restaurant</div>
            </div>
          </div>

          <div class="content">
            <div class="little-circle"></div>
            <div class="circle"></div>
            <div class="heading" font-family>Indigo Restaurant</div>
            <div class="subheading" font-family>Boracay, Philippines</div>
          </div>
          
        </div>

  `,
})
export class DiscoverPlaceCard {
  @Input()
  set heading(value: string) {
    this.setHeading(value);
  }

  set imgsrc(value: string) {
    this.setImgSrc(value);
  }
  set mediaheight(value: number) {
    this.setMediaHeight(value);
  }

  private eventsService: EventsService;
  private changeDetectorRef: ChangeDetectorRef;
  uiColorModel: UIColorModel;
  uiLayout: UILayout;

  _imgsrc: string = "";
  _mediaHeight: number = 88;
  _starColor: string = ""
  _elevation: number = 2


  _heading: string = "Book Tickets";

  constructor(
    public ref: ChangeDetectorRef,
    _eventsService: EventsService,
    private _uiLayout: UILayout,
    private _uiColorModel: UIColorModel
  ) {
    this.eventsService = _eventsService;
    this.uiColorModel = _uiColorModel;
    this.uiLayout = _uiLayout;
    this.changeDetectorRef = ref;

    this._starColor = this.uiColorModel.onBackground

    let self = this;
    this.eventsService.on("onResize", function (args: any) {
    });
  }

  setHeading(value: string) {
    this._heading = value;
  }


  setImgSrc(value: string) {
    this._imgsrc = value;
  }

  setMediaHeight(value: number) {
    this._mediaHeight = value;
  }

  clicked() {
   // alert("boom")

  }





  ngOnInit() {
  }

  ngOnDestroy() {}

  ngAfterViewInit() {}
}
