///////////////////////////////////////////////////////////////////////
// Angular modules
///////////////////////////////////////////////////////////////////////
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

///////////////////////////////////////////////////////////////////////
// 3rd Party Modules - Imports
///////////////////////////////////////////////////////////////////////


import { CoreUIModule } from '@coreui/coreui.module';
import { LayoutTemplatesModule } from '@libraries/layout-templates/layout.templates.module';
import { ColorSwatchesModule } from '@libraries/color-swatches/color.swatches.module';
import { AnimatedTilesModule } from '@libraries/animated-tiles/animatedtiles.module';

import { CoreUIExtensionsModule } from '@libraries/coreui-extensions/coreui.extensions.module';

import { BlogThemeModule } from '@libraries/themes/blog-theme/blog.theme.module';
import { FlagIconsModule } from '@libraries/flag-icons/flagicons.module';


///////////////////////////////////////////////////////////////////////
// SERVICES
///////////////////////////////////////////////////////////////////////
import { ApplicationService } from './services/application.service';
import { FileUploadService } from './flows/file-upload/file.upload.service';

///////////////////////////////////////////////////////////////////////
// Container
///////////////////////////////////////////////////////////////////////
import { Main } from './main/main';
import { MainRoutes } from './main/main.routes';
import { Modals } from './main/modals';
import { RightSliders } from './main/sliders';

import { TestPage } from './content/test/test.page';

///////////////////////////////////////////////////////////////////////
// CONTENT
///////////////////////////////////////////////////////////////////////


// Style - Icons
import { IconsPage } from './content/style/icons/icons.page';

// Colors
import { ColorsCollectionPage } from './content/collections/colors/colors.collection.page';
import { ColorsAnimCollection } from './content/collections/colors/colors.collection';

// Gradients 
import { GradientsCollectionPage } from './content/collections/gradients/gradients.collection.page';
import { GradientAnimCollection } from './content/collections/gradients/gradient.collection';
import { AddGradientSlider } from './content/collections/gradients/add.gradient.slider';

//Image Collection
import { ImageCollectionPage } from './content/collections/images/image.collection.page';
import { ImageUploadPage } from './content/collections/images/image.upload.page';
import { FileUploadSlider } from './content/collections/images/file.upload.slider';
import { FileUploadContainer } from './flows/file-upload/file.upload';

import { ImagesAnimCollection } from './content/collections/images/images.collection';


//layouts
import { ListDetailExamplePage } from './content/layouts/list.detail.page';
import { ColorListDetailExamplePage } from './content/layouts/color.list.detail.page';

//Maps
import { AppleMapsPage } from './content/maps/apple.maps.page';

//Edit Settings, Profile Etc
import { EditProfilePage } from './content/profile/edit.profile.page';

//Sign In
import { SignInModalFlow } from './flows/authentication/signin.modal.flow';

//auth
import { SessionsListPage } from './content/sessions-management/sessions.list.page';
import { SessionsList } from './content/sessions-management/sessions.table';
import { SessionsListItemComponent } from './content/sessions-management/sessions.list item';
import { SessionsByCountryList } from './content/sessions-management/lists/sessions.country.list';
import { UserSessionsHistoryPage } from './content/sessions-management/user.sessions.history';

//User Management
import { UserManagementPage } from './content/user-management/user.management.page';
import { UserManagementAddUserPage } from './content/user-management/add.user.page';


@NgModule({
  declarations: [
    Main,
    MainRoutes,
    Modals,
    RightSliders,

    TestPage,
    IconsPage,
    ListDetailExamplePage,
    ColorListDetailExamplePage,
    ImageUploadPage,
    ImageCollectionPage,
    ImagesAnimCollection,
    FileUploadSlider,
    FileUploadContainer,
    ColorsCollectionPage,
    ColorsAnimCollection,
    GradientsCollectionPage,
    GradientAnimCollection,
    AddGradientSlider,

    //sign in
    SignInModalFlow,

    AppleMapsPage,
    EditProfilePage,

    //auth
    SessionsListPage,
    SessionsList,
    SessionsListItemComponent,
    SessionsByCountryList,
    UserSessionsHistoryPage,
    //user management
    UserManagementPage,
    UserManagementAddUserPage

  ],

  imports: [
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    CoreUIModule,
    LayoutTemplatesModule,
    ColorSwatchesModule,
    AnimatedTilesModule,
    CoreUIExtensionsModule,
    BlogThemeModule,
    FlagIconsModule
  ],


  providers: [
    ApplicationService,
    FileUploadService
  ],

  exports: [
    Main,
    MainRoutes,
    Modals,
    RightSliders,

    TestPage,
    IconsPage,
    ListDetailExamplePage,
    ColorListDetailExamplePage,
    ImageUploadPage,
    ImageCollectionPage,
    ImagesAnimCollection,
    FileUploadSlider,
    FileUploadContainer,
    ColorsCollectionPage,
    ColorsAnimCollection,
    GradientsCollectionPage,
    GradientAnimCollection,
    AddGradientSlider,

    //sign in
    SignInModalFlow,

    AppleMapsPage,
    EditProfilePage,

    //auth
    SessionsListPage,
    SessionsList,
    SessionsListItemComponent,
    SessionsByCountryList,
    UserSessionsHistoryPage,
    //user management
    UserManagementPage,
    UserManagementAddUserPage

  ]
})
export class UICoreModule { }
