import {
  Component,
  ViewEncapsulation,
  ViewChild,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  NgZone,
  Input,
} from "@angular/core";

import { EventsService } from "@coreui//services/event.service";
import { UIColorModel } from "@coreui/services/ui.color.model.service";
import { UILayout } from '@coreui//services/ui.layout.service';
import { ImageGalleryUploadService } from './imagegallery.upload.service';


@Component({
  selector: "imagegallery-upload-slider",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  template: `

    <slider-sheet [direction]="'right'" [size]="320">
      <div right>
      
        <div class="_file-upload-container">
          <div class="drop-target">

          <div class="_file-drop-target" surfaceContainer type="file"
              [style.border]=_border>

              <div class="info-block">
                <i class="mdi mdi-cloud-upload-outline" onSurface></i>
                <div class="heading" onSurface font-family>Upload files here</div>
              </div>
              <input type="file" class="_file-input" multiple (change)="this.imageGalleryUploadService.fileBrowseHandler($event)" /> 
            </div>				
          </div>

          <div class="file-list-container" surfaceColor>
            <ul class="list-tiles-container">
             <file-upload-progress-card  *ngFor="let dat of this.imageGalleryUploadService.filesToUpload"
                [heading]=dat.filename	
                [indeterminate]=false	
                [filesize]=dat.size
                [uploaded]=dat.progress
                [showleadingicon]=dat.isUploading
                >
              </file-upload-progress-card> 
            </ul>
          </div>
        </div>

      </div>
    </slider-sheet>		

      
  `,
})

export class ImageGalleryUploadSlider {
  private eventsService: EventsService;
  private uiLayout: UILayout
  private changeDetectorRef: ChangeDetectorRef;
  private uiColorModel: UIColorModel;
  imageGalleryUploadService: ImageGalleryUploadService


  _closeIcon: string = "plus";
  _border: string = "";
  _containerHeight: number = 100

  constructor(
    public ref: ChangeDetectorRef,
    private _uiLayout: UILayout,
    private _imageGalleryUploadService: ImageGalleryUploadService,
    _eventsService: EventsService,
    private _uiColorModel: UIColorModel
  ) {
    this.eventsService = _eventsService;
    this.uiColorModel = _uiColorModel;
    this.uiLayout = _uiLayout
    this.imageGalleryUploadService = _imageGalleryUploadService
    this.changeDetectorRef = ref;

    let self = this;
    this.eventsService.on("onRefresh", function (args: any) {
      self.borderColor();
      console.log("refreshed!!!!")
      self.changeDetectorRef.markForCheck()
    });
  }

  borderColor() {
    this._border = "1px solid " + this.uiColorModel.outline;
  }

  calculateContainerHeight() {
    this._containerHeight = window.innerHeight - 64
  }

  openSequence() {
    setTimeout(() => {
      this._closeIcon = "close";
    }, 300);
  }

  close() {
    this._closeIcon = "plus";
    setTimeout(() => {
      this.eventsService.broadcast("onHideRightSlider", {});
    }, 300);
  }

  ngOnInit() {
    this.calculateContainerHeight()
    this.borderColor();
    this.openSequence();
  }

  ngOnDestroy() {
    this.close();
  }

  ngAfterViewInit() {}
}
