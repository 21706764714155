import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  ChangeDetectorRef,
  Inject,
  NgZone,
  OnInit,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'
import { UIColorModel } from '@coreui/services/ui.color.model.service';


@Component({
  selector: 'outline-social-button',
  inputs: ['type', 'label', 'preloading'],
  outputs: ['clicked'],
  changeDetection: ChangeDetectionStrategy.Default,

  template: `
    <div
      class="std-social-button fullwidth {{ btnPressEffect }}"
      [style.border]=_border
      (click)="btnClicked()"
      [style.borderRadius.px]="btnRadius"
      font-family onSecondaryContainer>

      <span *ngIf="_preloadingState==false">
          <div class="icon">
          <i class="i mdi mdi-{{ btnIcon }}"></i>
          </div>
          <div class="label" font-family>
            {{ this.btnLabel }}
          </div>
       <!--   <div class="action">
            <i class="i mdi mdi-chevron-right"></i>
          </div> -->
        </span>

        <span *ngIf="_preloadingState==true">
          <div class="preloader-wrapper">
            <div class="preloader-spinner"
              [style.borderTop]=_borderTop
              [style.borderRight]=_borderTop
              [style.borderBottom]=_borderTop></div>
          </div>
        </span>

    </div>
  `,
})
export class OutlineSocialButtonComponent {
  @Output()
  clicked = new EventEmitter();

  @Input()
  set type(value: string) {
    this.setType(value);
  }
  set label(value: string) {
    this.setLabel(value);
  }
  set preloading(value: boolean) {
    this.setPreloading(value)
  }

  changeDetectorRef: ChangeDetectorRef;
	uiColorModel: UIColorModel
	private eventsService: EventsService

	constructor(public ref: ChangeDetectorRef,
    private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel

    let self = this
		this.eventsService.on('onRefresh', function (args: any) {
			self.setBorderColor()
		});

  }

  btnLabel: string = '';
  btnPressEffect: string = '';
  btnOpacity: number = 1;
  btnRadius: number = 50;
  _border: string = "1px solid rgba(0,0,0,1)"
  _borderTop: string = "3px solid #FFFFFF"

  _preloadingState: boolean = false
  btnIcon: string = '';

  setLabel(value: string) {
    this.btnLabel = value;
  }

  setBorderColor() {
    this._border = "1px solid " + this.uiColorModel.onSecondaryContainer 
  }

  btnClicked() {
    this.btnPressEffect = 'button-press-animation-2';
    this.clicked.emit(true);
    setTimeout(() => {
      this.btnPressEffect = '';
    }, 250);
  }

  setType(value: string) {
    if(value == "apple") {
      this.btnIcon = "apple"
    }
    if(value == "facebook") {
      this.btnIcon = "facebook"
    }
    if(value == "instagram") {
      this.btnIcon = "instagram"
    }
    if(value == "linkedin") {
      this.btnIcon = "linkedin"
    }
    if(value == "twitter") {
      this.btnIcon = "twitter"
    }
    if(value == "google") {
      this.btnIcon = "google"
    }
    if(value == "email") {
      this.btnIcon = "email-outline"
    }
  }

  setPreloading(value: boolean) {
    this._preloadingState = value
  }


  ngOnInit() {
    this.setBorderColor()
  }

  ngOnChanges() {}

  ngOnDestroy() {}
}
