import { Injectable } from '@angular/core';

import { ColorScheme } from "../style/color/schemes/color.scheme.class"
import { M3ColorSchemeUtility } from '../style/color/schemes/color.schene.utility.class';
import { ColorHCTUtils } from '../style/color/utils/color.utils.hct.class';


@Injectable()
export class UIColorModel {



  private _isDark: boolean = false;
  private _primaryKeyColor: string = ""
  private _secondaryKeyColor: string = ""
  private _tertiaryKeyColor: string = ""
  private _quaternaryKeyColor: string = ""
  private _neutralKeyColor: string = ""
  private _errorKeyColor: string = ""
  private _neutralVariantKeyColor: string = ""
  private _primary: string = ""
  private _onPrimary: string = ""
  private _primaryContainer: string = ""
  private _onPrimaryContainer: string = ""
  private _inversePrimary: string = ""
  private _secondary: string = ""
  private _onSecondary: string = ""
  private _secondaryContainer: string = ""
  private _onSecondaryContainer: string = ""
  private _tertiary: string = ""
  private _onTertiary: string = ""
  private _tertiaryContainer: string = ""
  private _onTertiaryContainer: string = ""
  private _surface: string = ""
  private _surfaceBright: string = ""
  private _surfaceDim: string = ""
  private _onSurface: string = ""
  private _surfaceLowest: string = ""
  private _surfaceLow: string = ""
  private _surfaceContainer: string = ""
  private _surfaceHigh: string = ""
  private _surfaceHighest: string = ""
  private _surfaceVariant: string = ""
  private _onSurfaceVariant: string = ""
  private _inverseSurface: string = ""
  private _inverseOnSurface: string = "" 
  private _background: string = ""
  private _onBackground: string = ""
  private _error: string = ""
  private _onError: string = ""
  private _errorContainer: string = ""
  private _onErrorContainer: string = ""
  private _outline: string = ""
  private _outlineVariant: string = ""
  private _shadow: string = ""
  private _surfaceTint: string = ""
  private _scrim: string = ""



    constructor() {
        this.primaryKeyColor = "386a1f"
        this.secondaryKeyColor = "934df0"
        this.tertiaryKeyColor = "19686a"
        this.errorKeyColor = "b3261e"
        this.neutralKeyColor = "5d5f5a"
        this.neutralVariantKeyColor = "5b6056"
        this.lightScheme()
    }

    public defaultLightScheme() {
      let m3Util = new M3ColorSchemeUtility()
      let primary = "386a1f"
      let secondary = "55624c"
      let tertiary = "19686a"
      let error = "b3261e"
      let neutral = "5d5f5a"
      let neutralVariant = "5b6056"
      let scheme = m3Util.generateDarkScheme("dark", primary, secondary, tertiary, neutral, neutralVariant, error)
      this.mapColorScheme(scheme)
      console.log(scheme)
    }

    public darkScheme() {
      this.isDark = true
      let m3Util = new M3ColorSchemeUtility()
      let scheme = m3Util.generateDarkScheme("dark", this.primaryKeyColor, this.secondaryKeyColor, this.tertiaryKeyColor, this.neutralKeyColor, this.neutralVariantKeyColor, this.errorKeyColor)
      this.mapColorScheme(scheme)
    }

    public lightScheme() {
      this.isDark = false
      let m3Util = new M3ColorSchemeUtility()
      let scheme = m3Util.generateLightScheme("light", this.primaryKeyColor, this.secondaryKeyColor, this.tertiaryKeyColor, this.neutralKeyColor, this.neutralVariantKeyColor, this.errorKeyColor)
      this.mapColorScheme(scheme)
    }

    public generateM3ColorScheme(primary: string, secondary: string, tertiary: string, neutral: string, neutralVariant: string, error: string) {
        this._primaryKeyColor = primary
        this._secondaryKeyColor = secondary
        this._tertiaryKeyColor = tertiary
        this._errorKeyColor = error
        this._neutralKeyColor = neutral
        this._neutralVariantKeyColor = neutralVariant
        let m3Util = new M3ColorSchemeUtility()
        let scheme = m3Util.generateLightScheme("light", this._primaryKeyColor, this._secondaryKeyColor, this._tertiaryKeyColor, this._neutralKeyColor, this._neutralVariantKeyColor, this._errorKeyColor)
        this.mapColorScheme(scheme)
    }
    public generateM3DarkScheme(primary: string, secondary: string, tertiary: string, neutral: string, neutralVariant: string, error: string) {
        let m3Util = new M3ColorSchemeUtility()
        let scheme = m3Util.generateLightScheme("dark", this.primaryKeyColor, this.secondaryKeyColor, this.tertiaryKeyColor, this.neutralKeyColor, this.neutralVariantKeyColor, this.errorKeyColor)
        this.mapColorScheme(scheme)
    }

    public mapColorScheme(scheme: ColorScheme) {
      this.primaryKeyColor = scheme.primaryKeyColor
      this.secondaryKeyColor = scheme.secondaryKeyColor
      this.tertiaryKeyColor = scheme.tertiaryKeyColor
      this.quaternaryKeyColor = scheme.quaternaryKeyColor
      this.neutralKeyColor = scheme.neutralKeyColor
      this.errorKeyColor = scheme.errorKeyColor
      this.neutralVariantKeyColor = scheme.neutralVariantKeyColor
      this.primary = scheme.primary
      this.onPrimary = scheme.onPrimary
      this.primaryContainer = scheme.primaryContainer
      this.onPrimaryContainer = scheme.onPrimaryContainer
      this.inversePrimary = scheme.inversePrimary
      this.secondary = scheme.secondary
      this.onSecondary = scheme.onSecondary
      this.secondaryContainer = scheme.secondaryContainer
      this.onSecondaryContainer = scheme.onSecondaryContainer
      this.tertiary = scheme.tertiary
      this.onTertiary = scheme.onTertiary
      this.tertiaryContainer = scheme.tertiaryContainer
      this.onTertiaryContainer = scheme.onTertiaryContainer
      this.surface = scheme.surface
      this.surfaceBright = scheme.surfaceBright
      this.surfaceDim = scheme.surfaceDim
      this.onSurface = scheme.onSurface
      this.surfaceLowest = scheme.surfaceLowest
      this.surfaceLow = scheme.surfaceLow
      this.surfaceContainer = scheme.surfaceContainer
      this.surfaceHigh = scheme.surfaceHigh
      this.surfaceHighest = scheme.surfaceHighest
      this.surfaceVariant = scheme.surfaceVariant
      this.onSurfaceVariant = scheme.onSurfaceVariant
      this.inverseSurface = scheme.inverseSurface
      this.inverseOnSurface = scheme.inverseOnSurface
      this.background = scheme.background
      this.onBackground = scheme.onBackground
      this.error = scheme.error
      this.onError = scheme.onError
      this.errorContainer = scheme.errorContainer
      this.onErrorContainer = scheme.onErrorContainer
      this.outline = scheme.outline
      this.outlineVariant = scheme.outlineVariant
      this.shadow = scheme.shadow
      this.surfaceTint = scheme.surfaceTint
      this.scrim = scheme.scrim
    }


  public utils(): ColorHCTUtils {
    let util = new ColorHCTUtils()
    return util
  }

  public get isDark(): boolean {
    return this._isDark;
  }
  public set isDark(value: boolean) {
    this._isDark = value;
  }
  public get primaryKeyColor(): string {
      return this._primaryKeyColor
  }
  public set primaryKeyColor(value: string) {
      this._primaryKeyColor = value
  }
  public get secondaryKeyColor(): string {
      return this._secondaryKeyColor
  }
  public set secondaryKeyColor(value: string) {
      this._secondaryKeyColor = value
  }
  public get tertiaryKeyColor(): string {
      return this._tertiaryKeyColor
  }
  public set tertiaryKeyColor(value: string) {
      this._tertiaryKeyColor = value
  }
  public get quaternaryKeyColor(): string {
      return this._quaternaryKeyColor
  }
  public set quaternaryKeyColor(value: string) {
      this._quaternaryKeyColor = value
  }
  public get neutralKeyColor(): string {
      return this._neutralKeyColor
  }
  public set neutralKeyColor(value: string) {
      this._neutralKeyColor = value
  }
  public get neutralVariantKeyColor(): string {
      return this._neutralVariantKeyColor
  }
  public set neutralVariantKeyColor(value: string) {
      this._neutralVariantKeyColor = value
  }
  public get errorKeyColor(): string {
      return this._errorKeyColor
  }
  public set errorKeyColor(value: string) {
      this._errorKeyColor = value
  }
  public get primary(): string {
      return this._primary
  }
  public set primary(value: string) {
      this._primary = value
  }
  public get onPrimary(): string {
      return this._onPrimary
  }
  public set onPrimary(value: string) {
      this._onPrimary = value
  }
  public get primaryContainer(): string {
      return this._primaryContainer
  }
  public set primaryContainer(value: string) {
      this._primaryContainer = value
  }
  public get onPrimaryContainer(): string {
      return this._onPrimaryContainer
  }
  public set onPrimaryContainer(value: string) {
      this._onPrimaryContainer = value
  }
  public get inversePrimary(): string {
      return this._inversePrimary
  }
  public set inversePrimary(value: string) {
      this._inversePrimary = value
  }
  public get secondary(): string {
      return this._secondary
  }
  public set secondary(value: string) {
      this._secondary = value
  }
  public get onSecondary(): string {
      return this._onSecondary
  }
  public set onSecondary(value: string) {
      this._onSecondary = value
  }
  public get secondaryContainer(): string {
      return this._secondaryContainer
  }
  public set secondaryContainer(value: string) {
      this._secondaryContainer = value
  }
  public get onSecondaryContainer(): string {
      return this._onSecondaryContainer
  }
  public set onSecondaryContainer(value: string) {
      this._onSecondaryContainer = value
  }
  public get tertiary(): string {
      return this._tertiary
  }
  public set tertiary(value: string) {
      this._tertiary = value
  }
  public get onTertiary(): string {
      return this._onTertiary
  }
  public set onTertiary(value: string) {
      this._onTertiary = value
  }
  public get tertiaryContainer(): string {
      return this._tertiaryContainer
  }
  public set tertiaryContainer(value: string) {
      this._tertiaryContainer = value
  }
  public get onTertiaryContainer(): string {
      return this._onTertiaryContainer
  }
  public set onTertiaryContainer(value: string) {
      this._onTertiaryContainer = value
  }
  public get surface(): string {
      return this._surface
  }
  public set surface(value: string) {
      this._surface = value
  }
  public get surfaceBright(): string {
      return this._surfaceBright
  }
  public set surfaceBright(value: string) {
      this._surfaceBright = value
  }
  public get surfaceDim(): string {
      return this._surfaceDim
  }
  public set surfaceDim(value: string) {
      this._surfaceDim = value
  }
  public get surfaceLowest(): string {
      return this._surfaceLowest
  }
  public set surfaceLowest(value: string) {
      this._surfaceLowest = value
  }
  public get surfaceLow(): string {
      return this._surfaceLow
  }
  public set surfaceLow(value: string) {
      this._surfaceLow = value
  }
  public get surfaceContainer(): string {
      return this._surfaceContainer
  }
  public set surfaceContainer(value: string) {
      this._surfaceContainer = value
  }
  public get surfaceHigh(): string {
      return this._surfaceHigh
  }
  public set surfaceHigh(value: string) {
      this._surfaceHigh = value
  }
  public get surfaceHighest(): string {
      return this._surfaceHighest
  }
  public set surfaceHighest(value: string) {
      this._surfaceHighest = value
  }
  public get onSurface(): string {
      return this._onSurface
  }
  public set onSurface(value: string) {
      this._onSurface = value
  }   
  public get surfaceVariant(): string {
      return this._surfaceVariant
  }
  public set surfaceVariant(value: string) {
      this._surfaceVariant = value
  }
  public get onSurfaceVariant(): string {
      return this._onSurfaceVariant
  }
  public set onSurfaceVariant(value: string) {
      this._onSurfaceVariant = value
  }

  public get inverseSurface(): string {
      return this._inverseSurface
  }
  public set inverseSurface(value: string) {
      this._inverseSurface = value
  }
  public get inverseOnSurface(): string {
      return this._inverseOnSurface
  }
  public set inverseOnSurface(value: string) {
      this._inverseOnSurface = value
  }
  public get background(): string {
      return this._background
  }
  public set background(value: string) {
      this._background = value
  }
  public get onBackground(): string {
      return this._onBackground
  }
  public set onBackground(value: string) {
      this._onBackground = value
  }
  public get error(): string {
      return this._error
  }
  public set error(value: string) {
      this._error = value
  }
  public get onError(): string {
      return this._onError
  }
  public set onError(value: string) {
      this._onError = value
  }
  public get errorContainer(): string {
      return this._errorContainer
  }
  public set errorContainer(value: string) {
      this._errorContainer = value
  }
  public get onErrorContainer(): string {
      return this._onErrorContainer
  }
  public set onErrorContainer(value: string) {
      this._onErrorContainer = value
  }
  public get outline(): string {
      return this._outline
  }
  public set outline(value: string) {
      this._outline = value
  }
  public get outlineVariant(): string {
      return this._outlineVariant
  }
  public set outlineVariant(value: string) {
      this._outlineVariant = value
  }
  public get shadow(): string {
      return this._shadow
  }
  public set shadow(value: string) {
      this._shadow = value
  }
  public get surfaceTint(): string {
      return this._surfaceTint
  }
  public set surfaceTint(value: string) {
      this._surfaceTint = value
  }
  public get scrim(): string {
      return this._scrim
  }
  public set scrim(value: string) {
      this._scrim = value
  }


 }
