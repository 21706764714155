import {
	Component,
	ViewEncapsulation,
	ChangeDetectorRef,
	ChangeDetectionStrategy,
	Input,
	Output,
	EventEmitter
} from '@angular/core';

import { EventsService } from '@coreui//services/event.service'
import { AbstractCollectionContainerAnimated } from '@libraries/animated-tiles/components/collections/abstract.collection.container.animated';


@Component({
	selector: 		'images-collection-anim',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.Default,
	inputs: ['width', 'height'],
	outputs: ['selected'],
	template:	`  
		<ul class="grid-lcollection-ist" [style.opacity]=this.entryOpacity
			[style.paddingLeft.px]=0>

			<abstract-collection-tile [id]=dat.objectid [width]=this._width [height]=this._height [radius]=this._radius [marginright]=this._marginRight [marginbottom]=16
				[cssclass]=dat.anim [selectmode]=dat.select_mode [selected]=dat.selected_flag (clicked)=onClick($event)
				*ngFor="let dat of this.items">

				<image-collection-card [imgsrc]=dat.url></image-collection-card>
			</abstract-collection-tile>

		</ul>

	`,

})


export class ImagesAnimCollection extends AbstractCollectionContainerAnimated {
	@Output()
	selected = new EventEmitter();
  
	_isSelectMode: boolean = false
	_isSelected: boolean = false

	constructor(public ref: ChangeDetectorRef, _eventsService: EventsService) {
		super(ref, _eventsService)
	}


	onClick($event: any) {
		if(this.getMode() == "select") {
			this.selectItem($event)
			this.selected.emit(this.getSelectedItems())
		}
	}

	override setData(value: any): void {
		let images: any[] = this.updateId(value)
		this.initData(images)
		this.changeDetectorRef.detectChanges()
		this.shakeAll()
	}

	override ngOnInit() {
		this.shakeAll()
	}

	override ngOnDestroy() {
	}

	override ngAfterViewInit() {

	}


	updateId(images: any): any {
		let result: any[] = []
		let i=0
		for(i=0;i<images.length;i++) {
			images[i]["id"] = images[i]["objectid"]
		}
		result = images
		return result
	}

}
