import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'
import { UIColorModel } from '@coreui/services/ui.color.model.service';
import { ApplicationService } from '../../services/application.service';
import { TimeZonesService } from '@libraries/timezones/timezones.service';


@Component({
	selector: 		'dining01-screen',
	encapsulation: ViewEncapsulation.None,

	template:	`

	<content-container-scrollable>
		<optimised-content-container>

		<search-bar-container (value)=searchValue($event)>
			<ul class="list-tiles-container">
				<tile-single-text-only [label]=dat.id (clicked)="timezoneClicked(dat.id, dat.offset, dat.dst)"
				*ngFor="let dat of this.timezones"></tile-single-text-only>
			</ul>
		</search-bar-container>

<avatar-stack-5 [dat]=_people></avatar-stack-5>
<sbutton [label]="'Pick'" [tonaltype]="'primary-container'" (click)=pick()></sbutton>
		<!--	
		<div class="row">
				<div class="col s10 offset-s1">

<card>
	<div actions>
  		<card-action [actionlabel]="'Buy Ticket'"></card-action>
	</div>
</card>

				</div>
			</div> 
-->

		</optimised-content-container>
	</content-container-scrollable>
		
	`,

})


export class Dining01Screen {

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	uiColorModel: UIColorModel
	applicationService: ApplicationService
	private timeZonesService: TimeZonesService

	_people: any = []

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel, private _applicationService: ApplicationService,
		private _timeZonesService: TimeZonesService) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
		this.applicationService = _applicationService
		this.timeZonesService = _timeZonesService

	}

	_avatar: string = "./assets/avatars/female-avatar-1.jpg"
	timezones: any = []
	searchValue($event: any) {
		this.timezones = this.timeZonesService.search($event)
	}

	timezoneClicked(id: string, offset: number, dst: number) {
		alert(id + "  " + offset + "  " + dst)
	}

	ngOnInit() {
		this._people = this.applicationService.peopleData()
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
	}


	pick() {
		this.eventsService.broadcast("onShowModalOverlay", {id: "people"})
	}
}
