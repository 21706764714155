import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter
} from '@angular/core';



@Component({
	selector: 		'gradient-swatch',
	inputs: ['id', 'label', 'gradient', 'gradientdata', 'radius'],
	outputs: ['clicked'],


	template: 	`
		<div class="_gradient-swatch-card" elevated [elevation]="this.elevationLevel" [style.borderRadius.px]=_radius
				(mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" surfaceColor>
			<div class="swatch-color"	[style.background]="gradientValue"
				[style.borderTopLeftRadius.px]=_radius [style.borderTopRightRadius.px]=_radius
				(click)="gradientClicked(this.gradientData)">
				<div class="layer opacity-transition-350 background-color-transition-350" [style.backgroundColor]="firstHex" [style.opacity]="layer1Opacity"></div>
				<div class="layer opacity-transition-350 background-color-transition-350" [style.backgroundColor]="lastHex" [style.opacity]="layer2Opacity"></div>
			</div>
			<div class="swatch-content">
				<div class="swatch-title" subtitle1 onSurface>{{this.labelTxt}}</div>
				<ul class="swatch-color-bar">
					<li *ngFor="let dat of this._colors" class="color-circle" [style.backgroundColor]="'#' + dat" (click)="colorClicked(dat)"></li>
				</ul>
			</div>
		</div>
								`,

})



export class GradientSwatchComponent {
	@Output()
	clicked = new EventEmitter();

	@Input()
	set id(value: string) { this.setId(value); }
	set label(value: string) { this.setLabel(value); }
	set gradient(value: string) { this.setGradient(value); }
	set gradientdata(value: any) { this.setGradientData(value); }
	set radius(value: number) { this.setRadius(value); }


	labelTxt: String = "Black";
	gradientId: string = "";
	gradientName: string = "Black";
	gradientValue: String = "#000000";
	gradientData: any = [];

	firstHex: string = "";
	layer1Opacity: number = 1;
	lastHex: string = "";
	layer2Opacity: number = 1;
	elevationLevel: number = 1;
	_radius:	number = 2

	_colors: any = []

	constructor() {
	}


	onMouseEnter() {
		this.elevationLevel = 2;
	}
	onMouseLeave() {
		this.elevationLevel = 1;
	}

	colorClicked($event: any) {
		this.showLayer1();
		this.labelTxt = '#' + $event;
		this.firstHex = '#' + $event;
		setTimeout(() => {
			 this.showGradient();
			 this.labelTxt = this.gradientName;
		}, 3500);
	}


	gradientClicked($event: any) {
		this.clicked.emit(this.gradientId);
	}


	setId(value: string) {
		this.gradientId = value;
	}

	setRadius(value: number) {
		this._radius = value;
	}

	setLabel(value: string) {
		this.labelTxt = value;
		this.gradientName = value;
	}

	setGradient(value: string) {
		this.gradientValue = value;
		this._colors = this.parseGradientString(value)
	}

	setGradientData(value: any) {
		this.gradientData = value;
		this.firstHex = "#" + this.gradientData[0].hex;
		this.lastHex = "#" + this.gradientData[this.gradientData.length-1].hex;
	}


	showLayer1() {
		this.layer1Opacity = 1;
		this.layer2Opacity = 0;
	}
	showLayer2() {
		this.layer1Opacity = 0;
		this.layer2Opacity = 1;
	}
	showGradient() {
		this.layer1Opacity = 0;
		this.layer2Opacity = 0;
	}

	ngOnInit() {
	/*	this.showLayer1();
		setTimeout(() => {
			 this.showGradient();
		}, 150);
		setTimeout(() => {
			 this.showLayer2();
		}, 300);
		setTimeout(() => {
			 this.showGradient();
		}, 500); */
		this.showGradient();

	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

	private parseGradientString(gString: string): any {
		let colors: any = [];
	
		let s = gString.split(",");
		let angle = s[0];
		let idx1 = angle.indexOf("(");
		angle = angle.substr(idx1 + 1, angle.length);
		idx1 = angle.indexOf("deg");
		angle = angle.substr(0, idx1);
	
		let i = 1;
		for(i=1;i<s.length;i++) {
		  let colstr = s[i].split(" ");
		  let hex = colstr[1];
		  hex = hex.substr(1, hex.length);
		  let perc = colstr[2];
		  if(perc.endsWith(")")) {
			perc = perc.substr(0, perc.length-2);
		  } else {
			perc = perc.substr(0, perc.length-1)
		  }
		  colors.push(hex);	
		}
	
	
		return colors;
	  }
	
}
