import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	ViewChild,
	EventEmitter,
	ElementRef,
	ChangeDetectionStrategy
} from '@angular/core';

import { FormControl, Validators,  ReactiveFormsModule } from '@angular/forms';


@Component({
	selector: 		'search-bar',
	inputs: ['placeholder', 'leadingicon', 'trailingicon'],
	outputs: ['value', 'clicked'],
	changeDetection: ChangeDetectionStrategy.Default,


	template: 	`
				<div class="_search-bar" surfaceContainerHigh>
					<div class="leading-icon" (click)=leadingIconClicked()>
						<i class="mdi mdi-{{_leadingIcon}}" onSurface></i>
					</div>
					<div class="input-field-wrapper">
						<input #searchbar class="input-field" placeholder={{_placeholder}} 
						(keyup)="onKey(searchbar.value)" onSurface font-family/>
					</div>
					<div class="trailing-icon-wrapper" (click)=trailingIconClicked()>
						<div class="item">
							<i class="mdi mdi-{{_trailingIcon}}" onSurface></i>
						</div>
					</div>	
									
				</div>
					
				
								`,

})



export class SearchBarComponent {
	@ViewChild('searchbar')
	searchbar!: ElementRef;

	@Output()
	value = new EventEmitter();
	clicked = new EventEmitter();

	@Input()
	set placeholder(value: string) { this.setPlaceholder(value); }
	set leadingicon(value: string) { this.setLeadingIcon(value); }
	set trailingicon(value: string) { this.setTrailingIcon(value); }


	changeDetectorRef: ChangeDetectorRef;
	_placeholder: string = ""
	_leadingIcon: string = "magnify"
	_trailingIcon: string = ""

	update_interval: any;

	constructor(public ref: ChangeDetectorRef) {
		this.changeDetectorRef = ref
	}


	onKey(e: any) {
		this.value.emit(e);
	}


	setPlaceholder(val: string) {
		this._placeholder = val;
	}


	setLeadingIcon(value: string) {
		this._trailingIcon = value 
	}


	setTrailingIcon(value: string) {
		this._trailingIcon = value
	}


	ngOnInit() {
		this.update_interval = setInterval(() => {
		}, 1000)
	}


	ngOnDestroy() {
		if (this.update_interval) {
			clearInterval(this.update_interval);
		}

	}

	leadingIconClicked() {
		this.clicked.emit("leading-icon")
	}

	trailingIconClicked() {
		if(this._trailingIcon == "close") {
			this.searchbar.nativeElement.value = ""
			//this.value.emit("return-an-empty-search-value");
		}
		this.clicked.emit("trailing-icon")
	}

}
