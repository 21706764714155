import {
  Component,
  ViewEncapsulation,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Input,
} from "@angular/core";

import { EventsService } from "../../../services/event.service";
import { UIColorModel } from "../../../services/ui.color.model.service";
import { UILayout } from "../../../services/ui.layout.service";

@Component({
  selector: "mobile-screen-card",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  inputs: ["width"],
  template: `
      <div class="_card"
        [style.width.px]=_width
        [style.marginLeft.px]=_marginLeft>
        <ng-content></ng-content>
      </div>
  `,
})
export class MobileScreenCardContainer {
  @Input()
  set width(value: number) {
    this.setWidth(value);
  }

  private eventsService: EventsService;
  private changeDetectorRef: ChangeDetectorRef;
  uiColorModel: UIColorModel;
  uiLayout: UILayout;

  _width: number = 100;
  _height: number = 100;
  _marginLeft: number = 8;

  constructor(
    public ref: ChangeDetectorRef,
    _eventsService: EventsService,
    private _uiLayout: UILayout,
    private _uiColorModel: UIColorModel
  ) {
    this.eventsService = _eventsService;
    this.uiColorModel = _uiColorModel;
    this.uiLayout = _uiLayout;
    this.changeDetectorRef = ref;

    let self = this;
    this.eventsService.on("onResize", function (args: any) {
      self.detectSize()
    });
  }

  setWidth(value: number) {
    this._width = value;
  }

  detectSize() {
    let screenWidth = window.innerWidth
    this._marginLeft = (screenWidth - this._width) / 2
  }

  ngOnInit() {
    this.detectSize()
  }

  ngOnDestroy() {}

  ngAfterViewInit() {}
}
