import {
	Component,
	ViewEncapsulation,
	HostListener,
	ChangeDetectorRef
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'


@Component({
	selector: 		'heading4',
	encapsulation: ViewEncapsulation.None,
	template:	`
		<div class="h4" onsurface heading-display-font-family>
			<ng-content></ng-content>
		</div>
	`,

})


export class TypographyHeading4Component {

	private changeDetectorRef: ChangeDetectorRef
    private eventsService: EventsService


	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
	}

	ngOnInit() {
	}

	ngAfterViewInit() {
	}

	ngOnDestroy() {
	}


}
