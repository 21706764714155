import {
	Component,
	ChangeDetectorRef,
	Input
} from '@angular/core';

import { UIColorModel } from '@coreui/services/ui.color.model.service';
import { EventsService } from '@coreui/services/event.service'


@Component({
	selector: 		'calendar-month',
	inputs: ['today', 'month', 'year'],

	template: 	`
		<div class="_coreui-calendar" surfaceContainerHigh onSurface font-family
			elevated [elevation]=1>
			<div class="controls">
				<div class="prev" (click)=prevClicked()> 
					<div class="_chevron left"></div>	
				</div>
				<div class="year-month">{{_monthName}} {{_year}}</div>
				<div class="next" (click)=nextClicked()> 
					<div class="_chevron right"></div>	
				</div>
			</div>

			<div class="calendar-body">
				<div class="item" *ngFor="let dat of this.days">{{dat}}</div>
				<div class="item" *ngFor="let dat of this.monthdays">
					<span class="day"
						[style.opacity]=dat.opacity
						[style.color]=dat.color
						[style.backgroundColor]=dat.backgroundColor
						[style.fontWeight]=dat.fontWeight
						(click)=dayClicked(dat.ts)
						>{{dat.day}}</span>
				</div>
				
			</div>
			
		</div>

	`,

})



export class CalendarUIMonthComponent {

	@Input()
	set icon(value: string) { this.setIcon(value); }


 
	days: any = [
		"Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"
	]

	monthdays: any = []

	changeDetectorRef: ChangeDetectorRef;
	uiColorModel: UIColorModel
	private eventsService: EventsService

	_monthName: string = ""
	_year: number = 0
	_activeMonthRange: Date = new Date()

	_selectedDates: any = []
	_startTS: number = 0
	_endTS: number = 0
	_startSelected: boolean = false
	_endSelected: boolean = true

	_selectedColor: string = ""
	_selectedTextColor: string = "#FFFFFF"
	_textColor: string = ""
	_normalColor: string = "#FFFFFF"

	constructor(public ref: ChangeDetectorRef,
		private _eventsService: EventsService,
			private _uiColorModel: UIColorModel) {
			this.changeDetectorRef = ref
			this.eventsService = _eventsService
			this.uiColorModel = _uiColorModel
			this.uiColorModel = _uiColorModel
		this._selectedColor = this.uiColorModel.secondaryContainer
		this._selectedTextColor = this.uiColorModel.onSecondaryContainer
		this._normalColor = "rgba(0,0,0,0)"
		this._textColor = this.uiColorModel.onSurface
		let self = this
		this.eventsService.on('onRefresh', function (args: any) {
			self.plotDays(self._monthName, self._year.toString())
			self.renderSelection()
		});

	}

	setIcon(value: string) {
	}


	ngOnInit() {
		this._activeMonthRange = new Date(Date.now())
		this._monthName = this.getMonthName(this._activeMonthRange.getMonth()) 
		this._year = this._activeMonthRange.getFullYear()
		this.plotDays(this._monthName, this._year.toString())
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}


	prevClicked() {
		let newTS = this._activeMonthRange.getTime() - 2629800000
		this._activeMonthRange = new Date(newTS)
		this._monthName = this.getMonthName(this._activeMonthRange.getMonth()) 
		this._year = this._activeMonthRange.getFullYear()
		this.monthdays = []
		this.plotDays(this._monthName, this._year.toString())
		this.renderSelection()
	}

	nextClicked() {
		let newTS = this._activeMonthRange.getTime() + 2629800000
		this._activeMonthRange = new Date(newTS)
		this._monthName = this.getMonthName(this._activeMonthRange.getMonth()) 
		this._year = this._activeMonthRange.getFullYear()
		this.monthdays = []
		this.plotDays(this._monthName, this._year.toString())
		this.renderSelection()
	}


	dayClicked(ts: number) {

		if(ts == this._startTS) {
			this._startSelected = true
			this._endSelected = false
		} 
		if(ts == this._endTS) {
			this._startSelected = false
			this._endSelected = true
		} 

		//
		if(this._endTS == 0 && this._startTS > 0) {
			this._endTS = ts
		} 

		// is empty - first click
		if(this._startTS == 0) {
			this._startTS = ts
		} else {
			if(ts < this._startTS) {
				this._startTS = ts
			}
		}

		if(ts > this._endTS) {
			this._endTS = ts
		} 

		//click between
		if(ts < this._endTS && ts > this._startTS) {
			if(this._startSelected == true) {
				this._startTS = ts
			} 

			if(this._endSelected == true) {
				this._endTS = ts
			}

		} 

		this.renderSelection()
	}



	renderStartDate() {
		let i=0;
		for(i=0;i<this.monthdays.length;i++) {
			if(this.monthdays[i].ts == this._startTS) {
				this.monthdays[i]["backgroundColor"] = this.uiColorModel.primary
				this.monthdays[i]["color"] = "#FFFFFF"
			}
		}
	}

	renderEndDate() {
		let i=0;
		for(i=0;i<this.monthdays.length;i++) {
			if(this.monthdays[i].ts == this._endTS) {
				this.monthdays[i]["backgroundColor"] = this.uiColorModel.primary
				this.monthdays[i]["color"] = "#FFFFFF"
			}
		}
	}


	renderSelection() {
		let i=0;
		for(i=0;i<this.monthdays.length;i++) {
			if(this.monthdays[i].ts == this._startTS) {
				this.monthdays[i]["isSelected"] = true
				this.monthdays[i]["backgroundColor"] = this.uiColorModel.primary
				this.monthdays[i]["color"] = "#FFFFFF"
			}
			if(this.monthdays[i].ts == this._endTS) {
				this.monthdays[i]["isSelected"] = true
				this.monthdays[i]["backgroundColor"] = this.uiColorModel.primary
				this.monthdays[i]["color"] = "#FFFFFF"
			}

			//between
			if(this.monthdays[i].ts < this._endTS && this.monthdays[i].ts > this._startTS) {
				this.monthdays[i]["isSelected"] = true
				this.monthdays[i]["backgroundColor"] = this._selectedColor
				this.monthdays[i]["color"] = this._selectedTextColor
			}


			if(this.monthdays[i].ts < this._startTS || this.monthdays[i].ts > this._endTS) {
				this.monthdays[i]["isSelected"] = false
				this.monthdays[i]["backgroundColor"] = this._normalColor
				this.monthdays[i]["color"] = this._textColor
			}

			//change start

		}
	}


	startDateSelected(ts: number): boolean {
		let isSelected: boolean = false
		if(ts == this._startTS) {
			isSelected = true
		}
		return isSelected
	}

	endDateSelected(ts: number): boolean {
		let isSelected: boolean = false
		if(ts == this._endTS) {
			isSelected = true
		}
		return isSelected
	}




	setStartingDate() {
		let startTS = this._selectedDates[0]
		let i=0;
		for(i=0;i<this.monthdays.length;i++) {
			if(this.monthdays[i].ts == startTS) {
				this.monthdays[i]["backgroundColor"] = this.uiColorModel.primary
				this.monthdays[i]["color"] = "#FFFFFF"
			}
		}
	}
	setEndingDate() {
		let idx = this._selectedDates.length - 1
		let endDateTS = this._selectedDates[idx]
		let i=0;
		for(i=0;i<this.monthdays.length;i++) {
			if(this.monthdays[i].ts == endDateTS) {
				this.monthdays[i]["backgroundColor"] = this.uiColorModel.primary
				this.monthdays[i]["color"] = "#FFFFFF"
			}
		}
	}








	checkIsSelected(ts: number): boolean {
		let isselected: boolean = false
		if(this._selectedDates.includes(ts)) {
			isselected = true
		}
		return isselected
	}

	plotDays(month: string, year: string) {
		let monthRecord = new Date(1 + " " + month + " " + year)
		let numDays = this.numDaysInMonth(monthRecord.getMonth(), parseInt(year))

		let prevMonth = monthRecord.getMonth() - 1

		let offset: number = monthRecord.getDay() 
		let prevMonthStart = this.numDaysInMonth(prevMonth, parseInt(year)) - offset 
		let offsetCounter: number = 0
		let loop: number = numDays + offset + 1

		let i=1
		for(i=1;i<loop;i++) {
			if(offsetCounter < offset) {
				let rec = this.dateRec(prevMonthStart + i, this.getMonthName(prevMonth), year, false, true)
				this.monthdays.push(rec)
			} else {
				let rec = this.dateRec(i-offset, month, year, false, false)
				this.monthdays.push(rec)
			}
			offsetCounter++
		}
	}




	selectDate(ts: number) {
		let i=0;
		for(i=0;i<this.monthdays.length;i++) {
			if(this.monthdays[i].ts == ts) {
				this.monthdays[i]["isSelected"] = true
				this.monthdays[i]["backgroundColor"] = this._selectedColor
				this.monthdays[i]["color"] = this._selectedTextColor
			}
		}
	}

	fillBetween(start: number, end: number) {
		let i=0;
		for(i=0;i<this.monthdays.length;i++) {
			if(this.monthdays[i].ts >= start && this.monthdays[i].ts <= end) {
				this.monthdays[i]["isSelected"] = true
				this.monthdays[i]["backgroundColor"] = this._selectedColor
				this.monthdays[i]["color"] = this._selectedTextColor
				this._selectedDates.push(this.monthdays[i].ts)	
			}
		}
		this.setStartingDate()
		this.setEndingDate()
	}




	dateRec(day: number, month: string, year: string, isSelected: boolean, offset: boolean): any {
		let dateRec = new Date(day + " " + month + " " + year)
		let ts = (dateRec.getTime() / 1000).toFixed(0)

		let opacity = 1
		if(offset) {
			opacity = 0.4
		}

		let color = this.uiColorModel.onSurface
		let fontWeight = 500
		if(this.isDateToday(day, month, year)) {
			color = this.uiColorModel.primary
			fontWeight = 900
		}

		let backgroundColor = this._normalColor
		if(this._selectedDates.includes(ts)) {
			isSelected = true
			backgroundColor = this._selectedColor
			color = this._selectedTextColor
		}

		let rec = {
			ts:		ts,
			day:	day,
			dayNumber:	dateRec.getDay(),
			month: month,
			monthNumber: dateRec.getMonth(),
			year: year,
			isSelected: isSelected,
			isPrevious: offset,
			opacity:	opacity,
			color: color,
			backgroundColor: backgroundColor,
			fontWeight: fontWeight
		}
		return rec
	}


	private isDateToday(day: number, month: string, year: string): boolean {
		let dateRec = new Date(day + " " + month + " " + year)
		let today = new Date(Date.now())		
		var isToday = (today.toDateString() == dateRec.toDateString());
		return isToday
	}


	private numDaysInMonth(month: number, year: number): number {
		let isLeapYear: boolean = this.isLeapYear(year)
		let _month = month + 1
		let value:number = 31
		if([9, 4, 6, 11].includes(_month)) {
			value = 30
		}
		if(_month == 2) {
			if(isLeapYear) {
				value = 29
			} else {
				value = 28
			}
		}

		return value
	}

	private getMonthName(month: number): string {
		let _month = month + 1
		let value:string = "January"

		if(_month == 1) {
			value = "January"
		}
		if(_month == 2) {
			value = "Febrary"
		}
		if(_month == 3) {
			value = "March"
		}
		if(_month == 4) {
			value = "April"
		}
		if(_month == 5) {
			value = "May"
		}
		if(_month == 6) {
			value = "June"
		}
		if(_month == 7) {
			value = "July"
		}
		if(_month == 8) {
			value = "August"
		}
		if(_month == 9) {
			value = "September"
		}
		if(_month == 10) {
			value = "October"
		}
		if(_month == 11) {
			value = "November"
		}
		if(_month == 12) {
			value = "December"
		}
		return value
	}


	private isLeapYear(year: number): boolean {
		let value: boolean = false
		//three conditions to find out the leap year
		if ((0 == year % 4) && (0 != year % 100) || (0 == year % 400)) {
			value = true
		} 
		return value
	}

}
