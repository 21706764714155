import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  ChangeDetectorRef,
  Inject,
  NgZone,
  OnInit,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

import { EventsService } from '../../services/event.service'
import { UIColorModel } from '../../services/ui.color.model.service';


@Component({
  selector: 'badge-icon-button',
  inputs: ['icon', 'activeicon', 'color', 'activecolor', 'value', 'smallbadge'],
  outputs: ['clicked'],
  changeDetection: ChangeDetectionStrategy.Default,

  template: `
    <div
      class="_icon-button" 
      [style.color]=_currentColor 
      (click)=iconClicked()>
      <i
        class="i mdi mdi-{{ _currentIcon }}"
      ></i>
      <div *ngIf="_badgeValue > 0" class="badge {{_smallBadgeCSS}}">
        <badge [value]=_badgeValue [small]=_smallBadge></badge>
      </div>
    </div>
  `,
})
// surfaceContainerHighest
export class IconBadgeButtonComponent {
  @Output()
  clicked = new EventEmitter();

  @Input()
  set icon(value: string) {
    this.setIcon(value);
  }
  set activeicon(value: string) {
    this.setActiveIcon(value);
  }
  set color(value: string) {
    this.setColor(value);
  }
  set activecolor(value: string) {
    this.setActiveColor(value);
  }
  set value(value: number) {
    this.setValue(value);
  }
  set smallbadge(value: boolean) {
    this.isSmall(value);
  }


	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	uiColorModel: UIColorModel

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel

		let self = this
		this.eventsService.on('onRefresh', function (args: any) {
        self.defaultColor()
        if(self._isPressed) {
          self._currentColor = self._activeColor
        } else {
          self._currentColor = self._inactiveColor
        }
		});
	}

  _currentIcon: string = ""
  _inactiveIcon: string = ""
  _activeIcon: string = ""

  _currentColor: string = ""
  _inactiveColor: string = ""
  _activeColor: string = ""

  _isPressed: boolean = false
  _isDisabled: boolean = false

  _badgeValue: number = 0
  _smallBadge: boolean = false
  _smallBadgeCSS: string = ""


  setIcon(value: string) {
      this._inactiveIcon = value;
  }

  setActiveIcon(value: string) {
    this._activeIcon = value;
  }

  setColor(value: string) {
    if (value.startsWith('linear-gradient') || value.startsWith('rgb') || value.startsWith('#')) {
      this._inactiveColor = value;
    } else {
      this._inactiveColor = '#' + value;
    }
  }

  setActiveColor(value: string) {
    if (value.startsWith('linear-gradient') || value.startsWith('rgb') || value.startsWith('#')) {
      this._activeColor = value;
    } else {
      this._activeColor = '#' + value;
    }
  }

  setValue(value: number) {
    this._badgeValue = value;
  }

  isSmall(value: boolean) {
    this._smallBadge = value
    if(value) {
      this._smallBadgeCSS = "small"
    } else {
      this._smallBadgeCSS = ""
    }
  }
 

  defaultColor() {
    this._activeColor = this.uiColorModel.primary
    this._inactiveColor = this.uiColorModel.onSurface
  }

  pressed() {
    this._isPressed = true
    this._currentColor = this._activeColor
    this._currentIcon = this._activeIcon
  }

  enabled() {
    this._isPressed = false
    this._currentColor = this._inactiveColor
    this._currentIcon = this._inactiveIcon
  }

  iconClicked() {
    if(this._isPressed == true) {
      this.enabled()
      this.clicked.emit(false)
    } else {
      this.pressed()
      this.clicked.emit(true)
    }
  }

  ngOnInit() {
    this.defaultColor()
    this._currentIcon = this._inactiveIcon
    this._currentColor = this._inactiveColor
  }

  ngOnChanges() {}

  ngOnDestroy() {}
}
