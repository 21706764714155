import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter,
	ChangeDetectionStrategy
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'


@Component({
	selector: 		'image-collection-card',
	inputs: ['imgsrc'],
	outputs: ['clicked'],
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
	<div class="_image-card">
		<img [src]= _imgsrc/>
	</div>

								`,
})


export class ImageCollectionCardComponent {
	@Output()
	clicked = new EventEmitter();

	@Input()
	set imgsrc(value: string) { this.setImgSrc(value); }

	private changeDetectorRef: ChangeDetectorRef;
	private eventsService: EventsService
	_imgsrc: string = ""


	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService) {
		this.eventsService = _eventsService;
		this.changeDetectorRef = ref;

	}


	setImgSrc(value: string): void {
		this._imgsrc = value
	}

	

	ngOnInit() {
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}



}
