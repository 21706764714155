import { ColorUtils } from '@coreui/style/color/utils/color.utils.class';


import { ColorObject } from './color.class';


export class PaletteObject {

  private id:                 string
  private name:               string
  private description:        string = ""
  private colors:             any[] = []

  constructor(_id: string) {
    this.id = _id
    this.name = _id
  }

  public getName(): string { return this.name }
  public setName(value: string): void { this.name = value }
  public getDescription(): string { return this.description }
  public setDescription(value: string): void { this.description = value }
  public getColors(): any[] { return this.colors }

  public addColorObject(_color: ColorObject): void {
      let rec = {
        id:       _color.getHex(),
        hex:      _color.getHex(),
        name:     _color.getName()
      }
      this.colors.push(rec)
  }

  public addColor(hex: string, name: string): void {
    if(this.colorExists(hex) == false) {
      let rec = {
        id:       hex,
        hex:      hex,
        name:     name
      }
      this.colors.push(rec)
    }
  }

  public addColorR(hex: string, name: string): void {
    if(this.colorExists(hex) == false) {
      let rec = {
        id:       hex,
        hex:      name.toUpperCase(),
        name:     hex
      }
      console.log("palette.addColor(\"" + rec.hex + "\", " + "\"," + rec.name + "\")")
//      this.colors.push(rec)
    }
  }

  public colorExists(hex: string): boolean {
    let exists = false
    let i = 0
    for(i=0;i<this.colors.length;i++) {
      if(this.colors[i].hex == hex) {
        exists = true
      }
    }
    return exists
  }

}
