import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/////////////////////////////////////////////////////////
// Core
////////////////////////////////////////////////////////
import { CoreUIModule } from '@coreui/coreui.module';

import { AvatarStack5Component } from './components/avatar-stack/avatar.stack.component';
import { PeoplePickerBottomSliderComponent } from './components/people-list-slider/people.picker.slider';


@NgModule({
  declarations: [
    AvatarStack5Component,
    PeoplePickerBottomSliderComponent
  ],

  imports: [
    CommonModule,
    CoreUIModule
  ],

  providers: [
  ],

  exports: [
    AvatarStack5Component,
    PeoplePickerBottomSliderComponent
  ]
})
export class PeopleUIModule { }
