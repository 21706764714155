import {ColorCIERGBUtils} from "./color.utils.cie.class"

export class ColorUtils {
  /**********************************************************
  COLOR FUNCTIONS
  **********************************************************/
  public rgbToHsl(r: number, g: number, b: number): any {
    (r /= 255), (g /= 255), (b /= 255);

    var max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    var h = 0;
    var s,
      l = (max + min) / 2;

    if (max == min) {
      h = s = 0; // achromatic
    } else {
      var d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }

      h /= 6;
    }

    return [h, s, l];
  }

  public hslToRgb(h: number, s: number, l: number): any {
    var r, g, b;

    if (s == 0) {
      r = g = b = l; // achromatic
    } else {
      var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      var p = 2 * l - q;

      r = this.hue2rgb(p, q, h + 1 / 3);
      g = this.hue2rgb(p, q, h);
      b = this.hue2rgb(p, q, h - 1 / 3);
    }

    return [r * 255, g * 255, b * 255];
  }
  hue2rgb(p: number, q: number, t: number) {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  }

  public changeShade(r: number, g: number, b: number, value: number): string {
    //convert to hsl
    let hsl = this.rgbToHsl(r, g, b);
    let newLum = hsl[2] + value / 100;
    if (newLum >= 1) newLum = 0.99;

    if (newLum <= 0) newLum = 0.01;

    let newRGB = this.hslToRgb(hsl[0], hsl[1], newLum);
    let hex = this.rgb2hex(newRGB[0], newRGB[1], newRGB[2]);
    return hex;
  }

  public rgb2hex(red: number, green: number, blue: number): string {
    var rgb = blue | (green << 8) | (red << 16);
    return (0x1000000 + rgb).toString(16).slice(1);
  }

  public hexToRgb(hex: string): any {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  public hexToComplimentary(hex: string): string {
    let rgb = this.hexToRgb(hex);
    console.log(rgb.r + ' ' + rgb.g + ' ' + rgb.b);
    let hsl = this.rgbToHsl(rgb.r, rgb.g, rgb.b);
    console.log(hsl[0] + ' ' + hsl[1] + ' ' + hsl[2]);

    // Shift hue to opposite side of wheel and convert to [0-1] value
    let hue = hsl[0];
    let sat = hsl[1];
    let lum = hsl[2];

    hue = hue + 180;
    if (hue > 360) {
      hue = hue - 360;
    }
    hue = hue / 360;

    let newRGB = this.hslToRgb(hue, sat, lum);
    console.log(
      Math.round(newRGB[0]) +
        ' ' +
        Math.round(newRGB[1]) +
        ' ' +
        Math.round(newRGB[2])
    );

    let newHex = this.rgb2hex(
      Math.round(newRGB[0]),
      Math.round(newRGB[1]),
      Math.round(newRGB[2])
    );

    return newHex;
  }

  public invertColor(hex: string): string {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    // invert color components
    var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
      g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
      b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return this.padZero(r, 2) + this.padZero(g, 2) + this.padZero(b, 2);
  }

  private padZero(str: string, len: number): string {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  }

  public isDark(hex: string): boolean {
    let result = false;
    let rgb = this.hexToRgb(hex);
    let hsl = this.rgbToHsl(rgb.r, rgb.g, rgb.b);
    let lum = hsl[2];
    if (lum < 0.6) {
      result = true;
    } else {
      result = false;
    }
    return result;
  }

  public hexToRGBString(hex: string): string {
    let rgb: any = this.hexToRgb(hex);
    return rgb.r + ',' + rgb.g + ',' + rgb.b;
  }

  public hexToRGBOpacityString(hex: string, opacity: number): string {
    let rgb: any = this.hexToRgb(hex);
    return rgb.r + ',' + rgb.g + ',' + rgb.b + "," + opacity;
  }

  public hexToRGBOpacityStringFull(hex: string, opacity: number): string {
    let rgb: any = this.hexToRgb(hex);
    return "rgba(" + rgb.r + ',' + rgb.g + ',' + rgb.b + "," + opacity + ")";
  }


  public rgbToString(red: number, green: number, blue: number): string {
    let str = "rgb(" + red + "," + green + "," + blue + ")" 
    return str
  }

  public rgbArrayToString(rgb: any): string {
    let str = "rgb(" + rgb.r + "," + rgb.g + "," + rgb.b + ")" 
    return str
  }


  public cie_to_rgb(x: number, y: number, brightness: number): any {
    let util: ColorCIERGBUtils = new ColorCIERGBUtils()
    return util.cie_to_rgb(x,y,brightness)
  }
  
  public rgb_to_cie(red: number, green: number, blue: number): any {
    let util: ColorCIERGBUtils = new ColorCIERGBUtils()
    return util.rgb_to_cie(red, green, blue)
  }
  

}
