///////////////////////////////////////
// Handle animations of lists - adds,
// deletes etc
///////////////////////////////////////

export class CollectionsUIAnimations {



  //animation when a neighbouring tile
  //was deleted or inserted
  private addTileEffectAnimation:  string = "fade-in"
  private hideTileEffectAnimation:  string = "tileZoomOut"
  private showTileEffectAnimation:  string = "fade-in"

  private neighbouringTileEffectAnimation:  string = "wobblyEffect"
  private moveCounter: number = 6;


  constructor() {
  }


  initialiseTileCollection(records: any) {
    let i = 0;
    for(i=0;i<records.length;i++) {
      records[i]["visible"] = true
      records[i]["select_mode"] = false
      records[i]["selected_flag"] = false
      records[i]["deleted_flag"] = false
      records[i]["move_flag"] = false
      records[i]["array_index"] = -1
      records[i]["anim"] =	"",
      records[i]["width"] = 	220
      records[i]["height"] = 200
    }
    return records;
  }


  //////////////////////////////////////////////////////////////////////
  // SELECT TILE ANIMATIONS
  /////////////////////////////////////////////////////////////////////
  selectTileAnimationStart(items: any, id: string) {
		let i = 0;
		for(i=0;i<items.length;i++) {
			if(items[i]["id"] == id) {
        if(items[i]["selected_flag"] == true) {
          items[i]["array_index"] = i
          items[i]["selected_flag"] = false
        } else {
          items[i]["array_index"] = i
          items[i]["selected_flag"] = true
        }
			}
		}
    this.moveCounter = 4;
    return items;
	}

  selectTileAnimationEnd(items: any) {
    return items;
	}

  selectAll(items: any) {
    let i = 0;
		for(i=0;i<items.length;i++) {
      items[i]["selected_flag"] = true
		}
    return items
  }

  selectNone(items: any) {
    let i = 0;
		for(i=0;i<items.length;i++) {
      items[i]["selected_flag"] = false
		}
    return items
  }



  getSelectedItems(items: any): any[] {
    let selected: any[] = []
    let i = 0;
		for(i=0;i<items.length;i++) {
			if(items[i]["selected_flag"] == true) {
        selected.push(items[i]["objectid"])
			}
		}
    return selected
  } 


  //////////////////////////////////////////////////////////////////////
  // ADD TILE ANIMATIONS
  /////////////////////////////////////////////////////////////////////
  addTileAnimationStart(items: any, rec_id: string, new_rec: any, tile_width: number, tile_height: number) {
    new_rec["id"] = rec_id
		new_rec["visible"] = true
    new_rec["deleted_flag"] = false
    new_rec["move_flag"] = false
    new_rec["array_index"] = -1
		new_rec["anim"] =	this.addTileEffectAnimation
		new_rec["width"] = 	tile_width
		new_rec["height"] = tile_height
    items.unshift(new_rec)
    this.moveCounter = 0;
    return items;
	}

  addTileAnimationEnd(items: any) {
    items = this.tileWobbleEffect(items, 0)
    return items;
	}


  //////////////////////////////////////////////////////////////////////
  // DELETE TILE ANIMATIONS
	//////////////////////////////////////////////////////////////////////
	deletedItemIndex: number = -1;

	deleteTileAnimationStart(items: any, id: string) {
    this.moveCounter = 0;
		let i = 0;
		for(i=0;i<items.length;i++) {
			if(items[i]["id"] == id) {
				items[i]["anim"] = this.hideTileEffectAnimation
				items[i]["visible"] = true;
        items[i]["deleted_flag"] = true
        items[i]["array_index"] = i
				this.deletedItemIndex = i;
			} else {
				items[i]["anim"] = "";
			}
		}
    return items;
	}

  deleteTileAnimationEnd(items: any) {
    items.splice(this.deletedItemIndex, 1);
    items = this.tileWobbleEffect(items, this.deletedItemIndex)
    return items;
	}



  deleteMultipleSelectedItemsStart(items: any): any {
    this.moveCounter = 0;
		let i = 0;
		for(i=0;i<items.length;i++) {
			if(items[i]["selected_flag"] == true) {
				items[i]["anim"] = this.hideTileEffectAnimation
				items[i]["visible"] = true;
        items[i]["deleted_flag"] = true
        items[i]["array_index"] = i
				this.deletedItemIndex = i;
			} else {
				items[i]["anim"] = "";
			}
		}
    return items;
  }
  deleteMultipleSelectedItemsEnd(items: any) {
    let toKeep = []
    let i = 0;
		for(i=0;i<items.length;i++) {
			if(items[i]["deleted_flag"] == false) {
        toKeep.push(items[i])
			}
		}
    return toKeep
	}



  deleteSelectedItems(items: any): any {
    let toKeep = []
    let i = 0;
		for(i=0;i<items.length;i++) {
			if(items[i]["selected_flag"] == true) {
        console.log("deleting " + items[i]["id"])
				items[i]["anim"] = this.hideTileEffectAnimation
			} else {
        toKeep.push(items[i])
			}
		}
    return toKeep
  }


  getItemsToBeDeleted(items: any): any {
    let toDelete = []
    let i = 0;
		for(i=0;i<items.length;i++) {
			if(items[i]["selected_flag"] == true) {
        toDelete.push(items[i]["objectid"])
      } 
		}
    return toDelete
  }

	tileWobbleEffect(items: any, idx: number) {
    setTimeout(() => {
      if(idx < items.length) {
        idx = (idx * 1) + 1;
        items[idx]["anim"] = this.neighbouringTileEffectAnimation;
        if(this.moveCounter < items.length) {
          this.moveCounter = (this.moveCounter * 1) + 1;
          items = this.tileWobbleEffect(items, idx);
        }
      }
    }, 20);
    return items;
	}

  tileWobbleAll(items: any) {
    let i = 0;
		for(i=0;i<items.length;i++) {
			if(items[i]["selected_flag"] == false) {
        items[i]["anim"] = this.neighbouringTileEffectAnimation;
      }
		}
    return items;
	}


  shakeItem(items: any, id: string) {
    let i = 0;
		for(i=0;i<items.length;i++) {
      if(items[i]["id"] == id)
      items[i]["anim"] = "tiny-rubberband-effect";
		}
    return items;
	}


  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // ADD TILE ANIMATIONS
  /////////////////////////////////////////////////////////////////////
  hideOtherTiles(items: any, id: string) {
    let i = 0;
		for(i=0;i<items.length;i++) {
			if(items[i]["id"] != id) {
        items[i]["anim"] = this.hideTileEffectAnimation;
			}
		}
    return items;
	}

  unhideOtherTiles(items: any, id: string) {
    let i = 0;
		for(i=0;i<items.length;i++) {
			if(items[i]["id"] != id) {
				items[i]["anim"] = this.showTileEffectAnimation;
			}
		}
    return items;
	}

  resetAnimations(items: any) {
    let i = 0;
		for(i=0;i<items.length;i++) {
			items[i]["anim"] = "";
		}
    return items;
  }

  getDeletedItemIndex() {
    return this.deletedItemIndex
  }


  selectModeOn(items: any) {
    let i = 0;
    for(i=0;i<items.length;i++) {
      items[i]["select_mode"] = true;
    }
    return items;
  }

  selectModeOff(items: any) {
    let i = 0;
    for(i=0;i<items.length;i++) {
      items[i]["select_mode"] = false;
      items[i]["selected_flag"] = false;
    }
    return items;
  }

}
