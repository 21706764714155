import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  ChangeDetectorRef,
  Inject,
  NgZone,
  OnInit,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

import { UIColorModel } from '../../services/ui.color.model.service';
import { EventsService } from '../../services/event.service'


@Component({
  selector: 'switch',
	inputs: ['value'],
  outputs: ['state'],
  changeDetection: ChangeDetectionStrategy.Default,

  template: `
    <!-- Rounded switch -->
    <label class="_switch">
      <input #switchinput type="checkbox" [checked]=_initialValue (change)=checkValue($event)>
      <span class="_switch-slider round" [style.backgroundColor]=_color></span>
    </label>
  `,
})

export class SwitchComponent {
  @Output()
  state = new EventEmitter();
	@Input()
	set value(value: boolean) {
	  this.setValue(value);
	}


  changeDetectorRef: ChangeDetectorRef;
	uiColorModel: UIColorModel
	private eventsService: EventsService
  _color: string = "#00CCCC"
  _state: boolean = false
  _initialValue: boolean = false

	constructor(public ref: ChangeDetectorRef,
    private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
  }

  checkValue($event: any) {
    console.log($event.currentTarget.checked)
    if($event.currentTarget.checked == true) {
      this._color = this.uiColorModel.primary
      this._state = true
    } else {
      this._color = this.uiColorModel.surfaceDim
      this._state = false
    }
    this.state.emit(this._state)
  }


  updateState() {
    if(this._initialValue == true) {
      this._color = this.uiColorModel.primary
      this._state = true
    } else {
      this._color = this.uiColorModel.surfaceDim
      this._state = false
    }
    this.state.emit(this._initialValue)
  }


  setValue(value: boolean) {
    this._initialValue = value
  }

  ngOnInit() {
    this.updateState()
  }

  ngOnChanges() {}

  ngOnDestroy() {}
}
