import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  ChangeDetectorRef,
  Inject,
  NgZone,
  OnInit,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

import { EventsService } from '../../services/event.service'
import { UIColorModel } from '../../services/ui.color.model.service';


@Component({
  selector: 'icon-button',
  inputs: ['icon', 'activeicon', 'color', 'activecolor'],
  outputs: ['clicked'],
  changeDetection: ChangeDetectionStrategy.Default,

  template: `
    <div
      class="_icon-button" 
      [style.color]=_currentColor 
      (click)=iconClicked()>
      <i
        class="i mdi mdi-{{ _currentIcon }}"
      ></i>
    </div>
  `,
})
// surfaceContainerHighest
export class IconButtonComponent {
  @Output()
  clicked = new EventEmitter();

  @Input()
  set icon(value: string) {
    this.setIcon(value);
  }
  set activeicon(value: string) {
    this.setActiveIcon(value);
  }
  set color(value: string) {
    this.setColor(value);
  }
  set activecolor(value: string) {
    this.setActiveColor(value);
  }


	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	uiColorModel: UIColorModel

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel

		let self = this
		this.eventsService.on('onRefresh', function (args: any) {
      self.defaultColor()
      if(self._isPressed) {
        self._currentColor = self._activeColor
      } else {
        self._currentColor = self._inactiveColor
      }
		});
	}

  _currentIcon: string = ""
  _inactiveIcon: string = ""
  _activeIcon: string = ""

  _currentColor: string = ""
  _inactiveColor: string = ""
  _activeColor: string = ""

  _isPressed: boolean = false
  _isDisabled: boolean = false


  setIcon(value: string) {
      this._inactiveIcon = value;
  }

  setActiveIcon(value: string) {
    this._activeIcon = value;
  }

  setColor(value: string) {
    if (value.startsWith('linear-gradient') || value.startsWith('rgb') || value.startsWith('#')) {
      this._inactiveColor = value;
    } else {
      this._inactiveColor = '#' + value;
    }
  }

  setActiveColor(value: string) {
    if (value.startsWith('linear-gradient') || value.startsWith('rgb') || value.startsWith('#')) {
      this._activeColor = value;
    } else {
      this._activeColor = '#' + value;
    }
  }


  defaultColor() {
    this._activeColor = this.uiColorModel.primary
    this._inactiveColor = this.uiColorModel.onSurface
  }

  pressed() {
    this._isPressed = true
    this._currentColor = this._activeColor
    this._currentIcon = this._activeIcon
  }

  enabled() {
    this._isPressed = false
    this._currentColor = this._inactiveColor
    this._currentIcon = this._inactiveIcon
  }

  iconClicked() {
    if(this._currentIcon == this._activeIcon) {
      this.enabled()
      this.clicked.emit(false)
    } else {
      this.pressed()
      this.clicked.emit(true)
    }
  }

  ngOnInit() {
    this.defaultColor()
    this._currentIcon = this._inactiveIcon
    this._currentColor = this._inactiveColor
  }

  ngOnChanges() {}

  ngOnDestroy() {}
}
