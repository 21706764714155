import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'
import { ApplicationService } from '../../services/application.service';
import { UILayout } from '@coreui//services/ui.layout.service';
import { ImageGalleryUploadService } from './imagegallery.upload.service';


@Component({
	selector: 		'imagegallery-screen',
	encapsulation: ViewEncapsulation.None,

	template:	`

	<saas-page-container>
		<div toolbar>
			<saas-collections-toolbar (searchvalue)=search($event) (clicked)=toolbarAction($event)></saas-collections-toolbar>
		</div>
		<div content>
			<div id="scroll-to-top" class="_saas-page-header">
			</div>

			<images-collection-anim [data]=imgData [width]=200 [height]=160></images-collection-anim>
			<scroll-top-button [scrollshowvalue]=144 (clicked)=scrollToTop()></scroll-top-button>
		</div>


	</saas-page-container>

		
	`,

})


export class ImageGalleryScreen {

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	private uiLayout: UILayout
	applicationService: ApplicationService
	imageGalleryUploadService: ImageGalleryUploadService

	imgData: any = []
	update_interval: any;

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
				private _uiLayout: UILayout,
				private _imageGalleryUploadService: ImageGalleryUploadService,
			 	private _applicationService: ApplicationService) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiLayout = _uiLayout
		this.imageGalleryUploadService = _imageGalleryUploadService
		this.applicationService = _applicationService

		let self = this
		this.eventsService.on("objectsToDelete", function (args: any) {
			self.deleteMultiple(args.data)
		});
		this.eventsService.on("onImageGalleryObjectAdded", function (args: any) {
			self.add(args.id, args.url)
			self.eventsService.onRefresh()
			self.changeDetectorRef.detectChanges()
		});
	  	
		
	}
  
	ngOnInit() {
		this.fetchImages()

	/*	this.update_interval = setInterval(() => {
			this.imageGalleryUploadService.removeAfter3Secs()
		}, 1000)
*/
	}

	ngOnDestroy() {
		if (this.update_interval) {
			clearInterval(this.update_interval);
		}
	}

	ngAfterViewInit() {
	}

	scrollToTop() {
		let textElement: any = document.getElementById("scroll-to-top")
		textElement.scrollIntoView({behavior: "smooth"})
	}


	search($event: any) {

	}


	toolbarAction($event: any) {
		if($event == "action-clicked") {
			this.eventsService.broadcast("onShowModalOverlay", {id: "imagegallery-upload-slider"})
		}

		if($event == "select-on") {
			this.eventsService.broadcast("selectModeOn", {})	
			this.eventsService.onRefresh()
		}

		if($event == "select-off") {
			this.eventsService.broadcast("selectModeOff", {})	
			this.eventsService.onRefresh()
		}

		if($event == "trash-on")
			this.eventsService.broadcast("showTrashed", {})	

		if($event == "trash-off")
			this.eventsService.broadcast("showTrashedOff", {})	

		if($event == "trash-selected")
			this.eventsService.broadcast("deleteSelected", {})	

		this.changeDetectorRef.detectChanges()
	}


	add(id: string, url: string) {
		let rec = {
			objectid: id,
			url: url
		}
		//console.log(JSON.stringify(rec, null, 4))
		this.imgData.push(rec)
	}

	fetchImages() {
		let sid = this.applicationService.session.sessionid
		let apikey = this.applicationService.session.apikey
		let gid = "402b3bc3-453e-4199-afd9-e19642efb025"
		this.imageGalleryUploadService.activeGalleryId = gid
		this.applicationService.dpImageGalleryApi.getGallery(apikey, gid).subscribe(
			value => {
				let i=0
				for(i=0;i<value.body.data.images.length;i++) {
					let oid = value.body.data.images[i]["objectid"]
					let url = value.body.data.images[i]["url"]
					this.add(oid, url)
				}
				this.eventsService.onRefresh()
				this.changeDetectorRef.detectChanges()
			}, 
			err => {
				console.log(err)
				console.log("Error contacting server")
		})		

	}

	deleteMultiple(objects: any[]) {
		this.applicationService.dpImageGalleryApi.deleteMultipleObjects(objects).subscribe(
			value => {
				console.log("HERE!!!!")
				this.fetchImages()
			}, 
			err => {
				console.log(err)
				console.log("Error contacting server")
		})		

	}

}
