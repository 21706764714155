import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'
import { UIColorModel } from '@coreui/services/ui.color.model.service';
import { ApplicationService } from '../../services/application.service';


@Component({
	selector: 		'intro-screen',
	encapsulation: ViewEncapsulation.None,

	template:	`

	<content-container-scrollable>
		<optimised-content-container>

			<div class="_launcher-screen">
				<div class="logo">
					<img [src]=_logoSrc />  
				</div>
				<div class="content">
					<ul class="_horizontal-swipe-row">
						<discover-place-card [imgsrc]=samplePic1></discover-place-card>
						<discover-place-card [imgsrc]=samplePic2></discover-place-card>
						<discover-place-card [imgsrc]=samplePic1></discover-place-card>
						<discover-place-card [imgsrc]=samplePic1></discover-place-card>
					</ul>
				</div>
			</div>


		</optimised-content-container>
	</content-container-scrollable>
		
	`,

})


export class IntroScreen {

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	uiColorModel: UIColorModel
	applicationService: ApplicationService

	_logoSrc: string = "./assets/loyalty/logos/peach_marketing_logo.png"
	samplePic1: string = "/assets/sample/media/indigo-restaurant.jpg"
	samplePic2: string = "/assets/sample/media/coco-mama.png"


	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel, private _applicationService: ApplicationService) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
		this.applicationService = _applicationService
	}

	ngOnInit() {
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

}
