import { Hct } from "@material/material-color-utilities";
import { argbFromHex, hexFromArgb, sourceColorFromImage } from '@material/material-color-utilities';


export class ColorHCTUtils {

  public hex2HCT(hex: string): Hct {
    let argb = argbFromHex(hex)
    let color = Hct.fromInt(argb)
    return color
  }

  public hexFromHCT(hex: string, scale: number): string {
    let argb = argbFromHex(hex)
    let color = Hct.fromInt(argb)
    color.tone = scale
    let newARGBColor = color.toInt()
    let newHex = hexFromArgb(newARGBColor)
    return newHex
  }

 
  


}
