import {
	Component,
	ViewEncapsulation
} from '@angular/core';

import { ApplicationService } from '../services/application.service';
import { EventsService } from '@coreui/services/event.service'
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { UILayout } from '@coreui//services/ui.layout.service';

@Component({
	selector: 		'bottom-navigation',
	encapsulation: ViewEncapsulation.None,

	template:	`

			<div [ngSwitch]=this.uiNavigation.activeBottomNav>
				<div *ngSwitchCase="'home'">
					<navigation-bar 
							[data]=this.applicationService.navigationBar()
							(clicked)=handleNavClicked($event)>
					</navigation-bar>
				</div>
			</div>

	`,

})


export class BottomNavigation {

	private eventsService: EventsService
	applicationService: ApplicationService
	uiNavigation: UINavigation
	uiLayout: UILayout

	constructor(_eventsService: EventsService, 
			private _applicationService: ApplicationService,
			private _uiNavigation: UINavigation,
			private _uiLayout: UILayout) {
		this.eventsService = _eventsService
		this.applicationService = _applicationService
		this.uiNavigation = _uiNavigation
		this.uiLayout = _uiLayout

		let self = this;
		self.eventsService.on('onShowModalOverlay', function (args: any) {
		});

	}

	ngOnInit() {
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {

	}


	handleNavClicked($event: any) {
		this.applicationService.navigationBar().setActive($event.id, true)
		this.uiNavigation.activeView = $event.id

	}
}
