import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter,
	HostListener, 
	ChangeDetectionStrategy
} from '@angular/core';

import { UILayout } from '@coreui/services//ui.layout.service'
import { EventsService } from '@coreui/services/event.service'
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { UIColorModel } from '@coreui/services/ui.color.model.service';


@Component({
	selector: 		'list-detail-page',
	changeDetection: ChangeDetectionStrategy.Default,
	inputs: ['width'],
	template: 	`

		<div class="_list-detail-layout">
			<div class="list-pane"
				[style.flex]=_listPane>
				<ng-content select="[list-pane]"></ng-content>
			</div>

			<div class="detail-pane scrollable-div-container"
				[style.flex]=_detailPane
				[style.height.px]=_containerHeight>
				<ng-content select="[detail-pane]"></ng-content>
			</div>
		</div>

		`,
})


export class ListDetailPage {

	@Input()
	set width(value: number) {
	  this.setWidth(value);
	}
  
	private changeDetectorRef: ChangeDetectorRef
	private uiLayout: UILayout
	private uiNavigation: UINavigation
    private eventsService: EventsService
	uiColorModel: UIColorModel

	_listPane: string = "0 0 320px"
	_listPaneWidth: number = 320
	_detailPane: string = "1"
	_containerHeight: number = 0

	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService, 
		private _uiLayout: UILayout,
		private _uiNavigation: UINavigation,
		private _uiColorModel: UIColorModel) {
		this.uiLayout = _uiLayout
		this.eventsService = _eventsService
		this.uiNavigation = _uiNavigation
		this.uiColorModel = _uiColorModel
		this.changeDetectorRef = ref

		this.recalculate()

		let self = this;
		this.eventsService.on('onResize', function (args: any) {
			self.recalculate()
			self.changeDetectorRef.markForCheck()
		});

	}

	setWidth(value: number) {
		this._listPaneWidth = value
		this._listPane = "0 0 " + this._listPaneWidth + "px"
	}

	recalculate() {
		if(this.uiLayout.windowClass == "compact") {
			this._listPane = "1"
			this._detailPane = "0"
		}
		if(this.uiLayout.windowClass == "medium") {
			this._listPane = "1"
			this._detailPane = "0"
		}
		if(this.uiLayout.windowClass == "expanded") {
			this._listPane = "0 0 320px"
			this._detailPane = "1"
		}

		this._containerHeight = window.innerHeight - 64
	}


	ngOnInit() {
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}