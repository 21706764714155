import {Injectable} from '@angular/core'
import { HttpClient } from  '@angular/common/http'
import { Observable, Subject, ReplaySubject, from, of, range, timer, interval  } from 'rxjs'
import {takeWhile, take } from "rxjs/operators"
import { map, filter, switchMap } from 'rxjs/operators'


import { UINavigation } from '@coreui/services/ui.navigation.service';
import { UIColorModel } from '@coreui/services/ui.color.model.service';
import { UILayout } from '@coreui/services//ui.layout.service'
import { TypographyModel } from '@coreui/services/typography.service';


import { NavigationUISettings } from '../sys/models/navigation/navigation.settings'

import { NavigationRailSettings } from '@coreui/classes/navigation/navigation.rail.class'
import { NavigationDrawerSettings } from '@coreui/classes/navigation/navigation.drawer.class'
import { NavigationDrawerMenu } from '@coreui/classes/navigation/navigation.drawer.menu.class'
import { NavigationBarSettings } from '@coreui/classes/navigation/navigation.bar.class'


import { Themes } from '../sys/models/theme/themes.class'


import { UITheme } from '@coreui/classes/theme/ui.theme.class'

import { SkyAISessionService } from '@libraries/skyai-api/api/skyai.session.class'
import { SkyAIDOMService } from '@libraries/skyai-api/api/skyai.dom.class'

import { DpImageGalleryAPI } from '@libraries/domainprotocols/imagegalleryv1/dp.imagegallery.class'


import { PMThemes } from '../classes/themes.class'

import { PersonSampleDataInfo } from '@libraries/people-ui/classes/person.sample.class'




import { DomainOpeningHoursService } from '@libraries/domainprotocols/opening.hours.service';

import { AirportsData } from '../data/airports/airports.class';


@Injectable()
export class ApplicationService  {

  private uiNavigation: UINavigation
  private uiColorModel: UIColorModel
  private uiLayout: UILayout
	private typographyModel: TypographyModel
	private httpClient: HttpClient

  private _themes: PMThemes

  private _navigationUISettings: NavigationUISettings = new NavigationUISettings()
  private _mainNavigationRail: NavigationRailSettings = new NavigationRailSettings()
  private _mainNavigationBar: NavigationBarSettings = new NavigationBarSettings()
  private _mainNavigationDrawer: NavigationDrawerSettings = new NavigationDrawerSettings()
  private _mainNavigationDrawerMenu: NavigationDrawerMenu = new NavigationDrawerMenu()

  private skyAISessionService: SkyAISessionService
  private skyAIDOMService: SkyAIDOMService
  private dpImageGalleryAPI: DpImageGalleryAPI


  private samplePeople: PersonSampleDataInfo = new PersonSampleDataInfo()
  private people: any[] = []

  private _activeBackground: string = "peachy"

  constructor(_http: HttpClient,
    private _uiNavigation: UINavigation, 
    private _uiColorModel: UIColorModel,
    private _uiLayout: UILayout,
    private _typographyModel: TypographyModel) {

    this.httpClient = _http
    this.uiNavigation = _uiNavigation
    this.uiColorModel = _uiColorModel
    this.uiLayout = _uiLayout
    this.typographyModel = _typographyModel

    this._themes = new PMThemes(this.uiColorModel)

    this.skyAISessionService = new SkyAISessionService(this.httpClient)
		this.skyAISessionService.init("https://pg8.in", "skyai_root.FVsaDg1rQEIAcRpqQEukPkqxjeOmPb8NeJgn9Sef")
    this.skyAIDOMService = new SkyAIDOMService(this.httpClient)
		this.skyAIDOMService.init("https://pg8.in", "skyai_root.FVsaDg1rQEIAcRpqQEukPkqxjeOmPb8NeJgn9Sef")

    this.dpImageGalleryAPI = new DpImageGalleryAPI(this.httpClient)
    this.dpImageGalleryAPI.init("https://pg8.in", "skyai_root.FVsaDg1rQEIAcRpqQEukPkqxjeOmPb8NeJgn9Sef")


    this.uiLayout.applicationContainerSettings().compactNavigationComponent = "both"
    this.uiLayout.applicationContainerSettings().mediumNavigationComponent = "rail"
    this.uiLayout.applicationContainerSettings().expandedNavigationComponent = "rail"

    this.uiNavigation.activeView = "travel"
    this.uiNavigation.activeBottomNav = "home"
    this._activeBackground = "clouds"
    this.landingMode()


    this.typographyModel.uiFontFamily = "Inter"
    this.typographyModel.displayFontFamily = "Comfortaa"




    this.navUISettings()

    this.people = this.samplePeople.data

  }

  darkMode() {
    this.uiColorModel.darkScheme()
  }
  lightMode() {
    this.uiColorModel.lightScheme()
  }

  public get activeBackground(): string {
    return this._activeBackground
  }
  public set activeBackground(value: string) {
    this._activeBackground = value
  }

  public navigationRail(): NavigationRailSettings {
    return this._mainNavigationRail
  }

  public navigationDrawer(): NavigationDrawerSettings {
    return this._mainNavigationDrawer
  }

  public navigationBar(): NavigationBarSettings {
    return this._mainNavigationBar
  }

  public get session(): SkyAISessionService {
    return this.skyAISessionService
  }
  public get dom(): SkyAIDOMService {
    return this.skyAIDOMService
  }


  public get dpImageGalleryApi(): DpImageGalleryAPI {
    return this.dpImageGalleryAPI
  }


  private navUISettings () {
    this._mainNavigationRail = this._navigationUISettings.defineNavigationRail()    
    this._mainNavigationDrawer = this._navigationUISettings.defineNavigationDrawer()
    this._mainNavigationBar = this._navigationUISettings.defineNavigationBar()
  }




  landingMode() {
    this.themes.darkWaterTheme()
    this.uiLayout.applicationContainerSettings().compactNavigationComponent = "top"
    this.uiLayout.applicationContainerSettings().mediumNavigationComponent = "top"
    this.uiLayout.applicationContainerSettings().expandedNavigationComponent = "top"
  }


  dashMode() {
    this.themes.peachyTheme()
    this.uiLayout.applicationContainerSettings().compactNavigationComponent = "both"
    this.uiLayout.applicationContainerSettings().mediumNavigationComponent = "rail"
    this.uiLayout.applicationContainerSettings().expandedNavigationComponent = "rail"
  }





  peopleData() {
    return this.people
  }


  public get themes(): PMThemes {
    return this._themes
  }
  public set themes(value: PMThemes) {
    this._themes = value
  }



}
