import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  ChangeDetectorRef,
  Inject,
  NgZone,
  OnInit,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

import { UIColorModel } from '@coreui/services/ui.color.model.service';


@Component({
  selector: 'std-social-button',
  inputs: ['type', 'label', 'preloading'],
  outputs: ['clicked'],
  changeDetection: ChangeDetectionStrategy.Default,

  template: `
    <div
      class="std-social-button background-color-transition-350 
        fullwidth {{ btnPressEffect }}" 
      [style.background]=btnColor
      [style.color]=txtColor
      (click)="btnClicked()"
      font-family

	    elevated [elevation]=1 >
        <span *ngIf="_preloadingState==false">
          <div class="icon">
          <i class="i mdi mdi-{{ btnIcon }} {{ spinClass }}"></i>
          </div>
          <div class="label" font-family>
            {{ this.btnLabel }}
          </div>
       <!--   <div class="action">
            <i class="i mdi mdi-chevron-right"></i>
          </div> -->
        </span>

        <span *ngIf="_preloadingState==true">
          <div class="preloader-wrapper">
            <div class="preloader-spinner"
              [style.borderTop]=_borderTop
              [style.borderRight]=_borderTop
              [style.borderBottom]=_borderTop></div>
          </div>
        </span>

    </div> 
  `,
})
export class StdSocialButtonComponent {
  @Output()
  clicked = new EventEmitter();

  @Input()
  set type(value: string) {
    this.setType(value);
  }
  set label(value: string) {
    this.setLabel(value);
  }
  set preloading(value: boolean) {
    this.setPreloading(value)
  }

  changeDetectorRef: ChangeDetectorRef
  uiColorModel: UIColorModel

  constructor(public ref: ChangeDetectorRef, private _uiColorModel: UIColorModel) {
    this.changeDetectorRef = ref
    this.uiColorModel = _uiColorModel
  }

  btnLabel: string = '';
  btnIcon: string = '';
  btnColor: string = 'FFEEEE';
  txtColor: string = 'FFFFFF';
  prevBtnColor: string = 'FFEEEE';
  btnPressEffect: string = '';
  btnOpacity: number = 1;
  txtMarginLeft: number = 0;

  spinDirection: string = 'clock';
  spinClass: string = 'anim-spin-clockwise';

  _preloadingState: boolean = false
  _borderTop: string = "3px solid #FFFFFF"


  //colors
  _facebookColor:  string = "1877f2";
  _twitterColor:  string = "1da1f2";
  _youtubeColor:  string = "ff0000";
  _linkedinColor:  string = "0a66c2";
  _instagramColor:  string = "-webkit-linear-gradient(225deg, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)"
  _tiktokColor:  string = "010101";
  _appleColor:  string = "000000";
  _googleColor:  string = "db4a39";


  setLabel(value: string) {
    this.btnLabel = value;
  }

  setColor(value: string) {
    if (value.startsWith('linear-gradient') || value.startsWith('rgb') || value.startsWith('#')
         || value.startsWith('-webkit-linear-gradient')) {
      this.btnColor = value;
    } else {
      this.btnColor = '#' + value;
    }
  }

  setIcon(value: string) {
    if (this.btnIcon == 'null') {
      this.btnIcon = value;
    } else {
      this.txtMarginLeft = 8;
      this.changeIcon(value);
    }
  }

  setTextColor(value: string) {
    this.txtColor = value
  }

  btnClicked() {
    this.btnPressEffect = 'button-press-animation-2';
    this.clicked.emit(true);
    setTimeout(() => {
      this.btnPressEffect = '';
    }, 250);
  }

  changeIcon(v: string) {
    if (this.spinDirection == 'clock') {
      this.spinClass = 'anim-spin-clockwise';
      this.ref.markForCheck();
      setTimeout(() => {
        this.btnIcon = v;
        this.spinClass = '';
      }, 150);
    } else {
      this.spinClass = 'anim-spin-counterclockwise-150';
      this.ref.markForCheck();
      setTimeout(() => {
        this.btnIcon = v;
        this.spinClass = '';
      }, 150);
    }
  }


  setType(value: string) {
    if(value == "apple") {
      this.btnIcon = "apple"
      this.setColor(this._appleColor)
    }
    if(value == "facebook") {
      this.btnIcon = "facebook"
      this.setColor(this._facebookColor)
    }
    if(value == "instagram") {
      this.btnIcon = "instagram"
      this.setColor(this._instagramColor)
    }
    if(value == "linkedin") {
      this.btnIcon = "linkedin"
      this.setColor(this._linkedinColor)
    }
    if(value == "twitter") {
      this.btnIcon = "twitter"
      this.setColor(this._twitterColor)
    }
    if(value == "google") {
      this.btnIcon = "google"
      this.setColor(this._googleColor)
    }
    if(value == "email") {
      this.btnIcon = "email-outline"
      this.setColor(this.uiColorModel.primary)
    }
  }

  setPreloading(value: boolean) {
    this._preloadingState = value
  }


  ngOnInit() {}

  ngOnChanges() {}

  ngOnDestroy() {}
}
