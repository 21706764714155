import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';

@Component({
	selector: 		'dash-routes',
	inputs: 			['route'],
	encapsulation: ViewEncapsulation.None,

	template:	`

			<div [ngSwitch]=this._route>

				<div *ngSwitchCase="'home'">
					<home-screen></home-screen>
				</div>

				<div *ngSwitchCase="'signup'">
					<signup-screen></signup-screen>
				</div>

				<div *ngSwitchCase="'ecomm'">
					<ecommerce-screen></ecommerce-screen>
				</div>

			</div>

	`,

})


export class DashRoutes {
	@Input()
    set route(value: string) { this.setRoute(value); }

	_route: string = ""
	_opacity: number = 1

	private setRoute(route: string): void {
		setTimeout(() => {
			this._opacity = 0
			setTimeout(() => {
				this._route = route
				this._opacity = 1
			}, 150);
		}, 10);
	}

	constructor() {
	}

	ngOnInit() {
	}

	ngOnDestroy() {
	} 

	ngAfterViewInit() {

	}


}
