import {
	Component,
	ViewEncapsulation,
	ViewChild,
	ElementRef,
	HostListener,
	ChangeDetectorRef,
	Input
} from '@angular/core';

import { EventsService } from '../../services/event.service'


@Component({
	selector: 		'optimised-content-container',
	encapsulation: ViewEncapsulation.None,
	template:	`
		<div #optimisedcontentcontainer
			[style.paddingLeft.px]=_margins
			[style.paddingRight.px]=_margins>
			<ng-content></ng-content>
		</div>
	`,

})


export class PageContentContainer {
	@ViewChild('optimisedcontentcontainer')
	optimisedcontentcontainer!: ElementRef;

	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		this.calculateWidths()
	}


	private changeDetectorRef: ChangeDetectorRef
    private eventsService: EventsService

	_margins: number = 32
	_topMargin: number = 32
	_bottomMargin: number = 64
	_usingPercentages: boolean = false

	_marginsPx: number = 8.125
	_topMarginPx: number = 8.125

	_containerWidth: number = 100

	_optimalWidth: number = 576
	_optimalWidthSmall: number = 340

	_check_interval: any = {}
	_specifiedWidth: number = 0

	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService

		let self = this;
		this.eventsService.on('onRefresh', function (args: any) {
			self.calculateWidths()
		});		
	}


    private calculateOptimalReadingMargins(containerWidth: number, optimalWidth: number, optimalWidthSmall: number): number {
        let margins = 24
            
		if(containerWidth > optimalWidth) {
			let diff: number = containerWidth - optimalWidth
			margins = diff / 2
		} 
		if(margins < 24) {
			margins = 24
		}
        return margins
	}


	calculateWidths() {
		let containerWidth = this.optimisedcontentcontainer.nativeElement.offsetWidth;
		let optimalWidth = 676
		let optimalWidthSmall = 340
		this._margins = this.calculateOptimalReadingMargins(containerWidth, optimalWidth, optimalWidthSmall)
		this.changeDetectorRef.detectChanges()
	}

	ngOnInit() {
		this._check_interval = setInterval(() => {
			this.calculateWidths()
		}, 300);

	}

	ngAfterViewInit() {
		this.calculateWidths()
		this.changeDetectorRef.detectChanges()
	}


	ngOnDestroy() {
		if (this._check_interval) {
			clearInterval(this._check_interval);
		}
	}


}
