import { HttpClient,  HttpResponse, HttpHeaders, HttpEvent, HttpErrorResponse, HttpEventType, HttpRequest} from  '@angular/common/http';
import { Observable, Subject, ReplaySubject, from, of, range, timer, interval  } from 'rxjs';
import { takeWhile, take } from "rxjs/operators";
import { map, filter, switchMap } from 'rxjs/operators';


export class DpImageGalleryAPI {

  private http: HttpClient;

  private _apiURL: string = "";
  private _apikey: string = "";

  private get_gallery_endpoint:   string = "/dp/v1/imagegallery/gallery";
  private add_object_endpoint:   string = "/dp/v1/imagegallery/object";
  private delete_multiole_endpoint:   string = "/dp/v1/imagegallery/delete_objects";


  constructor(_http: HttpClient) {
    this.http = _http;
  }

  init(apiurl: string, apikey: string) {
    this.apiURL = apiurl;
    this.apikey = apikey;
  }



  getGallery(id: string, galleryid: string): Observable<any> {
    let dat = {
      id:           id,
      apikey:       this.apikey,
      galleryid:    galleryid
    };
    let body = JSON.stringify(dat);
    return this.http.post(this.apiURL + this.get_gallery_endpoint, body, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response'
    })
  }


  addObject(id: string, objectid: string, objecttype: string, ext: string, size: number): Observable<any> {
    let dat = {
      id:           id,
      apikey:       this.apikey,
      objectid:     objectid,
      type:         objecttype,
      size:         size,
      ext:          ext
    };
    let body = JSON.stringify(dat);
    return this.http.post(this.apiURL + this.add_object_endpoint, body, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response'
    })
  }


  deleteMultipleObjects(objects: any[]): Observable<any> {
    let dat = {
      apikey:      this.apikey,
      objects:     objects
    };
    let body = JSON.stringify(dat);
    return this.http.post(this.apiURL + this.delete_multiole_endpoint, body, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response'
    })
  }


  public get apiURL(): string {
    return this._apiURL;
  }
  public set apiURL(value: string) {
    this._apiURL = value;
  }
  public get apikey(): string {
    return this._apikey;
  }
  public set apikey(value: string) {
    this._apikey = value;
  }

}
