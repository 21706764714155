import { UIColorModel } from '@coreui/services/ui.color.model.service';


export class PMThemes {


    private uiColorModel: UIColorModel
    private _dcBackground: string = ""
    private _dcCloudBackground1: string = ""
    private _dcCloudBackground2: string = ""
    private _dcCloudOpacity: number = 0.1
    private _dcCloudNumber: number = 6
    private _dcCloudSpeed: number = 5000
    private _dcCloudInterval: number = 5000
    

    constructor(private _uiColorModel: UIColorModel) {
        this.uiColorModel = _uiColorModel
    }



    peachyTheme() {
        let amaranthRed: string = "e96625"
        let blazingYellow: string = "fee715"
        let reef: string = "017371"
        let shadedGrey: string = "605D62"
        let baroqueGrey: string = "605D66"
        let errorRed: string = "b3261e"

        this._dcBackground = this.removeHash(this.uiColorModel.utils().hexFromHCT(amaranthRed, 98))
        this._dcCloudBackground1 = amaranthRed
        this._dcCloudBackground2 = "f478d9"
        this._dcCloudOpacity = 0.05
        this._dcCloudInterval = 5000
        this._dcCloudSpeed = 5000
        this._dcCloudNumber = 7
        this.uiColorModel.generateM3ColorScheme(amaranthRed, amaranthRed, reef, shadedGrey, baroqueGrey, errorRed)
        this.uiColorModel.lightScheme()
    }
    
    waterTheme() {
        let comeTotheSea: string = "2596be"
        let magicMint: string = "20ec90"
        let reef: string = "017371"
        let shadedGrey: string = "605D62"
        let baroqueGrey: string = "605D66"
        let errorRed: string = "b3261e"

        this._dcBackground = "2596be"
        this._dcCloudBackground1 = comeTotheSea
        this._dcCloudBackground2 = "1bf6ef"
        this._dcCloudOpacity = 0.8
        this._dcCloudInterval = 3000
        this._dcCloudSpeed = 5000
        this._dcCloudNumber = 12

        this.uiColorModel.generateM3ColorScheme(comeTotheSea, magicMint, reef, shadedGrey, baroqueGrey, errorRed)
        this.uiColorModel.lightScheme()   
    }  
    
    darkWaterTheme() {
        let comeTotheSea: string = "2596be"
        let magicMint: string = "20ec90"
        let reef: string = "017371"
        let shadedGrey: string = "605D62"
        let baroqueGrey: string = "605D66"
        let errorRed: string = "b3261e"

        this._dcBackground = "02042b"
        this._dcCloudBackground1 = comeTotheSea
        this._dcCloudBackground2 = "042957"
        this._dcCloudOpacity = 0.3
        this._dcCloudInterval = 3000
        this._dcCloudSpeed = 5000
        this._dcCloudNumber = 12

        this.uiColorModel.generateM3ColorScheme(comeTotheSea, magicMint, reef, shadedGrey, baroqueGrey, errorRed)
        this.uiColorModel.darkScheme()   
    }        

    
    purpleSkiesTheme() {
        let comeTotheSea: string = "0f32f5"
        let magicMint: string = "ccf0e0"
        let reef: string = "017371"
        let shadedGrey: string = "605D62"
        let baroqueGrey: string = "605D66"
    
        let errorRed: string = "b3261e"

        this._dcBackground = this.uiColorModel.utils().hexFromHCT("d1f7f2", 98)
        this._dcCloudBackground1 = comeTotheSea
        this._dcCloudBackground2 = "042957"
        this._dcCloudOpacity = 0.1
        this._dcCloudInterval = 15000
        this._dcCloudSpeed = 10000
        this._dcCloudNumber = 9
    

        this.uiColorModel.generateM3ColorScheme(comeTotheSea, magicMint, reef, shadedGrey, baroqueGrey, errorRed)
        this.uiColorModel.lightScheme()   
    }        


    calmSkyTheme() {
        let comeTotheSea: string = "12a0ed"
        let magicMint: string = "20ec90"
        let reef: string = "017371"
        let shadedGrey: string = "605D62"
        let baroqueGrey: string = "605D66"
        let errorRed: string = "b3261e"

        this._dcBackground = this.uiColorModel.utils().hexFromHCT("150bdf", 30)
        this._dcCloudBackground1 = comeTotheSea
        this._dcCloudBackground2 = "042957"
        this._dcCloudOpacity = 0.2
        this._dcCloudInterval = 7000
        this._dcCloudSpeed = 3000
        this._dcCloudNumber = 9
        this.uiColorModel.generateM3ColorScheme(comeTotheSea, magicMint, reef, shadedGrey, baroqueGrey, errorRed)
        this.uiColorModel.lightScheme()   
    }  



    public get dcBackground(): string {
        return this._dcBackground
    }
    public set dcBackground(value: string) {
        this._dcBackground = value
    }
    public get dcCloudBackground1(): string {
        return this._dcCloudBackground1
    }
    public set dcCloudBackground1(value: string) {
        this._dcCloudBackground1 = value
    }
    public get dcCloudBackground2(): string {
        return this._dcCloudBackground2
    }
    public set dcCloudBackground2(value: string) {
        this._dcCloudBackground2 = value
    }
    public get dcCloudOpacity(): number {
        return this._dcCloudOpacity
    }
    public set dcCloudOpacity(value: number) {
        this._dcCloudOpacity = value
    }
    public get dcCloudNumber(): number {
        return this._dcCloudNumber
    }
    public set dcCloudNumber(value: number) {
        this._dcCloudNumber = value
    }
    public get dcCloudSpeed(): number {
        return this._dcCloudSpeed
    }
    public set dcCloudSpeed(value: number) {
        this._dcCloudSpeed = value
    }
    public get dcCloudInterval(): number {
        return this._dcCloudInterval
    }
    public set dcCloudInterval(value: number) {
        this._dcCloudInterval = value
    }


    removeHash(str: string): string {
        let newStr = str.substring(1, str.length)
        return newStr
    }
}
