import {
	Component,
	ViewEncapsulation,
	ChangeDetectorRef,
	Input,
	ViewChild,
	ChangeDetectionStrategy
} from '@angular/core';

import { UILayout } from '@coreui/services//ui.layout.service'
import { EventsService } from '@coreui/services/event.service'
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { TypographyModel } from '@coreui/services/typography.service';


@Component({
	selector: 		'blog-page-header',
	changeDetection: ChangeDetectionStrategy.Default,
	inputs: ['heading'],

	template: 	`
	<div class="_blog-page-header">
		<div class="heading" onSurface heading-display-font-family>{{_pageHeading}}</div>
		<div class="page-author-wrapper">
			<div class="circle-wrapper">
				<div class="circle" tertiaryColor></div>
			</div>
			<div class="content">
				<div class="top-line">Giuliano Silvestro</div>
				<div class="bottom-line">Sky-AI articles - August 28, 2023</div>
			</div>
		</div>
	</div>

		`,
})


export class BlogPageHeaderComponent {
	@Input()
	set heading(value: string) {
	  this.setHeading(value);
	}
	private changeDetectorRef: ChangeDetectorRef
	private uiLayout: UILayout
	private uiNavigation: UINavigation
    private eventsService: EventsService
	private typographyModel: TypographyModel

	_pageHeading: string = ""

	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService, 
		private _uiLayout: UILayout,
		private _uiNavigation: UINavigation,
		private _typographyModel: TypographyModel) {
		this.uiLayout = _uiLayout
		this.eventsService = _eventsService
		this.uiNavigation = _uiNavigation
		this.typographyModel = _typographyModel
		this.changeDetectorRef = ref
	}

	setHeading(value: string) {
		this._pageHeading = value
	}

	ngOnInit() {
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
