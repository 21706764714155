import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';

import { ApplicationService } from '../services/application.service';
import { EventsService } from '@coreui/services/event.service'
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { UILayout } from '@coreui//services/ui.layout.service';

@Component({
	selector: 		'backgrounds',
	encapsulation: ViewEncapsulation.None,

	template:	`

			<div [ngSwitch]=this.applicationService.activeBackground>

				<div *ngSwitchCase="'clouds'">
					<background-dynamicclouds 
					[backgroundcolor]=this.applicationService.themes.dcBackground [color1]=this.applicationService.themes.dcCloudBackground1 
					[color2]=this.applicationService.themes.dcCloudBackground2 
					[cloudopacity]=this.applicationService.themes.dcCloudOpacity 
					[numclouds]=this.applicationService.themes.dcCloudNumber 
					[speed]=this.applicationService.themes.dcCloudSpeed 
					[interval]=this.applicationService.themes.dcCloudInterval></background-dynamicclouds>
				</div>


				<div *ngSwitchCase="'sunrise'">
					<background-sunrise [state]=sunriseState></background-sunrise>
				</div>
	

			</div>

	`,

})


export class Backgrounds {

	private eventsService: EventsService
	applicationService: ApplicationService
	uiNavigation: UINavigation
	uiLayout: UILayout

	sunriseState: string = "reduce"

	constructor(_eventsService: EventsService, 
			private _applicationService: ApplicationService,
			private _uiLayout: UILayout,
			private _uiNavigation: UINavigation) {
		this.eventsService = _eventsService
		this.applicationService = _applicationService
		this.uiNavigation = _uiNavigation
		this.uiLayout = _uiLayout
/*
		this.peachyCloudsSettings()
		let self = this;
		this.eventsService.on('onRefresh', function (args: any) {
			if(self.applicationService.activeBackground == "peachy") {
				self.peachyCloudsSettings()
			}
			if(self.applicationService.activeBackground == "peachy-dark") {
				self.peachyDarkSettings()
			}

		});
		////////////////////////////////////////////////////////////////
		// Good Morning - Sunrise Mode - Sample App
		////////////////////////////////////////////////////////////////
		this.eventsService.on('sunriseExpand', function (args: any) {
			self.sunriseState = "expand"
		});
		this.eventsService.on('sunriseReduce', function (args: any) {
			self.sunriseState = "reduce"
		});
*/
	}

	ngOnInit() {
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {

	}

/*
	// Background
	color1: string = "e96625"
	color2: string = "ef2832"
	background: string = "fefefe"
	speed: number = 5000
	interval: number = 5000
	cloudOpacity: number = 0.1
	numClouds: number = 1


	peachyCloudsSettings() {
		let rec: any = this.applicationService.peachyBackground()
		this.color1 = rec["color1"]
		this.color2 = rec["color2"]
		this.background = rec["backgroundcolor"]
		this.speed = rec["speed"]
		this.interval = rec["interval"]
		this.cloudOpacity = rec["cloudopacity"]
		this.numClouds = rec["numclouds"]
	}

	peachyDarkSettings() {
		let rec: any = this.applicationService.peachyNightBackground()
		this.color1 = rec["color1"]
		this.color2 = rec["color2"]
		this.background = rec["backgroundcolor"]
		this.speed = rec["speed"]
		this.interval = rec["interval"]
		this.cloudOpacity = rec["cloudopacity"]
		this.numClouds = rec["numclouds"]
	}
*/

}
