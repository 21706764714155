
export class NavigationBarSettings {

    private _id: string = ""
    private _hasFab: boolean = true
    private _fabIcon: string = ""
    private _destinations: any = []
    
    constructor() {}

    public addDestination(id: string, label: string, inactiveicon: string, activeicon: string) {
        let rec = {
            id: id,
            label: label,
            icon: inactiveicon,
            activeIcon: activeicon,
            inactiveIcon: inactiveicon,
            isEnabled: true,
            isHovered: false,
            isActive: false,
            badgeValue: 0,
            smallBadge: false
        }
        this._destinations.push(rec)
    }



    public setActive(id: string, isActive: boolean) {
        let i = 0
        for(i=0;i<this._destinations.length;i++) {
            if(this._destinations[i]["id"] == id) {
                this._destinations[i]["isActive"] = isActive
                this._destinations[i]["icon"] = this._destinations[i]["activeIcon"]

            } else {
                this._destinations[i]["isActive"] = false
                this._destinations[i]["icon"] = this._destinations[i]["inactiveIcon"]
            }
        }
    }


    public isActive(id: string): boolean {
        let result: boolean = false
        let i = 0
        for(i=0;i<this._destinations.length;i++) {
            if(this._destinations[i]["id"] == id) {
                result = this._destinations[i]["isActive"]
            } 
        }
        return result
    }


    public setEnabled(id: string, isEnabled: boolean) {
        let i = 0
        for(i=0;i<this._destinations.length;i++) {
            if(this._destinations[i]["id"] == id) {
                this._destinations[i]["isEnabled"] = isEnabled
            } else {
                this._destinations[i]["isEnabled"] = false
            }
        }
    }
    public isEnabled(id: string): boolean {
        let result: boolean = false
        let i = 0
        for(i=0;i<this._destinations.length;i++) {
            if(this._destinations[i]["id"] == id) {
                result = this._destinations[i]["isEnabled"]
            } 
        }
        return result
    }



    public setIcon(id: string, icon: string) {
        let i = 0
        for(i=0;i<this._destinations.length;i++) {
            if(this._destinations[i]["id"] == id) {
                this._destinations[i]["icon"] = icon
            }
        }
    }
    public setLabel(id: string, label: string) {
        let i = 0
        for(i=0;i<this._destinations.length;i++) {
            if(this._destinations[i]["id"] == id) {
                this._destinations[i]["label"] = label
            }
        }
    }


    public setBadgeValue(id: string, value: number) {
        let i = 0
        for(i=0;i<this._destinations.length;i++) {
            if(this._destinations[i]["id"] == id) {
                this._destinations[i]["badgeValue"] = value
            }
        }
    }

    public setSmallBadge(value: boolean) {
        let i = 0
        for(i=0;i<this._destinations.length;i++) {
            this._destinations[i]["smallBadge"] = value
        }
    }


    public get id(): string {
        return this._id
    }
    public set id(value: string) {
        this._id = value
    }

    public get hasFab(): boolean {
        return this._hasFab
    }
    public set hasFab(value: boolean) {
        this._hasFab = value
    }
    public get fabIcon(): string {
        return this._fabIcon
    }
    public set fabIcon(value: string) {
        this._fabIcon = value
    }
    public get destinations(): any {
        return this._destinations
    }
    public set destinations(value: any) {
        this._destinations = value
    }

}