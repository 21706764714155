import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	ViewChild,
	EventEmitter,
	ElementRef,
	ChangeDetectionStrategy
} from '@angular/core';

import { FormControl, Validators,  ReactiveFormsModule } from '@angular/forms';


@Component({
	selector: 		'search-bar-container',
	inputs: ['placeholder', 'leadingicon', 'trailingicon'],
	outputs: ['value', 'clicked'],
	changeDetection: ChangeDetectionStrategy.Default,


	template: 	`

			<div class="_search-bar {{_mode}}" surfaceContainerHigh>
				<div class="leading-icon" (click)=leadingIconClicked()>
					<i class="mdi mdi-{{_leadingIcon}}" onSurface></i>
				</div>
				<div class="input-field-wrapper">
					<input #searchbar class="input-field" placeholder={{_placeholder}} 
					(keyup)="onKey(searchbar.value)" onSurface font-family/>
				</div>
				<div class="trailing-icon-wrapper" (click)=trailingIconClicked()>
					<div class="item">
						<i class="mdi mdi-{{_trailingIcon}}" onSurface></i>
					</div>
				</div>	
				<div class="container" 
					[style.maxHeight.px]=_searchContainerHeight
					elevated [elevation]=1
					surfaceContainerHigh>
					<div class="divider" scrimColor></div>
					<ng-content></ng-content>
				</div>
			</div>

		
				
								`,

})



export class SearchBarContainerComponent {
	@ViewChild('searchbar')
	searchbar!: ElementRef;

	@Output()
	value = new EventEmitter();
	clicked = new EventEmitter();

	@Input()
	set placeholder(value: string) { this.setPlaceholder(value); }
	set leadingicon(value: string) { this.setLeadingIcon(value); }
	set trailingicon(value: string) { this.setTrailingIcon(value); }


	changeDetectorRef: ChangeDetectorRef;
	_mode: string = ""
	_searchContainerHeight: number = 0
	_placeholder: string = ""
	_leadingIcon: string = "magnify"
	_trailingIcon: string = ""


	constructor(public ref: ChangeDetectorRef) {
		this.changeDetectorRef = ref
	}


	onKey(e: any) {
		if(this.searchbar.nativeElement.value == "") {
			this._mode = ""
			this._searchContainerHeight = 0
		} else {
			this._mode = "container-mode"
			let height34 = window.innerHeight * 0.65
			this._searchContainerHeight = height34
		}


		this.value.emit(e);
	}


	setPlaceholder(val: string) {
		this._placeholder = val;
	}


	setLeadingIcon(value: string) {
		this._trailingIcon = value 
	}


	setTrailingIcon(value: string) {
		this._trailingIcon = value
	}


	ngOnInit() {
	}


	ngOnDestroy() {
	}

	leadingIconClicked() {
		this.clicked.emit("leading-icon")
	}

	trailingIconClicked() {
		if(this._trailingIcon == "close") {
			this.searchbar.nativeElement.value = ""
			//this.value.emit("return-an-empty-search-value");
		}
		this.clicked.emit("trailing-icon")
	}

}
