import {
  Component,
  ViewEncapsulation,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Input,
} from "@angular/core";

import { EventsService } from "../../../services/event.service";
import { UIColorModel } from "../../../services/ui.color.model.service";
import { UILayout } from "../../../services/ui.layout.service";

@Component({
  selector: "transformable-card",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  inputs: ["width", "height", "marginright"],
  template: `
    <li
      class="grid-element"
      style="width: {{ _widthString }}"
      [style.marginRight.px]=_marginRight
    >
      <div class="_card" surfaceContainerLow elevated [elevation]="1">
        <div [ngSwitch]="this.displayMode">
          <div *ngSwitchCase="'compact'">
            <ng-content select="[compact]"></ng-content>
          </div>

          <div *ngSwitchCase="'medium'">
            <ng-content select="[medium]"></ng-content>
          </div>

          <div *ngSwitchCase="'expanded'">
            <ng-content select="[expanded]"></ng-content>
          </div>
        </div>
      </div>
    </li>
  `,
})
export class TransformableCardContainer {
  @Input()
  set width(value: number) {
    this.setWidth(value);
  }
  set height(value: number) {
    this.setHeight(value);
  }
  set marginright(value: number) {
    this.setMarginRight(value);
  }

  private eventsService: EventsService;
  private changeDetectorRef: ChangeDetectorRef;
  uiColorModel: UIColorModel;
  uiLayout: UILayout;

  displayMode: string = "card";
  _width: number = 100;
  _height: number = 100;
  _marginRight: number = 8;
  _widthString: string = "100px";

  constructor(
    public ref: ChangeDetectorRef,
    _eventsService: EventsService,
    private _uiLayout: UILayout,
    private _uiColorModel: UIColorModel
  ) {
    this.eventsService = _eventsService;
    this.uiColorModel = _uiColorModel;
    this.uiLayout = _uiLayout;
    this.changeDetectorRef = ref;

    let self = this;
    this.eventsService.on("onResize", function (args: any) {
      self.detectMode();
    });
  }

  setWidth(value: number) {
    this._width = value;
  }
  setHeight(value: number) {
    this._height = value;
  }
  setMarginRight(value: number) {
    this._marginRight = value;
  }

  detectMode() {
    if (this.uiLayout.windowClass == "compact") {
      this.displayMode = "compact";
      this._widthString = "100%";
    } 
    if (this.uiLayout.windowClass == "medium" || this.uiLayout.windowClass == "expanded") {
      this.displayMode = "medium";
      this._widthString = this._width + "px";
    } 
    if (this.uiLayout.windowClass == "expanded") {
//      this.displayMode = "expanded";
 //     this._widthString = this._width + "px";
    } 

  }

  ngOnInit() {
    this.detectMode();
  }

  ngOnDestroy() {}

  ngAfterViewInit() {}
}
