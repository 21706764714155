import { ColorUtils } from '@coreui/style/color/utils/color.utils.class';
import { ColorObject } from './color.class';


export class GradientCollection {


  private id:                 string
  private name:               string
  private description:        string = ""
  private gradients:          any[] = []

  constructor(_id: string) {
    this.id = _id
    this.name = _id
  }


  public getName(): string { return this.name }
  public setName(value: string): void { this.name = value }
  public getDescription(): string { return this.description }
  public setDescription(value: string): void { this.description = value }
  public getGradients(): any[] { return this.gradients }


  public addGradient(name: string, gradientString: string): void {
    if(this.gradientExists(name) == false) {
      let obj = this.parseGradientString(name, gradientString)
      this.gradients.push(obj)
    }
  }


  public gradientExists(name: string): boolean {
    let exists = false
    let i = 0
    for(i=0;i<this.gradients.length;i++) {
      if(this.gradients[i].name == name) {
        exists = true
      }
    }
    return exists
  }


  private parseGradientString(name: string, gString: string): any {
    let colors: any[] = [];

    let s = gString.split(",");
    let angle = s[0];
    let idx1 = angle.indexOf("(");
    angle = angle.substr(idx1 + 1, angle.length);
    idx1 = angle.indexOf("deg");
    angle = angle.substr(0, idx1);

    let i = 1;
    for(i=1;i<s.length;i++) {
      let colstr = s[i].split(" ");
      let hex: string = colstr[1];
      hex = hex.substr(1, hex.length);
      let perc: string = colstr[2];
      if(perc.endsWith(")")) {
        perc = perc.substr(0, perc.length-2);
      } else {
        perc = perc.substr(0, perc.length-1)
      }

      let utils = new ColorUtils()
      let rgbValue = utils.hexToRgb(hex);
      let rgbStr = "rgba(" + rgbValue.r + "," + rgbValue.g + "," + rgbValue.b + "," + "1" + ")";

      let colrec = {
        hex:        "",
        perc:       ""
      }
      colrec['hex'] = hex
      colrec['perc'] = perc
      colors.push(colrec)
    }

    let valueStringRGB = "(" + angle + "deg";
    for(i=0;i<colors.length;i++) {
      valueStringRGB = valueStringRGB + ", " + colors[i]['rgbstring'] + " " + colors[i]['perc'] + "%";
    }
    valueStringRGB = valueStringRGB + ")";

    let valueStringHex = "(" + angle + "deg";
    for(i=0;i<colors.length;i++) {
      valueStringHex = valueStringHex + ", #" + colors[i]['hex'] + " " + colors[i]['perc'] + "%";
    }
    valueStringHex = valueStringHex + ")";

    let id_str = name.replace(" ", "_")
    id_str = id_str.toLowerCase()

    let rec = {
      id:     id_str,
      name:   name,
      type:   "linear",
      angle:  angle,
      colors: colors,
      rgbstring: "-webkit-linear-gradient" + valueStringRGB,
      hexstring: "-webkit-linear-gradient" + valueStringHex
    }

    return rec;
  }


}
