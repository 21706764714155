import {Injectable} from '@angular/core'
import { HttpClient } from  '@angular/common/http'
import { Observable, Subject, ReplaySubject, from, of, range, timer, interval  } from 'rxjs'
import {takeWhile, take } from "rxjs/operators"
import { map, filter, switchMap } from 'rxjs/operators'


import { UINavigation } from '@coreui/services/ui.navigation.service';
import { UIColorModel } from '@coreui/services/ui.color.model.service';
import { UILayout } from '@coreui/services//ui.layout.service'
import { TypographyModel } from '@coreui/services/typography.service';

import { NavigationBarSettings } from '@coreui/classes/navigation/navigation.bar.class'
import { NavigationRailSettings } from '@coreui/classes/navigation/navigation.rail.class'
import { NavigationDrawerSettings } from '@coreui/classes/navigation/navigation.drawer.class'
import { NavigationDrawerMenu } from '@coreui/classes/navigation/navigation.drawer.menu.class'

                          

@Injectable()
export class ApplicationService  {

  private uiNavigation: UINavigation
  private uiColorModel: UIColorModel
  private uiLayout: UILayout
	private typographyModel: TypographyModel
	private httpClient: HttpClient


  constructor(_http: HttpClient,
    private _uiNavigation: UINavigation, 
    private _uiColorModel: UIColorModel,
    private _uiLayout: UILayout,
    private _typographyModel: TypographyModel) {

    this.httpClient = _http
    this.uiNavigation = _uiNavigation
    this.uiColorModel = _uiColorModel
    this.uiLayout = _uiLayout
    this.typographyModel = _typographyModel

    this.settings()

  }

  darkMode() {
    this.uiColorModel.darkScheme()
  }
  lightMode() {
    this.uiColorModel.lightScheme()
  }



  private settings() {

    this.typographyModel.uiFontFamily = "Rubik Regular"
    this.typographyModel.displayFontFamily = "Alegreya Regular"
//F17E27
    // 088a58
    this.uiColorModel.generateM3ColorScheme("f03c3c", "dc3b2a", "fee715" , "605D62", "605D66", "b3261e")
    this.uiColorModel.lightScheme()

    this.uiLayout.applicationContainerSettings().compactNavigationComponent = "top"
    this.uiLayout.applicationContainerSettings().mediumNavigationComponent = "top"
    this.uiLayout.applicationContainerSettings().expandedNavigationComponent = "top"
		this.uiLayout.showInnerHeader = false



    
  }


}



