///////////////////////////////////////////////////////////////////////
// Angular modules
///////////////////////////////////////////////////////////////////////
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

///////////////////////////////////////////////////////////////////////
// 3rd Party Modules - Imports
///////////////////////////////////////////////////////////////////////
import { CoreUIModule } from '@coreui/coreui.module';
import { BlogThemeModule } from '@libraries/themes/blog-theme/blog.theme.module';

//import { QRCodesModule } from '@libraries/qr-codes/qrcodes.module';
import { QRCodeModule } from 'angularx-qrcode';


///////////////////////////////////////////////////////////////////////
// SERVICES
///////////////////////////////////////////////////////////////////////
import { ApplicationService } from './services/application.service';


///////////////////////////////////////////////////////////////////////
// Container
///////////////////////////////////////////////////////////////////////
import { Main } from './main/main';
import { MainRoutes } from './main/main.routes';
import { Modals } from './main/modals';
import { RightSliders } from './main/sliders';



import { AppbarRoutes } from './components/appbar/appbar.routes';
/* Appbar - medium size */
import { AppbarMediumComponent } from './components/appbar/appbar.medium.component';
/* Appbar - compact size */
import { AppbarCompactComponent } from './components/appbar/appbar.compact.component';

/* Sidenav Drawer */
import { SidenavDrawerComponent } from './components/sidenav/sidenav.component';
import { SidenavMenuItemComponent } from './components/sidenav/sidenav.menu.item.component';


import { ScrollTopButtonComponent } from './components/scrolltop-button/scrolltop.button.component';

///////////////////////////////////////////////////////////////////////
// CONTENT
///////////////////////////////////////////////////////////////////////
import { HomeScreen } from './content/home/home.screen';
import { WhoWeAreScreen } from './content/whoweare/whoweare.screen';


@NgModule({
  declarations: [
    Main,
    MainRoutes,
    Modals,
    RightSliders,
    AppbarRoutes,
    AppbarMediumComponent,
    AppbarCompactComponent,
    SidenavDrawerComponent,
    SidenavMenuItemComponent,
    ScrollTopButtonComponent,
    HomeScreen,
    WhoWeAreScreen

  ],

  imports: [
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    QRCodeModule,
    CoreUIModule,
    BlogThemeModule
  ],


  providers: [
    ApplicationService
  ],

  exports: [
    Main,
    MainRoutes,
    Modals,
    RightSliders,
    AppbarRoutes,
    AppbarMediumComponent,
    AppbarCompactComponent,
    SidenavDrawerComponent,
    SidenavMenuItemComponent,
    ScrollTopButtonComponent,
    HomeScreen,
    WhoWeAreScreen
  ]
})
export class SpiritansModule { }
