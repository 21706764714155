import { HttpClient,  HttpResponse, HttpHeaders, HttpEvent, HttpErrorResponse, HttpEventType, HttpRequest} from  '@angular/common/http';
import { Observable, Subject, ReplaySubject, from, of, range, timer, interval  } from 'rxjs';
import { takeWhile, take } from "rxjs/operators";
import { map, filter, switchMap } from 'rxjs/operators';


export class SkyAISessionService {

  private http: HttpClient;

  private _apiURL: string = "";
  private _apikey: string = "";
  private _is_signed_in: boolean = false;
  private _applicationid: string = "";

  private _sessionid: string = "";
  private _session_start_ts: number = 0;
  private _session_expiry_interval: number = 0;
  private _session_expiry: number = 0;



  private _ipaddress: string = "";
  private _geocountry: string = "";
  private _countrycode: string = "";
  private _geocity: string = "";
  private _continentcode: string = "";
  private _geocurrency: string = "";
  private _geotimezone: string = "";


  private _devicetype: string = "";
  private _devicevendor: string = "";
  private _devicemodel: string = "";
  private _osname: string = "";
  private _osversion: string = "";
  private _browsername: string = "";





  private querydata:                any = [];
  private subjectid:                string = "";
  private refid:                    string = "";



  private visitlog_endpoint:   string = "/auth/visitlog";

  private register_endpoint:   string = "/auth/register";
  private register_verification_endpoint:   string = "/auth/register/verify";
  private signin_endpoint:   string = "/auth/session";
  private signout_endpoint:   string = "/auth/session";
  private keep_alive_endpoint:   string = "/auth/session/keepalive";

  private session_status_endpoint:   string = "/auth/session/status";

  private forgot_password_endpoint:   string = "/auth/forgotpassword";
  private forgot_password_validate_endpoint:   string = "/auth/forgotpassword/validate";
  private reset_forgot_password_endpoint:   string = "/auth/forgotpassword/reset";
  private change_password_endpoint:   string = "/auth/changepassword";
  private terminate_account_endpoint:   string = "/auth/terminate_account";


  constructor(_http: HttpClient) {
    this.http = _http;
  }

  init(apiurl: string, apikey: string) {
    this.apiURL = apiurl;
    this.apikey = apikey;
  }



  sessionRegisterSuccessful(rec: any) {
    this._sessionid = rec["sessionid"]
    this._is_signed_in = true
    this._ipaddress = rec["ip_address"]
    this._geocity = rec["geo_city"]
    this._geocountry = rec["geo_country"]
    this._countrycode = rec["geo_country_code2"] 
    this._continentcode = rec["OC"] 
    let now = Date.now();
    this._session_start_ts = now
  }

  reconnectSession(sessionid: string) {
    this._sessionid = sessionid
    this._is_signed_in = true
    let now = Date.now();
    this._session_start_ts = now
  }




  visitlog(appid: string): Observable<any> {
    let dat = {
      appid:     appid
    };
    let body = JSON.stringify(dat);
    return this.http.post(this.apiURL + this.visitlog_endpoint, body, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response'
    })
  }


  register(userid: string, pw: string, firstname: string, lastname: string): Observable<any> {
    let dat = {
      apikey:     this.apikey,
      userid:      userid,
      password:    pw,
      firstname:  firstname,
      lastname:   lastname
    };
    let body = JSON.stringify(dat);
    return this.http.post(this.apiURL + this.register_endpoint, body, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response'
    })
  }

  register_verification(userid: string, code: string): Observable<any> {
    let dat = {
      apikey:     this.apikey,
      userid:     userid,
      code:       code
    };
    let body = JSON.stringify(dat);
    return this.http.post(this.apiURL + this.register_verification_endpoint, body, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response'
    })
  }


  signin(userid: string, pw: string): Observable<any> {
    let dat = {
      apikey:      this.apikey,
      userid:      userid,
      password:    pw
    };
    let body = JSON.stringify(dat);
    return this.http.post(this.apiURL + this.signin_endpoint, body, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response'
    })
  }

  signout(sid: string): Observable<any> {
    let url = this.apiURL + this.signout_endpoint + "?id=" + sid
    return this.http.delete(url, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response'
    })
  }


  session_status(sid: string): Observable<any> {
    let dat = {
      id:      sid
    };
    let body = JSON.stringify(dat);
    return this.http.post(this.apiURL + this.session_status_endpoint, body, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response'
    })
  }



  keep_alive(sid: string): Observable<any> {
    let dat = {
      id:      sid,
    };
    let body = JSON.stringify(dat);
    return this.http.post(this.apiURL + this.keep_alive_endpoint, body, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response'
    })
  }


  forgot_password(userid: string): Observable<any> {
    let dat = {
      apikey:     this.apikey,
      userid:      userid
    };
    let body = JSON.stringify(dat);
    return this.http.post(this.apiURL + this.forgot_password_endpoint, body, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response'
    })
  }

  forgot_password_validate_code(userid: string, code: string): Observable<any> {
    let dat = {
      apikey:     this.apikey,
      userid:     userid,
      code:       code
    };
    let body = JSON.stringify(dat);
    return this.http.post(this.apiURL + this.forgot_password_validate_endpoint, body, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response'
    })
  }

  reset_forgot_password(userid: string, code: string, password: string): Observable<any> {
    let dat = {
      apikey:     this.apikey,
      userid:     userid,
      code:       code,
      password:   password
    };
    let body = JSON.stringify(dat);
    return this.http.post(this.apiURL + this.reset_forgot_password_endpoint, body, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response'
    })
  }


  change_password(sid: string, oldpassword: string, newpassword: string): Observable<any> {
    let dat = {
      sid:      sid,
      oldpassword:    oldpassword,
      newpassword:    newpassword

    };
    let body = JSON.stringify(dat);
    return this.http.post(this.apiURL + this.change_password_endpoint, body, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response'
    })
  }

  terminate_account(sid: string): Observable<any> {
    let dat = {
      sid:      sid,
    };
    let body = JSON.stringify(dat);
    return this.http.delete(this.apiURL + this.terminate_account_endpoint, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response'
    })
  }


  public get apiURL(): string {
    return this._apiURL;
  }
  public set apiURL(value: string) {
    this._apiURL = value;
  }
  public get apikey(): string {
    return this._apikey;
  }
  public set apikey(value: string) {
    this._apikey = value;
  }
  public get is_signed_in(): boolean {
    return this._is_signed_in;
  }
  public set is_signed_in(value: boolean) {
    this._is_signed_in = value;
  }
  public get applicationid(): string {
    return this._applicationid;
  }
  public set applicationid(value: string) {
    this._applicationid = value;
  }
  public get sessionid(): string {
    return this._sessionid;
  }
  public set sessionid(value: string) {
    this._sessionid = value;
  }
  public get session_start_ts(): number {
    return this._session_start_ts;
  }
  public set session_start_ts(value: number) {
    this._session_start_ts = value;
  }
  public get session_expiry_interval(): number {
    return this._session_expiry_interval;
  }
  public set session_expiry_interval(value: number) {
    this._session_expiry_interval = value;
  }
  public get session_expiry(): number {
    return this._session_expiry;
  }
  public set session_expiry(value: number) {
    this._session_expiry = value;
  }
  public get ipaddress(): string {
    return this._ipaddress;
  }
  public set ipaddress(value: string) {
    this._ipaddress = value;
  }
  public get geocountry(): string {
    return this._geocountry;
  }
  public set geocountry(value: string) {
    this._geocountry = value;
  }
  public get countrycode(): string {
    return this._countrycode;
  }
  public set countrycode(value: string) {
    this._countrycode = value;
  }
  public get geocity(): string {
    return this._geocity;
  }
  public set geocity(value: string) {
    this._geocity = value;
  }
  public get continentcode(): string {
    return this._continentcode;
  }
  public set continentcode(value: string) {
    this._continentcode = value;
  }
  public get geocurrency(): string {
    return this._geocurrency;
  }
  public set geocurrency(value: string) {
    this._geocurrency = value;
  }
  public get geotimezone(): string {
    return this._geotimezone;
  }
  public set geotimezone(value: string) {
    this._geotimezone = value;
  }

  public get devicetype(): string {
    return this._devicetype;
  }
  public set devicetype(value: string) {
    this._devicetype = value;
  }
  public get devicevendor(): string {
    return this._devicevendor;
  }
  public set devicevendor(value: string) {
    this._devicevendor = value;
  }
  public get devicemodel(): string {
    return this._devicemodel;
  }
  public set devicemodel(value: string) {
    this._devicemodel = value;
  }
  public get osname(): string {
    return this._osname;
  }
  public set osname(value: string) {
    this._osname = value;
  }
  public get osversion(): string {
    return this._osversion;
  }
  public set osversion(value: string) {
    this._osversion = value;
  }
  public get browsername(): string {
    return this._browsername;
  }
  public set browsername(value: string) {
    this._browsername = value;
  }

}
