import {
	Component,
	ViewEncapsulation,
	ViewChild,
	ElementRef,
	HostListener,
	ChangeDetectorRef,
	Input
} from '@angular/core';

import { EventsService } from '../../services/event.service'
import { UILayout } from '../../services/ui.layout.service'

@Component({
	selector: 		'application-container',
	encapsulation: ViewEncapsulation.None,
	template:	`
		<div class="_application-layout-container" backgroundColor
			[style.height.px]=_windowHeight>

			<div class="_background-layer">
				<ng-content select="[background-layer]"></ng-content>
			</div>

			<div class="_top" [style.flexBasis.px]=_topHeight>
				<ng-content select="[top]"></ng-content>
			</div>

			<div class="_centre-wrapper" [style.height.px]=_centreWrapperHeight>
		
				<div class="left-navigation {{_leftNavigationCSS}}">
					<ng-content select="[left-navigation]"></ng-content>
				</div>

				<div class="main-body">

					<div class="_application-body">

						<div class="_inner-header {{showInnerHeader()}}">
							<ng-content select="[inner-header]"></ng-content>
						</div>

						<div class="_body-container">
							<div class="_main-body">
								<ng-content select="[body]"></ng-content>
							</div>
							<div class="_right-slider-sheet {{showRightSliderSheet()}}"
								[style.height.px]=_rightSliderSheetHeight>
								<ng-content select="[right-slider-sheet]"></ng-content>
							</div>
						</div>
	
					</div>	
				
				</div>
				
			
			</div>

			<div class="_bottom" [style.flexBasis.px]=_bottomHeight>
				<ng-content select="[bottom]"></ng-content>
			</div>

		</div> 

	`,

})


export class ApplicationContainer {

	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		this._windowHeight = window.innerHeight
		let width = window.innerWidth
		this.uiLayout.detectDeviceWindowClass(width)
		this.uiLayout.detectDeviceWindowMargins()
		this.detectLayoutConfiguration(width)
		this.eventsService.broadcast("onResize", {})
		this.changeDetectorRef.detectChanges()
	}

	@HostListener('document:click', ['$event']) onDocumentClick($event: any) {
		this.eventsService.broadcast("onDocumentClick", 
			{screenX: $event.screenX,
			 screenY: $event.screenY,
			 clientX: $event.clientX,
			 clientY: $event.clientY}
		)
	  }

	private changeDetectorRef: ChangeDetectorRef
    private eventsService: EventsService
	uiLayout: UILayout

	_topHeight: number = 0
	_bottomHeight: number = 0
	_leftWidth: number = 0
	_leftNavigationCSS: string = ""

	_innerHeaderCSS: string = "hide"
	_rightSliderCSS: string = "hide"
	_rightSliderSheetHeight: number = 100

	_windowHeight: number = 100
	_centreWrapperHeight: number = 100

	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService, private _uiLayout: UILayout) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiLayout = _uiLayout
		let width = window.innerWidth
		this.uiLayout.detectDeviceWindowClass(width)
		this.uiLayout.detectDeviceWindowMargins()
		this.detectLayoutConfiguration(width)

		let self = this;
		this.eventsService.on('onRefresh', function (args: any) {
			let width = window.innerWidth
			self.uiLayout.detectDeviceWindowClass(width)
			self.uiLayout.detectDeviceWindowMargins()
			self.detectLayoutConfiguration(width)
			self.calculateCentreWrapperHeight()
			self.changeDetectorRef.markForCheck()
		});
	}

	ngOnInit() {
		let width = window.innerWidth
		this.uiLayout.detectDeviceWindowClass(width)
		this.uiLayout.detectDeviceWindowMargins()
		this.detectLayoutConfiguration(width)
		this.calculateCentreWrapperHeight()
		this.changeDetectorRef.detectChanges()	
	}

	ngOnDestroy() {
	}


	detectLayoutConfiguration(width: number) {
		if(this.uiLayout.windowClass == "compact") {
			this.compactConfiguration()
		}
		if(this.uiLayout.windowClass == "medium") {
			this.mediumConfiguration()
		}
		if(this.uiLayout.windowClass == "expanded") {
			this.expandedConfiguration()
		}
	}


	hideLeftNav() {
		this._leftNavigationCSS = "hide"
	}
	leftNavigatonRail() {
		this._leftNavigationCSS = "rail"
	}
	leftNavigatonExpanded() {
		this._leftNavigationCSS = "full"
	}

	hideRightNav() {
		this._leftNavigationCSS = "hide"
	}
	rightNavigatonExpanded() {
		this._leftNavigationCSS = "show"
	}


	hideNavigationTop() {
		this._topHeight = 0
	}
	showNavigationTop() {
		this._topHeight = 72
	}


	hideNavigationFooter() {
		this._bottomHeight = 0
	}
	showNavigationFooter() {
		this._bottomHeight = 80
	}




	hideRightSlider() {
		this._rightSliderCSS = "hide"
	}
	showRightSlider() {
		this._rightSliderCSS = "show"
	}


	showInnerHeader(): string {
		let css = ""
		if(this.uiLayout.showInnerHeader == true) {
			css = "show"
		} else {
			css = "hide"
		}
		return css
	}
	showRightSliderSheet(): string {
		let css = ""
		this.calculateRightSliderSheetHeight()
		if(this.uiLayout.showRightSliderSheet == true) {
			css = "show"
		} else {
			css = "hide"
		}
		return css
	}
	calculateRightSliderSheetHeight() {
		if(this.uiLayout.showInnerHeader == true) {
			this._rightSliderSheetHeight = window.innerHeight - 64
		} else {
			this._rightSliderSheetHeight = window.innerHeight
		}
	}


	compactConfiguration() {
		let config = this.uiLayout.applicationContainerSettings().compactNavigationComponent
		if(config == "none") {
			this.hideLeftNav()
			this.hideNavigationTop()
			this.hideNavigationFooter()
		}
		if(config == "top") {
			this.hideLeftNav()
			this.showNavigationTop()
			this.hideNavigationFooter()
		}
		if(config == "bottom") {
			this.hideLeftNav()
			this.hideNavigationTop()
			this.showNavigationFooter()
		}
		if(config == "both") {
			this.hideLeftNav()
			this.showNavigationTop()
			this.showNavigationFooter()
		}
	} 

	mediumConfiguration() {
		let config = this.uiLayout.applicationContainerSettings().mediumNavigationComponent
		this.hideNavigationFooter()

		if(config == "none") {
			this.hideLeftNav()
			this.hideNavigationTop()
			this.hideNavigationFooter()
		}
		if(config == "rail") {
			this.leftNavigatonRail()
			this.hideNavigationTop()
			this.hideNavigationFooter()
		}
		if(config == "top") {
			this.hideLeftNav()
			this.showNavigationTop()
			this.hideNavigationFooter()
		}
		
	} 

	expandedConfiguration() {
		let config = this.uiLayout.applicationContainerSettings().expandedNavigationComponent
		this.hideNavigationFooter()

		if(config == "none") {
			this.hideLeftNav()
			this.hideNavigationTop()
			this.hideNavigationFooter()
		}
		if(config == "rail") {
			this.leftNavigatonRail()
			this.hideNavigationTop()
			this.hideNavigationFooter()
		}
		if(config == "expanded") {
			this.leftNavigatonExpanded()
			this.hideNavigationTop()
			this.hideNavigationFooter()
		}
		if(config == "top") {
			this.hideLeftNav()
			this.showNavigationTop()
			this.hideNavigationFooter()
		}
	} 

	calculateCentreWrapperHeight() {
		let h = window.innerHeight
		this._windowHeight = h
		this._centreWrapperHeight = h - this._topHeight - this._bottomHeight
	}

}
