import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter,
	HostListener,
	ChangeDetectionStrategy
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'
import { UIColorModel } from '@coreui/services/ui.color.model.service';


@Component({
	selector: 		'web-appbar-w-search',
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
		<div class="_web-appbar-w-search-top {{_cssShadowClass}} {{_cssAnimationClass}}"
			[style.background]=this._surfaceColor borderBottomOutline>
			<div class="fill-remaining-space"></div>
	<!--		<div class="search-wrapper">
				<search-bar></search-bar>
			</div> -->
			<div class="action-items" onSurface>
				<div class="item">
					<badge-icon-button [icon]="'bell-outline'" [activeicon]="'bell'" [smallbadge]=false [value]=3></badge-icon-button>				
				</div>
				<div class="item">
					<icon-button [icon]="'cog-outline'" [activeicon]="'cog'"></icon-button>				
				</div>
				<div class="item">
					<icon-button [icon]="'weather-sunny'" [activeicon]="'weather-night'" (clicked)=lightDarkMode()></icon-button>				
				</div>
			</div>
			<div class="avatar-wrapper">
				<avatar [imgsrc]=getAvatarPic()></avatar>
			</div>
		</div> 

		`,
})


export class WebAppbarWSearchComponent {

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	uiColorModel: UIColorModel
	_surfaceColor: string = ""
	_cssShadowClass: string = ""
	_cssAnimationClass: string = ""

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel

		let self = this
		this.eventsService.on('onRefresh', function (args: any) {
			self._surfaceColor = self.uiColorModel.surface
		});
		this.eventsService.on('onScrollStart', function (args: any) {
			self._surfaceColor = self.uiColorModel.surfaceContainer
			self._cssShadowClass = "shadow-2 scale-2"
			self.changeDetectorRef.detectChanges()
		});
		this.eventsService.on('onScrollEnd', function (args: any) {
			self._surfaceColor = self.uiColorModel.surface
			self._cssShadowClass = "scale-1"
			self.changeDetectorRef.detectChanges()
		});
	  
	}


	lightDarkMode() {
		if(this.uiColorModel.isDark) {
			this.uiColorModel.lightScheme()
		} else {
			this.uiColorModel.darkScheme()
		}
		this.eventsService.onRefresh()
	}

	public getAvatarPic(): string {
		return "./assets/avatars/female-avatar-1.jpg"
	}

	ngOnInit() {
		this._surfaceColor = this.uiColorModel.surface
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
