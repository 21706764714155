import {
	Component,
	ViewEncapsulation,
	Input,
	ChangeDetectorRef,
	Inject,
	OnInit,
	ChangeDetectionStrategy
} from '@angular/core';

import { EventsService } from '../../services/event.service'
import { UIColorModel } from '../../services/ui.color.model.service';

@Component({
	selector: 		'anim-icon',
	inputs: ['icon', 'color', 'selectedcolor', 'selected', 'disabled', 'disabledcolor', 'direction', 'animated', 'changeeffect',
					'opacity', 'selectedopacity', 'disabledopacity', 'fast', 'css'],
	changeDetection: ChangeDetectionStrategy.Default,
	template: 	`
		<i style="display: inline-block;"
				[style.opacity]="_activeOpacity"
				[style.color]=_activeColor
					class="mdi mdi-{{_icon}} {{spinClass}} {{_cssClass}} color-transition-150 opacity-transition-150">
		</i>
	`,

})



export class AnimatedIconComponent implements OnInit {

	@Input()
	set color(value: string) { this.setColor(value); }
	set icon(value: string) { this.setIcon(value); }
	set direction(value: string) { this.setDirection(value); }
	set opacity(value: number) { this.setOpacity(value); }

	set selected(value: boolean) { this.setSelected(value); }
	set selectedcolor(value: string) { this.setSelectedColor(value); }
	set selectedopacity(value: number) { this.setSelectedOpacity(value); }

	set disabled(value: boolean) { this.setDisabled(value); }
	set disabledcolor(value: string) { this.setDisabledColor(value); }
	set disabledopacity(value: number) { this.setDisabledOpacity(value); }

	set animated(value: boolean) { this.setAnimated(value); }
	set changeeffect(value: string) { this.setChangeEffect(value); }

	set fast(value: boolean) { this.setFast(value); }

	set css(value: string) { this.setCSS(value); }



	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
		this._activeColor = this.uiColorModel.surface

		let self =this
		this.eventsService.on('onRefresh', function (args: any) {
			if(self._overrideColor)
				self._activeColor = self.uiColorModel.onSurface
		});
	}

	private eventsService: EventsService
	changeDetectorRef: ChangeDetectorRef;
	uiColorModel: UIColorModel
	_icon: string = "null";
	_activeColor: string = "#000000";
	_iconColor: string = "#000000";
	_selectedColor: string = "#000000";
	_disabledColor: string = "#000000";
	_activeOpacity: number = 1;
	_opacity: number = 1;
	_selectedOpacity: number = 1;
	_disabledOpacity: number = 0.5;
	_isDisabled: boolean = false;
	_isSelected: boolean = false;
	_isAnimated: boolean = true;

	_changeeffect: string = "spin";
	spinDirection: string = "clock";

	spinClass: string = ""

	_fast: boolean = false
	_spinSpeed: number = 350

	_cssClass: string = ""
	_overrideColor: boolean = false

	setColor(value: string) {
		if (value.startsWith('linear-gradient') || value.startsWith('rgb') || value.startsWith('#')) {
			this._iconColor = value;
			this._activeColor = value;
		  } else {
			this._iconColor = "#" + value;
			this._activeColor = "#" + value;
		}
		this._overrideColor = true	  
	}
	
	setDirection(value: string) {
		this.spinDirection = value;
	}

	setOpacity(value: number) {
		this._opacity = value;
		this._activeOpacity = this._opacity;
	}

	setSelected(value: boolean) {
		this._isSelected = value;
		if(this._isSelected == true) {
			this._activeColor = this._selectedColor;
			this._activeOpacity = this._selectedOpacity;
		} else {
			this._activeColor = this._iconColor;
			this._activeOpacity = this._opacity;
		}
	}

	setSelectedColor(value: string) {
		if(value.startsWith("rgb")) {
			this._selectedColor = value;
		} else {
			this._selectedColor = "#" + value;
		}
	}

	setSelectedOpacity(value: number) {
		this._selectedOpacity = value;
	}

	setDisabled(value: boolean) {
		this._isDisabled = value;
		if(this._isDisabled == true) {
			this._activeColor = this._disabledColor;
			this._activeOpacity = this._disabledOpacity;
		} else {
			this._activeColor = this._iconColor;
			this._activeOpacity = this._opacity;
		}
	}

	setDisabledColor(value: string) {
		this._disabledColor = "#" + value;
	}

	setDisabledOpacity(value: number) {
		this._disabledOpacity = value;
		this._activeOpacity = this._disabledOpacity;
	}

	setAnimated(value: boolean) {
		this._isAnimated = value;
	}

	setChangeEffect(value: string) {
		this._changeeffect = value;
	}

	getIcon() {
		return this._icon;
	}

	setIcon(value: string) {
		if(this._isAnimated == true) {
			if(this._icon == "null") {
				this._icon = value;
			} else {
				if(this._changeeffect == "spin") {
					this.spinIconChangeEffect(value);
				}
				if(this._changeeffect == "in-out") {
					this.inOutIconChangeEffect(value);
				}
			}
		} else {
			this._icon = value;
		}

	}

	spinIconChangeEffect(v: string) {
			if(this.spinDirection == "clock") {
			//	this.changeDetectorRef.detach()
				if(this._fast == true) {
					this.spinClass = "anim-spin-clockwise-fast";
				} else {
					this.spinClass = "anim-spin-clockwise";
				}
			//	this.changeDetectorRef.detectChanges();
			//	this.changeDetectorRef.detach()

				setTimeout(() => {
					this._icon = v;
					this.spinClass= "";
			//		this.changeDetectorRef.detectChanges();
				}, this._spinSpeed);

			} else {
				this.spinClass = "anim-spin-counterclockwise";
				this.changeDetectorRef.detectChanges();

				setTimeout(() => {
					this._icon = v;
					this.spinClass= "";
					this.changeDetectorRef.detectChanges();
				}, this._spinSpeed);
			}
	}

	setCSS(value: string) {
		this._cssClass = value;
	}


	inOutIconChangeEffect(v: string) {
		this.spinClass = "mdicon-in-out-effect";
		this.ref.detectChanges();
		setTimeout(() => {
			this._icon = v;
			this.spinClass= "";
			this.ref.detectChanges();
		}, 80);
	}

	setFast(value: boolean) {
		this._fast = value
		if(value == true) {
			this._spinSpeed = 150
		} else {
			this._spinSpeed = 350
		}
	}


	ngOnInit() {
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
