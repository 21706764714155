import {
	Component,
	ViewEncapsulation,
	ChangeDetectorRef,
	Input,
	ViewChild,
	ChangeDetectionStrategy
} from '@angular/core';

import { UILayout } from '@coreui/services//ui.layout.service'
import { EventsService } from '@coreui/services/event.service'
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { TypographyModel } from '@coreui/services/typography.service';


@Component({
	selector: 		'article-image',
	changeDetection: ChangeDetectionStrategy.Default,
	inputs: ['imgsrc', 'caption'],

	template: 	`
	<div class="_article-page-image-fullwidth">
		<img [src]=_imgsrc/>
		<div class="caption" onPrimaryContainer>{{_caption}}</div>
	</div>

		`,
})


export class BlogArticleImageComponent {
	@Input()
	set imgsrc(value: string) {
	  this._imgsrc = value
	}
	set caption(value: string) {
		this._caption = value
	  }
  
	private changeDetectorRef: ChangeDetectorRef
	private uiLayout: UILayout
	private uiNavigation: UINavigation
    private eventsService: EventsService
	private typographyModel: TypographyModel

	_imgsrc: string = ""
	_caption: string = ""

	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService, 
		private _uiLayout: UILayout,
		private _uiNavigation: UINavigation,
		private _typographyModel: TypographyModel) {
		this.uiLayout = _uiLayout
		this.eventsService = _eventsService
		this.uiNavigation = _uiNavigation
		this.typographyModel = _typographyModel
		this.changeDetectorRef = ref
	}

	ngOnInit() {
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}
