import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


/////////////////////////////////////////////////////////
// 3rd Party Libraries
////////////////////////////////////////////////////////
import { MarkdownModule } from 'ngx-markdown';


/////////////////////////////////////////////////////////
// Common Libraries
////////////////////////////////////////////////////////
import { CoreUIModule } from '@coreui/coreui.module'

import { DynamicCloudsBackgroundModule } from '@libraries/dynamic-clouds/dynamicclouds.module'
import { AnimatedTilesModule } from '@libraries/animated-tiles/animatedtiles.module'
import { CoreUIExtensionsModule } from '@libraries/coreui-extensions/coreui.extensions.module';
  
/////////////////////////////////////////////////////////
// Templates
////////////////////////////////////////////////////////

/* Landing */
import { LandingPage01Template } from './landing-page/landing.page01'

/* Blog */
import { BlogPageM3Template } from './blog/blog.m3'

/* Technical Document */
import { TechnicalDocumentPageTemplate } from './tech-documentation/technical.document.page'

/* Web appbars */
import { WebAppbarWSearchComponent } from './appbars/webapp-w-search/web.appbar.w.search.component'

/* SAAS */
import { SaaSPageContentContainer } from './saas/saas.content.container'
import { SaasCollectionsToolbarComponent } from './saas/collection-management-toolbar/saas.collections.toolbar.component'
import { SaasCollectionsActionsSlider } from './saas/collection-management-toolbar/sass.collections.actions.slider'


/* List Detail */
import { ListDetailPage } from './list-detail/list.detail.page'


/* Media, Images etc */
import { ImagesAnimCollection } from './media/images.collection'


import { AppUIPageHeading } from './applicationui/page-headers/appui.page.heading';



@NgModule({
  declarations: [
    LandingPage01Template,
    BlogPageM3Template,
    TechnicalDocumentPageTemplate,

    // Appbars
    WebAppbarWSearchComponent,

    SaaSPageContentContainer,
    SaasCollectionsToolbarComponent,
    SaasCollectionsActionsSlider,

    // List Detail
    ListDetailPage,

    ImagesAnimCollection,

    // App UI
    AppUIPageHeading
   
  ],

  imports: [
    CommonModule,
    MarkdownModule.forRoot(),
    CoreUIModule,
    DynamicCloudsBackgroundModule,
    AnimatedTilesModule,
    CoreUIExtensionsModule
  ],

  providers: [
 
  ],

  exports: [
    LandingPage01Template,
    BlogPageM3Template,
    TechnicalDocumentPageTemplate,

    // Appbars
    WebAppbarWSearchComponent,

    SaaSPageContentContainer,
    SaasCollectionsToolbarComponent,
    SaasCollectionsActionsSlider,
    
    // List Detail
    ListDetailPage,

    ImagesAnimCollection,

    // App UI
    AppUIPageHeading


  ]
})
export class LayoutTemplatesModule { }
