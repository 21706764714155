import {
	Component,
	ViewEncapsulation,
	HostListener,
	Input,
	ChangeDetectorRef
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'


@Component({
	selector: 'article',
	inputs: ['fontfamily'],
	encapsulation: ViewEncapsulation.None,
	template:	`
		<div class="article" onPrimaryContainer
			[style.lineHeight.em]=1.618
			display-font-family>
			<ng-content></ng-content>
		</div>
	`,

})


export class TypographyArticleComponent {
    @Input()
	  set fontfamily(value: string) { this.setFontFamily(value) }

	private changeDetectorRef: ChangeDetectorRef
    private eventsService: EventsService
	_font: string = "Comfortaa"

	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
	}

	ngOnInit() {
	}

	ngAfterViewInit() {
	}

	ngOnDestroy() {
	}

	setFontFamily(value: string) {

	}

}
