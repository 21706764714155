import { Directive, ElementRef, Input, ChangeDetectorRef } from '@angular/core';
import { EventsService } from '../../../../services/event.service';
import { UIColorModel } from '../../../../services/ui.color.model.service';

@Directive({
  selector: '[primaryContainer]',
})

export class ColorPrimaryContainerDirective {
  uiColorModel: UIColorModel
  eventsService: EventsService
  changeDetectorRef: ChangeDetectorRef

  constructor(private _eventsService: EventsService, public el: ElementRef,
              public ref: ChangeDetectorRef,
              private _uiColorModel: UIColorModel) {

    this.eventsService = _eventsService;
    this.uiColorModel = _uiColorModel;
    this.changeDetectorRef = ref

    this.updateElement()
    let self = this;
    this.eventsService.on('onRefresh', function (args: any) {
      self.updateElement()
    });
  }

  updateElement() {
    this.el.nativeElement.style.background = this.uiColorModel.primaryContainer
    this.ref.markForCheck();
  }
}
