import {
	Component,
	ViewEncapsulation,
	ViewChild,
	ChangeDetectorRef,
	ChangeDetectionStrategy,
	NgZone,
	Input,
	Output,
	EventEmitter
} from '@angular/core';

import { CollectionsUIAnimations } from '../../classes/collection.ui.animations';


import { EventsService } from '@coreui//services/event.service'


@Component({
	selector: 		'abstract-anim-collection-container',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.Default,
	inputs: ['width', 'height', 'margintop', 'marginright', 'border', 'radius', 'data', 'mode'],
	template:	`
	`,  

})


export class AbstractCollectionContainerAnimated {
	@Output()
	clicked = new EventEmitter();

	@Input()
	set width(value: number) { this.setWidth(value); }
	set height(value: number) { this.setHeight(value); }
	set margintop(value: number) { this.setMarginTop(value); }
	set marginright(value: number) { this.setMarginRight(value); }
	set border(value: string) { this.setBorder(value); }
	set radius(value: number) { this.setRadius(value); }

	set data(value: any) { this.setData(value); }
	set mode(value: string) { this.setMode(value); }

	eventsService: EventsService
	changeDetectorRef: ChangeDetectorRef;

	collectionsUIAnimations: CollectionsUIAnimations
	entryOpacity: number = 1
	tileMode: string = ""

  	items: any = []
  	itemsTemp: any = []

	_width:	number = 220;
	_height:	number = 220;
	_elevation: number = 3;
	_radius: number = 2;
	_clickAnimated: boolean = false;
	clickAnimationClass: string = "";
	_marginTop: number = 16;
	_marginRight: number = 16;
  	_transitionClass: string = ""
	_cssClass: string = ""
	_selectedAnim: string = ""
	_border: string = ""


	constructor(ref: ChangeDetectorRef, private _eventsService: EventsService) {
 		this.changeDetectorRef = ref;
		 this.eventsService = _eventsService

		this.collectionsUIAnimations = new CollectionsUIAnimations()


		let self = this
		this.eventsService.on('event', function (args: any) {
		});

		this.eventsService.on('onRefresh', function (args: any) {
			self.setData(self.items)
			if(self.tileMode == "select") {
				self.selectModeOn()
			} else {
				self.selectModeOff()
			}
		
		});

		this.eventsService.on('selectModeOn', function (args: any) {
			self.selectModeOn()
		});
		this.eventsService.on('selectModeOff', function (args: any) {
			self.selectModeOff()
		});

		this.eventsService.on('selectAll', function (args: any) {
			self.selectAll();
		});

		this.eventsService.on('selectNone', function (args: any) {
			self.selectNone();
		});

		this.eventsService.on('moveSelected', function (args: any) {
			self.moveSelectedItems(args.id);
		});

		this.eventsService.on('deleteSelected', function (args: any) {
			self.deleteSelectedItems();
		});


		this.eventsService.on('showTrashed', function (args: any) {
			self.selectModeOn()
			self.showTrashed();
		});

		this.eventsService.on('showTrashedOff', function (args: any) {
			self.showTrashedOff();
			self.selectModeOff()
		});


		this.eventsService.on('shakeSelected', function (args: any) {
			self.shakeItem(args.id);
		});


	}


	setWidth(value: number) {
		this._width = value
	}
	setHeight(value: number) {
		this._height = value
	}
	setMarginTop(value: number) {
		this._marginTop = value
	}
	setMarginRight(value: number) {
		this._marginRight = value
	}
	setBorder(value: string) {
		this._border = value
	}
	setRadius(value: number) {
		this._radius = value
	}


	setData(value: any) {
		this.items = value
	}


	initData(_items: any) {
		let itemsList = _items
		itemsList = this.collectionsUIAnimations.initialiseTileCollection(itemsList)
		itemsList = this.collectionsUIAnimations.resetAnimations(itemsList);
		this.items = itemsList
	}

	ngOnInit() {
	  this.entryOpacity = 0;
	  setTimeout(() => {
			this.entryOpacity = 1;
      }, 10)
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {

	}

	setMode(value: string) {
		this.tileMode = value
		if(this.tileMode == "select") {
			this.selectModeOn()
		}

		if(this.tileMode == "none") {
			this.selectModeOff()
		}
	}

	getMode() {
		return this.tileMode
	}

	//////////////////////////////////////////////////////////////////////
  // ADD TILE(S)
	//////////////////////////////////////////////////////////////////////
	addTileItem(id: string, src: string) {
		let item = {
			id:							id,
			src:						src,
			markForDelete:				false
		}
		this.items = this.collectionsUIAnimations.addTileAnimationStart(this.items, id, item, 320, 300);
		this.changeDetectorRef.markForCheck();
		setTimeout(() => {
			this.items = this.collectionsUIAnimations.addTileAnimationEnd(this.items);
			this.changeDetectorRef.markForCheck();
		}, 50);
		setTimeout(() => {
			this.items = this.collectionsUIAnimations.resetAnimations(this.items);
		}, 355);
		this.changeDetectorRef.markForCheck();
	}


  //////////////////////////////////////////////////////////////////////
  // SELECT TILE(S)
	//////////////////////////////////////////////////////////////////////
	selectModeOn() {
		this.tileMode = "select"
		this.items = this.collectionsUIAnimations.selectModeOn(this.items);
	}
	selectModeOff() {
		this.tileMode = "none"
		this.items = this.collectionsUIAnimations.selectModeOff(this.items);
	}

	selectItem(id: string) {
		if(this.tileMode == "select") {
			this.items = this.collectionsUIAnimations.selectTileAnimationStart(this.items, id)
			this.changeDetectorRef.markForCheck();
		} else {
			this.shakeItem(id);
		//	this.applicationService.events().broadcast('shakeSelected', {'id': id});
			setTimeout(() => {
				this.eventsService.broadcast('detailView', {'id': id});
			}, 100);
		}
	}

	selectAll() {
		this.items = this.collectionsUIAnimations.selectAll(this.items)
	}
	selectNone() {
		this.items = this.collectionsUIAnimations.selectNone(this.items)
	}



	itemExists(id: string): boolean {
		let i = 0
		let exists = false
		for(i=0;i<this.items.length;i++) {
			if(this.items[i]["id"] == id) {
				exists = true
			}
		}
		return exists
	}


	getSelectedItems(): any[] {
		return this.collectionsUIAnimations.getSelectedItems(this.items)
	}


	//////////////////////////////////////////////////////////////////////
    // DELETE TILE(S)
	//////////////////////////////////////////////////////////////////////
	deleteItem(id: any) {
		this.items = this.collectionsUIAnimations.deleteTileAnimationStart(this.items, id)
		this.changeDetectorRef.markForCheck();
		setTimeout(() => {
			this.items = this.collectionsUIAnimations.deleteTileAnimationEnd(this.items)
			setTimeout(() => {
				this.items = this.collectionsUIAnimations.resetAnimations(this.items);
			}, 355);
			this.changeDetectorRef.markForCheck();
		}, 255);
	}

	deleteSelectedItems() {
		this.items = this.collectionsUIAnimations.deleteMultipleSelectedItemsStart(this.items)
		this.changeDetectorRef.markForCheck();
		setTimeout(() => {
			this.items = this.collectionsUIAnimations.tileWobbleAll(this.items)
			this.changeDetectorRef.markForCheck();
			let deleteItems = this.collectionsUIAnimations.getItemsToBeDeleted(this.items)
			this.eventsService.broadcast("objectsToDelete", {data: deleteItems})
		}, 200);
		setTimeout(() => {
			this.items = this.collectionsUIAnimations.deleteMultipleSelectedItemsEnd(this.items)
			this.items = this.collectionsUIAnimations.resetAnimations(this.items);
		}, 450);
	}


	_deleteSelectedItemsFromArray() {
		let collection: any = []
		let original_list = collection["objects"]
		if(original_list != null) {
			let i = 0
			for(i=0;i<original_list.length;i++) {
				if(this.itemExists(original_list[i]["id"]) == false) {
					console.log("Deleting " + original_list[i]["url"])
		//			this.domManagementService.collections().trashObject(original_list[i]["id"])
				}
			}
		}
	}


	showTrashed() {
		this.itemsTemp = this.items
		this.items = []
		let i = 0
		for(i=0;i<this.itemsTemp.length;i++) {
			if(this.itemsTemp[i]["deleted_flag"] == true) {
				this.items.push(this.itemsTemp[i])
			}
		}
	}

	showTrashedOff() {
		this.items = this.itemsTemp
	}

	//////////////////////////////////////////////////////////////////////
    // MOVE TILE(S)
	//////////////////////////////////////////////////////////////////////
	moveSelectedItems(id: any) {
		this.printSelected()
		this._moveSelectedItems(id)

		this.items = this.collectionsUIAnimations.deleteSelectedItems(this.items)
		this.changeDetectorRef.markForCheck();
		setTimeout(() => {
			this.items = this.collectionsUIAnimations.tileWobbleAll(this.items)
			this.changeDetectorRef.markForCheck();
		}, 50);
		setTimeout(() => {
			this.items = this.collectionsUIAnimations.resetAnimations(this.items);
		}, 450);
	}

	_moveSelectedItems(id: any) {
		let selected = this.collectionsUIAnimations.getSelectedItems(this.items)
		let i = 0
		for(i=0;i<selected.length;i++) {
//			this.domManagementService.collections().moveObjectToCollection(id, selected[i]["id"])
		}
	}



	//////////////////////////////////////////////////////////////////////
    // ANIMATE TILE(S)
	//////////////////////////////////////////////////////////////////////
	shakeItem(id: any): any {
		this.items = this.collectionsUIAnimations.shakeItem(this.items, id)
		setTimeout(() => {
			this.items = this.collectionsUIAnimations.resetAnimations(this.items);
		}, 350);
	}

	shakeAll(): void {
		for(let i in this.items) {
			this.items = this.collectionsUIAnimations.shakeItem(this.items, this.items[i]["id"])
		}
	}

	printSelected() {
		let selected = this.collectionsUIAnimations.getSelectedItems(this.items)
		let i = 0
		for(i=0;i<selected.length;i++) {
			console.log("Selected " + selected[i]["url"])
		}
	}

}
