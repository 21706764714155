import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  ChangeDetectorRef,
  Inject,
  NgZone,
  OnInit,
  EventEmitter,
  HostListener,
  ChangeDetectionStrategy,
} from "@angular/core";

import { EventsService } from "../../../services/event.service";
import { UIColorModel } from "../../../services/ui.color.model.service";
import { ColorUtils } from "../../../style/color/utils/color.utils.class";
import { NavigationDrawerMenu } from "../../../classes/navigation/navigation.drawer.menu.class";

@Component({
  selector: "navigation-drawer-submenu",
  inputs: ["data"],
  outputs: ["clicked", "hoverenter", "hoverexit"],
  changeDetection: ChangeDetectionStrategy.Default,
  template: `
    <slider-sheet [direction]="'left'" [size]="320">
      <div left>
        <div class="_navigation-drawer" surfaceContainerLow>
          <div class="menu-control">
            <div class="icon-wrapper">
              <i class="mdi mdi-menu-open"></i>
            </div>
          </div>

          <ul class="menu-container">
            <span *ngIf="this._data.isRootMenu != true"><navigation-drawer-menu-item
                [id]="'back'"
                [icon]="'arrow-left'"
                [label]="'Back'"
                [active]="false"
              >
              </navigation-drawer-menu-item>
            </span>

            <ul class="menu-container {{_indentClass}}">
              <span
                *ngFor="
                  let dat of this._data.destinations
                "
              > 
                <span *ngIf="this.hasSubMenu(dat.id) == true">
                  <menu-item-collapsible
                    [id]="dat.id"
                    [label]="dat.label"
                    [active]="dat.isPressed"
                    [hovered]="dat.isHovered"
                    [submenu]="_data.getSecondLevelMenu(dat.id)"
                    (clicked)="subMenuClicked($event)"
                  >
                  </menu-item-collapsible>
                </span>
                <span *ngIf="this.hasSubMenu(dat.id) == false">
                  <menu-item-no-icon
                    [id]="dat.id"
                    [label]="dat.label"
                    [active]="dat.isPressed"
                    (clicked)="subMenuClicked(dat.id)"
                  >
                  </menu-item-no-icon>
                </span>
              </span>
            </ul>
          </ul>


		  <!-- Top Level Menu -->
	  

        </div>
      </div>
    </slider-sheet>
  `,
})
export class NavigationDrawerSubMenu {
  @Output()
  clicked = new EventEmitter();
  hoverenter = new EventEmitter();
  hoverexit = new EventEmitter();

  @Input()
  set data(value: NavigationDrawerMenu) {
    this.setData(value);
  }

  private changeDetectorRef: ChangeDetectorRef;
  private eventsService: EventsService;
  private colorUtils: ColorUtils = new ColorUtils();
  uiColorModel: UIColorModel;
  _indentClass: string = ""

  _data: NavigationDrawerMenu = new NavigationDrawerMenu();

  constructor(
    public ref: ChangeDetectorRef,
    private _eventsService: EventsService,
    private _uiColorModel: UIColorModel
  ) {
    this.changeDetectorRef = ref;
    this.eventsService = _eventsService;
    this.uiColorModel = _uiColorModel;

    let self = this;
    this.eventsService.on("onRefresh", function (args: any) {});
  }

  setData(value: NavigationDrawerMenu) {
    this._data = value;
  }

  itemClicked(id: string) {
    this.clicked.emit(id);
  }

  public hasSubMenu(id: string): boolean {
    return this._data.hasSubmenu(id) 
  }


  ngOnInit() {}

  ngOnChanges() {}

  ngOnDestroy() {}

  subMenuClicked($event: any) {

  }
}
