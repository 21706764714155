import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


/////////////////////////////////////////////////////////
// 3rd Party Libraries
////////////////////////////////////////////////////////


/////////////////////////////////////////////////////////
// Common Libraries
////////////////////////////////////////////////////////
import { CoreUIModule } from '@coreui/coreui.module'
import { AppleMapsModule } from 'ngx-apple-maps';

/////////////////////////////////////////////////////////
// Components
////////////////////////////////////////////////////////
import { StarReviewWComponent } from './components/star-review/star.review.component';
import { ScrollTopButtonComponent } from './components/scrolltop-button/scrolltop.button.component';

import { ProgressPercentOnelineCard } from './components/progress-cards/progress.perc.oneline.card';
import { ProgressPercentTwolineCard } from './components/progress-cards/progress.perc.twoline.card';
import { FileUploadProgressCard } from './components/progress-cards/file.upload.progress.card';

import { ImageCollectionCardComponent } from './components/image-cards/image.collection.card';

import { StdSocialButtonComponent } from './components/social-buttons/std.social..button.component';
import { RoundSocialButtonComponent } from './components/social-buttons/round.social.button.component';
import { OutlineSocialButtonComponent } from './components/social-buttons/outline.social.button.component';

/* Toast */
import { PopupToast } from './components/fullscreen-confirmation/popup.fullscreen.toast';

import { PopupRadarBlip } from './components/fullscreen-radar-popup/popup.fullscreen.radar.blip';

/////////////////////////////////////////////////////////
// Flows
////////////////////////////////////////////////////////
import { SignInModalFlow } from './flows/signin/signin.modal.flow';

import { AbstractFileDropComponent } from './flows/file-upload/abstract.file.drop.component';
import { FileDropTargetComponent } from './flows/file-upload/file.drop.target';
import { FileUploadService } from './flows/file-upload/file.upload.service';



@NgModule({
  declarations: [
    StarReviewWComponent,
    ScrollTopButtonComponent,
    SignInModalFlow,
    ImageCollectionCardComponent,
    AbstractFileDropComponent,
    FileDropTargetComponent,
    ProgressPercentOnelineCard,
    ProgressPercentTwolineCard,
    FileUploadProgressCard,
    StdSocialButtonComponent,
    RoundSocialButtonComponent,
    OutlineSocialButtonComponent,
    PopupToast,
    PopupRadarBlip
  ],

  imports: [
    CommonModule,
    CoreUIModule,
  ],

  providers: [
    AppleMapsModule,
    FileUploadService
  ],

  exports: [
    StarReviewWComponent,
    ScrollTopButtonComponent,
    SignInModalFlow,
    ImageCollectionCardComponent,
    AbstractFileDropComponent,
    FileDropTargetComponent,
    ProgressPercentOnelineCard,
    ProgressPercentTwolineCard,
    FileUploadProgressCard,
    StdSocialButtonComponent,
    RoundSocialButtonComponent,
    OutlineSocialButtonComponent,
    PopupToast,
    PopupRadarBlip
  ]
})
export class CoreUIExtensionsModule { }
