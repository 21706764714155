import {
	Component,
	ViewEncapsulation
} from '@angular/core';

import { ApplicationService } from '../services/application.service';
import { EventsService } from '@coreui/services/event.service'
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { UILayout } from '@coreui//services/ui.layout.service';

@Component({
	selector: 		'left-navigation',
	encapsulation: ViewEncapsulation.None,

	template:	`

			<div [ngSwitch]=this.uiLayout.windowClass>

				<div *ngSwitchCase="'medium'">
					<navigation-rail 
							[data]=this.applicationService.navigationRail()
							(hoverenter)=this.handleNavRailOnHover($event)
							(clicked)=handleNavRailClicked($event)>
					</navigation-rail>
				</div>
				<div *ngSwitchCase="'expanded'">
					<navigation-rail 
							[data]=this.applicationService.navigationRail()
							(hoverenter)=this.handleNavRailOnHover($event)
							(clicked)=handleNavRailClicked($event)>
					</navigation-rail>

		<!--		<navigation-drawer 
							[data]=this.applicationService.navigationDrawer()>
					</navigation-drawer> -->
				</div>
			</div>

	`,

})


export class LeftNavigation {

	private eventsService: EventsService
	applicationService: ApplicationService
	uiNavigation: UINavigation
	uiLayout: UILayout

	constructor(_eventsService: EventsService, 
			private _applicationService: ApplicationService,
			private _uiNavigation: UINavigation,
			private _uiLayout: UILayout) {
		this.eventsService = _eventsService
		this.applicationService = _applicationService
		this.uiNavigation = _uiNavigation
		this.uiLayout = _uiLayout

		let self = this;
		self.eventsService.on('onShowModalOverlay', function (args: any) {
		});

	}

	ngOnInit() {
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {

	}


	handleNavRailClicked(id: string) {
		if(id == "navigation-rail-menu") {
			this.eventsService.broadcast("onShowModalOverlay", {id: "sample-overlay"})
		} else {
			this.applicationService.navigationRail().unPressAll()
			if(this.applicationService.navigationRail().hasSubmenu(id) == false) {
				this.applicationService.navigationRail().makePressed(id, true)
				this.uiNavigation.activeView = this.applicationService.navigationRail().getDestination(id)
			}	
		}
	}

	handleNavRailOnHover(id: string) {
		this.applicationService.navigationRail().unHoverAll()
		this.applicationService.navigationRail().makeHover(id, true)
	}


}
