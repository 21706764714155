import {
  Component,
  ViewEncapsulation,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from "@angular/core";

import { EventsService } from '@coreui/services/event.service'

@Component({
  selector: "abstract-tile",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
	inputs: ['id', 'width', 'height', 'marginbottom', 'marginright', 'border', 'radius', 'selected', 'selectmode', 'cssclass'],

	template:	`
		<li class="grid-element shadow-1 {{_cssClass}}" style="box-sizing: border-box;"
				[style.width.px]=_width
				[style.height.px]=_height
				[style.marginBottom.px]=_marginBottom
				[style.marginRight.px]=_marginRight
				[style.border]=_border
				[style.borderRadius.px]=_radius
				surfaceContainer>
				<div class="_abstract-tile {{_selectClass}} {{_selectedAnim}}" (click)=selectItem(this._id)
		 			[style.width.px]=_width [style.height.px]=_height [style.borderRadius.px]=_radius>
					<ng-content></ng-content>
				</div>
			</li>  


  `,
})
export class AbstractTile {
	@Output()
	clicked = new EventEmitter();

	@Input()
	set id(value: string) { this.setId(value); }
	set width(value: number) { this.setWidth(value); }
	set height(value: number) { this.setHeight(value); }
	set marginbottom(value: number) { this.setMarginBottom(value); }
	set marginright(value: number) { this.setMarginRight(value); }
	set border(value: string) { this.setBorder(value); }
	set radius(value: number) { this.setRadius(value); }

	set selected(value: boolean) { this.setSelected(value); }
	set selectmode(value: boolean) { this.setSelectMode(value); }
	set cssclass(value: string) { this.setCSSClass(value); }

	_imgsrc: string = "assets/default/default-image.png"
	_selected: boolean = false
	_selectmode: boolean = false
	_selectClass: string = ""

	_id: string = ""
	_width:	number = 220;
	_height:	number = 220;
	_elevation: number = 3;
	_radius: number = 2;
	_clickAnimated: boolean = false;
	clickAnimationClass: string = "";
	_marginBottom: number = 16;
	_marginRight: number = 16;
  	_transitionClass: string = ""
	_cssClass: string = ""
	_selectedAnim: string = ""
	_border: string = ""
	_selectIcon: string = "checkbox-blank-circle-outline"


  private eventsService: EventsService;
  private changeDetectorRef: ChangeDetectorRef;

  constructor(
    public ref: ChangeDetectorRef,
    _eventsService: EventsService,
  ) {
    this.eventsService = _eventsService;
    this.changeDetectorRef = ref;


    let self = this;
    this.eventsService.on("onResize", function (args: any) {
    });
  }


	setId(value: string) {
		this._id = value
	}
	setWidth(value: number) {
		this._width = value
	}
	setHeight(value: number) {
		this._height = value
	}
	setMarginBottom(value: number) {
		this._marginBottom = value
	}
	setMarginRight(value: number) {
		this._marginRight = value
	}
	setBorder(value: string) {
		this._border = value
	}
	setRadius(value: number) {
		this._radius = value
	}

	setSelected(value: boolean) {
		this._selected = value;
		this.toggleSelected()
	}

	setSelectMode(value: boolean) {
		this._selectmode = value
		if(value==true) {
			this._selectClass = "select-mode"
			this.ref.detectChanges()
		} else {
			this._selectClass = "none"
			this.ref.detectChanges()
		}
	}

	toggleSelected() {
		if(this._selected == true) {
			this._selectedAnim = "selected"
			this._border = "1px solid rgba(255,255,255,0.2)"
			this._selectIcon = "checkbox-marked-circle"
		} else {
			this._selectedAnim = ""
			this._border = ""
			this._selectIcon = "checkbox-blank-circle-outline"
		}
		this.ref.detectChanges();
	}

	setCSSClass(value: string) {
		this._cssClass = value
	}

	selectItem(id: string) {
		this.clicked.emit(id)
	}

  ngOnInit() {
  }

  ngOnDestroy() {}

  ngAfterViewInit() {}
}

