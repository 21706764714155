import {
  Component,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';

import { EventsService } from '../../../services/event.service'
import { ColorUtils } from "../../../style/color/utils/color.utils.class"
import { UIColorModel } from '../../../services/ui.color.model.service';

@Component({
  selector: 'tile-single-text-icon',
  inputs: ['label', 'icon', 'secondaryicon'],
  outputs: ['clicked'],
  changeDetection: ChangeDetectionStrategy.Default,
 
  template: `
      <li class="single-item-list-tile-icon-text" (mouseenter)=mouseoter($event)
        (mouseleave)=mouseleave($event)
        (click)=tileClicked($event)
        [style.backgroundColor]=surfaceColor>
        <div class="icon-wrapper">
          <i class="mdi mdi-{{_icon}}" onSurface font-family></i>
        </div>
        <div class="content">
          <div class="primary-text" onSurface font-family>{{_label}}</div>
        </div>
        <div *ngIf="'this._icon.length>0'" class="secondary-action">
          <tile-secondary-action-56 [icon]=_secondaryIcon></tile-secondary-action-56>
        </div>
      </li>

  `,
})
export class TileSingleItemIconComponent {
  @Output()
  clicked = new EventEmitter();

  @Input()
  set label(value: string) {
    this.setLabel(value);
  }
  set icon(value: string) {
    this.setIcon(value);
  }
  set secondaryicon(value: string) {
    this.setSecondaryIcon(value);
  }

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
  private colorUtils: ColorUtils = new ColorUtils()
	uiColorModel: UIColorModel

  _label: string = ""
  _icon: string = ""
  _secondaryIcon: string = ""
  surfaceColor: string = ""

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
    this.surfaceColor = "rgba(0,0,0,0)"
	}

  setLabel(value: string) {
    this._label = value
  }
  setIcon(value: string) {
    this._icon = value
  }
  setSecondaryIcon(value: string) {
    this._secondaryIcon = value
  }

  ngOnInit() {}

  ngOnChanges() {}

  ngOnDestroy() {}


  tileClicked($event: any) {
    this.clicked.emit(true)
  }

  mouseoter($event: any) {
    this.surfaceColor = this.uiColorModel.surfaceDim
  }
  mouseleave($event: any) {
    this.surfaceColor = "rgba(0,0,0,0)"
  }


}
