import {
	Component,
	Input,
	ChangeDetectorRef,
	ChangeDetectionStrategy,
	EventEmitter,
	Output
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'
import { UIColorModel } from '@coreui/services/ui.color.model.service';

@Component({
	selector: 'scroll-top-button',
	inputs: ['scrollshowvalue'],
	outputs: ['clicked'],
	changeDetection: ChangeDetectionStrategy.Default,
	template: 	`

		<div
			[style.position]="'fixed'"
			[style.bottom.px]=16
			[style.right.px]=16
			[style.visibility]=_display
		>
			<fab [icon]="'format-vertical-align-top'" (clicked)=onClick($event)></fab>
		</div>


		`,
})


export class ScrollTopButtonComponent {

	@Input()
	set scrollshowvalue(value: number) {
	  this.setScrollShow(value);
	}


	
	@Output()
	clicked = new EventEmitter();

	private changeDetectorRef: ChangeDetectorRef
    private eventsService: EventsService
	uiColorModel: UIColorModel
	_display: string = "hidden"
	_scrollStartValue: number = 0
	_isShowing: boolean = false
	
	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService, 
		private _uiColorModel: UIColorModel) {
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
		this.changeDetectorRef = ref

		let self = this;
		self.eventsService.on('onScrollStart', function (args: any) {
			if(args.y >= self._scrollStartValue) {
				if(self._isShowing == false) {
					self._isShowing = true
					self.show()
				}
			}  
		});

		self.eventsService.on('onScrollEnd', function (args: any) {
			if(args.y <= self._scrollStartValue) {
				if(self._isShowing == true) {
					self._isShowing = false
					self.hide()
				}
			}
		});


	}

	setScrollShow(value: number) {
		this._scrollStartValue = value
		if(value == 0) {
			this._display = "hidden"
		}
	}

	show() {
		this._display = "visible"
	}

	hide() {
		this._display = "hidden"
	}

	onClick($event: any) {
		this.clicked.emit($event)
	}

	ngOnInit() {
		if(this._scrollStartValue == 0) {
			this._display = "visible"
		}
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}

}