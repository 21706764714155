import { Injectable } from '@angular/core';


@Injectable()
export class AppAuthService {


    private _activeView: string = "signin";

    constructor() {
    }


    public get activeView(): string {
      return this._activeView;
    }
    public set activeView(value: string) {
      this._activeView = value;
    }
  
}
