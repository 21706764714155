import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	EventEmitter,
	HostListener, 
	ChangeDetectionStrategy
} from '@angular/core';

import { UILayout } from '@coreui/services//ui.layout.service'
import { EventsService } from '@coreui/services/event.service'
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { UIColorModel } from '@coreui/services/ui.color.model.service';
import { M3ColorSchemeUtility } from '@coreui/style/color/schemes/color.schene.utility.class';
import { ColorHCTUtils } from '@coreui/style/color/utils/color.utils.hct.class';


@Component({
	selector: 		'landing-page01',
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
	<background-dynamicclouds 
		[backgroundcolor]=_backgroundColor 
		[color1]=this._color1
		[color2]=this._color2
		[cloudopacity]=1>
	</background-dynamicclouds>


	<div style="z-index: 1; position: fixed; top: 0;">
		<content-container-scrollable>
			<div class="_landing-page01">
				<div class="hero-block">
					<div class="text-block">
						<div class="headline" onSurface>This is a headline</div>
						<div class="description" onSurface>A compelling description about the product or service</div>
					</div>
				</div>


				<div class="_features-section">
					<div class="row">
						<div class="col m4 s12">
							<div class="_icon-feature-block" surfaceContainerLowest
								elevated [elevation]=1>
								<div class="icon">
									<i class="mdi mdi-rocket-launch" onSurface></i>
								</div>
								<div class="heading" onSurface>Lightning Fast</div>
								<div class="body" onSurface>Our products are very quick and fast, so you'll get results in no time.</div>
							</div>
						</div>

						<div class="col m4 s12">

							<div class="_icon-feature-block" surfaceContainerLowest
								elevated [elevation]=1>
								<div class="icon">
									<i class="mdi mdi-creation" onSurface></i>
								</div>
								<div class="heading" onSurface>Creative</div>
								<div class="body" onSurface>Create your own widget with our very own widget robot thing.</div>
							</div>					
						</div>					
						<div class="col m4 s12">
							<div class="_icon-feature-block" surfaceContainerLowest
								elevated [elevation]=1>
								<div class="icon">
									<i class="mdi mdi-cloud-lock" onSurface></i>
								</div>
								<div class="heading" onSurface>Cloud-Based</div>
								<div class="body" onSurface>Our service is based in the clouds where your head will be too.</div>
							</div>					
						</div>
					</div>
				</div>


				<ul class="_clients-list-carousel">
					<li class="_client-logo-item"  *ngFor="let dat of this._logos"><img [src]=dat/></li>
				</ul>

				<div class="fab-container">
					<fab [icon]="'chevron-left'" (clicked)=close()></fab>
				</div>
			</div> 
		</content-container-scrollable>
	</div>

		`,
})


export class LandingPage01Template {

	private changeDetectorRef: ChangeDetectorRef
	private uiLayout: UILayout
	private uiNavigation: UINavigation
    private eventsService: EventsService
	uiColorModel: UIColorModel

	_backgroundColor: string = "rgba(0,0,0,0"
	_color1: string = ""
	_color2: string = ""


	_logos: any = []

	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService, 
		private _uiLayout: UILayout,
		private _uiNavigation: UINavigation,
		private _uiColorModel: UIColorModel) {
		this.uiLayout = _uiLayout
		this.eventsService = _eventsService
		this.uiNavigation = _uiNavigation
		this.uiColorModel = _uiColorModel
		this.changeDetectorRef = ref

		let util = new ColorHCTUtils()
		this._color1 = util.hexFromHCT(this.uiColorModel.primaryKeyColor, 91)
		this._color2 = util.hexFromHCT(this.uiColorModel.secondaryKeyColor, 97)
		this._color1 = this._color1.substring(1, this._color1.length)
		this._color2 = this._color2.substring(1, this._color2.length)

		this.addLogo("./assets/sample/logos/baci-perugina-logo.png")
		this.addLogo("./assets/sample/logos/coca-colalogo.png")
		this.addLogo("./assets/sample/logos/xbox-logo.png")
		this.addLogo("./assets/sample/logos/nintendo-logo.png")
		this.addLogo("./assets/sample/logos/starbucks-logo.png")
		this.addLogo("./assets/sample/logos/lambo-logo.png")
		this.addLogo("./assets/sample/logos/bugatti-logo.png")
	}


	ngOnInit() {
		setTimeout(() => {
			this.uiLayout.ignoreWindowMargins(true)
		//	this.uiLayout.applicationContainerSettings().compactNavigationComponent = "none"
		//	this.uiLayout.applicationContainerSettings().mediumNavigationComponent = "none"
		//	this.uiLayout.applicationContainerSettings().expandedNavigationComponent = "none"
			this.changeDetectorRef.detectChanges()
			this.eventsService.onRefresh()
	  }, 1);	
	  
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
		this.close()
	}


	addLogo(src: string) {
		this._logos.push(src)
	}

	close() {
		this.uiLayout.ignoreWindowMargins(false)
	/*	this.uiLayout.ignoreWindowMargins(false)
		this.uiLayout.applicationContainerSettings().compactNavigationComponent = "top"
		this.uiLayout.applicationContainerSettings().mediumNavigationComponent = "rail"
		this.uiLayout.applicationContainerSettings().expandedNavigationComponent = "rail"
		this.eventsService.onRefresh()
		setTimeout(() => {
			this.uiNavigation.activeView = "null"
		}, 250);	*/
	}

}

/*

Inspiration

https://www.in-focus.co.jp/
https://www.dotimg.co.jp/
https://www.atklab.com/
https://www.amestha.ch/
https://viens-la.com/

https://justhappiness.fr/
https://www.callbruno.com/fr/



*/


