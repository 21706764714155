import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	ChangeDetectorRef,
	Inject,
	NgZone,
	OnInit,
	ViewChild,
	ElementRef,
	EventEmitter,
	ChangeDetectionStrategy
} from '@angular/core';

import { UIColorModel } from '@coreui//services/ui.color.model.service';


@Component({
	selector: 'text-input-filled',
	inputs: ['label', 'supportingtext', 'leadingingicon', 'trailingicon', 'errormode'],
	outputs: ['value'],
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
		<div class="_text-input-filled-container {{_cssClass}}" 
			font-family surfaceVariantColor
			[style.borderBottom]=_borderBottom>
			<div *ngIf="_leadingIcon.length > 0" class="leading-icon">
				<i class="mdi mdi-{{_leadingIcon}}"></i>
			</div>
			<input #ifield class="input-field"
				autocomplete="off"
				spellcheck="false"
				(focus)="focusState()" 
				(focusout)="focusOutState()" 
				(keyup)="onKey(ifield.value)" onSurface>
			<div *ngIf="_trailingIcon.length > 0" class="trailing-icon-wrapper"
					(click)=clearField()>
					<i class="mdi mdi-{{_trailingIcon}}"
					[style.color]=_trailingIconColor></i>
			</div>
			<span class="label" [style.color]=_labelTxtColor>{{_labelTxt}}</span>
			<div *ngIf="_supportingText.length > 0" class="text-input-supporting-text" 
			[style.color]=_supportingTextColor>{{_supportingText}}</div>
		</div>
	`,

})



export class InputTextFieldFilled {
	@ViewChild('ifield') public ifield: ElementRef = new ElementRef("ifield");

	@Output()
	value = new EventEmitter();

	@Input()
	set label(value: string) { this.setLabel(value); }
	set supportingtext(value: string) { this.setSupportingText(value); }
	set leadingicon(value: string) { this.setTrailingIcon(value); }
	set trailingicon(value: string) { this.setTrailingIcon(value); }
	set errormode(value: boolean) { this.setErrorMode(value); }


    uiColorModel: UIColorModel
	_leadingIcon: string = ""

	_trailingIcon: string = ""
	_trailingIconColor: string = ""

	_labelTxt: string = "";
	_labelTxtColor: string = "";

	_supportingText: string = ""
	_supportingTextColor: string = ""

	_trailingIconPrevious: string = ""
	_errorModeIcon: string = "close-circle"

	_borderBottom: string = "none"
	_cssClass: string = ""
	_isErrorMode: boolean = false
	_isFocused: boolean = false

	changeDetectorRef: ChangeDetectorRef;
	textValue: string = ""
	constructor(public ref: ChangeDetectorRef, private _uiColorModel: UIColorModel) {
		this.changeDetectorRef = ref
		this.uiColorModel = _uiColorModel

	}


	onKey(e: any) {
		this.value.emit(e);
		this.textValue = e
		if(e.length > 0) {
			this._cssClass = "active-has-icon"
		} else {
			this._cssClass = ""
		}
		this.computeColor()
	}

	setLabel(value: string) {
		this._labelTxt = value;
	}

	setSupportingText(val: string) {
		this._supportingText = val;
		this.computeColor()
	}

	setTrailingIcon(value: string) {
		this._trailingIcon = value
		this._trailingIconPrevious = value
	}

	setLeadingIcon(value: string) {
		this._leadingIcon = "mdi-" + value
	}

	setErrorMode(value: boolean) {
		this._isErrorMode = value
		this.computeColor()
	}

	clearField() {
		this.ifield.nativeElement.value = ""
		this.textValue = ""
	}

	ngOnInit() {
	}

	ngOnDestroy() {
  	}

	isEmpty(): boolean {
		let result = true
		if(this.textValue.length > 0) {
			result = false
		}
		return result
	}

	focusState() {
		this.computeColor()
		this._isFocused = true
		this._cssClass = "active-has-icon"
		if(this._isErrorMode) {
			this._borderBottom = "2px solid " + this.uiColorModel.error
			this._labelTxtColor = this.uiColorModel.error
		} else {
			this._borderBottom = "2px solid " + this.uiColorModel.primary
			this._labelTxtColor = this.uiColorModel.primary
		}
	}

	focusOutState() {
		this.computeColor()
		if(this.isEmpty()) {
			this._cssClass = ""
			this._labelTxtColor = this.uiColorModel.onSurface
		} else {
			this._cssClass = "active-has-icon"
			this._labelTxtColor = this.uiColorModel.primary
		}
		this._isFocused = false
		if(this._isErrorMode == false) {
			this._borderBottom = "none"
		}
		this.changeDetectorRef.detectChanges()
	}

	enabledState() {
	}

	selectedState() {
	}

	errorState() {
	}


	computeColor() {
		if(this._isErrorMode) {
			this._labelTxtColor = this.uiColorModel.error
			this._supportingTextColor = this.uiColorModel.error
			this._trailingIconColor = this.uiColorModel.error
			this._trailingIcon = this._errorModeIcon
			this._borderBottom = "2px solid " + this.uiColorModel.error
		} else {
			this._labelTxtColor = this.uiColorModel.primary
			this._supportingTextColor = this.uiColorModel.onSurface
			this._trailingIconColor = this.uiColorModel.onSurface
			this._trailingIcon = this._trailingIconPrevious
		}

		if(this.isEmpty()) {
			this._labelTxtColor = this.uiColorModel.onSurface
			this._supportingTextColor = this.uiColorModel.onSurface
			this._trailingIconColor = this.uiColorModel.onSurface
		}

		this.changeDetectorRef.detectChanges()
	}

}
