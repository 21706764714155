import {
	Component,
	ViewEncapsulation,
	ChangeDetectorRef,

	ViewChild,
	ChangeDetectionStrategy
} from '@angular/core';

import { UILayout } from '@coreui/services//ui.layout.service'
import { EventsService } from '@coreui/services/event.service'
import { UINavigation } from '@coreui/services/ui.navigation.service';
import { TypographyModel } from '@coreui/services/typography.service';
import { UIColorModel } from '@coreui/services/ui.color.model.service';
import { PaletteDataSample } from '@libraries/color-swatches/classes/palette.data.class';


@Component({
	selector: 		'color-list-detail-example-page',
	changeDetection: ChangeDetectionStrategy.Default,

	template: 	`
		<list-detail-page [width]=280>
			<div list-pane>
				<ul class="list-tiles-container">
					<li class="single-item-list-tile-icon-text" surfaceVariantColor
						*ngFor="let dat of this._palettes"
						(click)=paletteSelect(dat.id)>
						<div class="icon-wrapper"><i class="mdi mdi-palette" onSurface></i></div>
						<div class="content"><div class="primary-text" onSurface font-family>{{dat.title}}</div></div>
					</li>
				</ul>
			</div>
			<div detail-pane>
				<colors-collection-anim [data]=_searchResults></colors-collection-anim>
			</div>

		</list-detail-page>
 
		`,
})


export class ColorListDetailExamplePage {
	private changeDetectorRef: ChangeDetectorRef
	private uiLayout: UILayout
	private uiNavigation: UINavigation
    private eventsService: EventsService
	private typographyModel: TypographyModel
	uiColorModel: UIColorModel

	_colorData: any = {}
	_searchResults: any = {}

	_palettes: any = []

	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService, 
		private _uiLayout: UILayout,
		private _uiNavigation: UINavigation,
		private _uiColorModel: UIColorModel,
		private _typographyModel: TypographyModel) {
		this.uiLayout = _uiLayout
		this.eventsService = _eventsService
		this.uiNavigation = _uiNavigation
		this.typographyModel = _typographyModel
		this.uiColorModel = _uiColorModel
		this.changeDetectorRef = ref

		this.addPalette("pm", "Peach Marketing Colors")
		this.addPalette("pantone2023", "Pantone 2023")
		this.addPalette("material", "Material Colors")
		this.addPalette("flat", "Flat Colors")
	}

	ngOnInit() {
		let pp = new PaletteDataSample()
		this._colorData = pp.materialColors().getColors()
		this._searchResults = this._colorData
		this.changeDetectorRef.detectChanges()
	}

	ngOnChanges() {
	}

	ngOnDestroy() {
	}


	paletteSelect(id: string) {
		let pd = new PaletteDataSample()
		if(id == "pm") {
			this._searchResults = pd.peachMarketingColors().getColors()
		}
		if(id == "pantone2023") {
			this._searchResults = pd.pantone2023().getColors()
		}
		if(id == "flat") {
			this._searchResults = pd.flatColors().getColors()
		}
		if(id == "material") {
			this._searchResults = pd.materialColors().getColors()
		}
	}

	addPalette(id: string, title: string) {
		let rec = {
			id: id,
			title: title
		}
		this._palettes.push(rec)
	}
}
