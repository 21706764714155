import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';

import { EventsService } from '@coreui/services/event.service'
import { UIColorModel } from '@coreui/services/ui.color.model.service';
import { ApplicationService } from '../../services/application.service';


@Component({
	selector: 		'travel01-screen',
	encapsulation: ViewEncapsulation.None,

	template:	`

	<content-container-scrollable>
		<optimised-content-container>
		<!--	<div class="row">
				<div class="col s10 offset-s1">
					<airline01-flight-price-card></airline01-flight-price-card>	
				</div>
			</div> -->

			<calendar-month></calendar-month>
		</optimised-content-container>
	</content-container-scrollable>
		
	`,

})


export class Travel01Screen {

	private changeDetectorRef: ChangeDetectorRef
	private eventsService: EventsService
	uiColorModel: UIColorModel
	applicationService: ApplicationService

	constructor(public ref: ChangeDetectorRef, private _eventsService: EventsService,
		private _uiColorModel: UIColorModel, private _applicationService: ApplicationService) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiColorModel = _uiColorModel
		this.applicationService = _applicationService
	}

	ngOnInit() {
		this.eventsService.onRefresh()
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
	}

}
