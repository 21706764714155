import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';



@Component({
	selector: 		'home-screen',
	encapsulation: ViewEncapsulation.None,

	template:	`

		<screen-content-container [nomargins]=true>
		<div class="_sp-home-screen">
			<div class="hero-slide">
				<div class="tint">
					<div class="cta-block">
						<div class="text" display-font>Serving the poor and disadvantaged</div>
						<div class="cta-wrapper">
							<div class="sp-cta-button" secondaryColor font-family>Join Us</div>
						</div>
					</div>
				</div>
				<img [src]=_heroImageURL/>
			</div>

			<div class="slide">
				<div class="heading" display-font>The Spiritan Mission</div> 
				<div class="content" display-font>The Spiritan Fathers are dedicated to the Holy Spirit, the author of all holiness, and we place ourselves under the protection of the Immaculate Heart of Mary.  We, therefore, live in willing obedience to the Holy Spirit, taking Mary as our model.</div> 
			</div>


			<div class="slide">
				<div class="content" display-font>
					<div class="row">
					<div class="col s12 m6 l4">
							<div class="testimony-card">
								<div class="photo">
									<img [src]="'./assets/spiritans/photos/JimMcCloskey_rome_arricia.png'"/>
								</div>
								<div class="testimony" display-font>
								"I was deeply influenced by the Spiritans as a student at Holy Ghost Prep. I saw deeply that they are truly good men and good priests and loved what they were doing. I knew I wanted to be like them.""
								</div>
								<div class="name" display-font>
								Fr. Jim McCloskey, C.S.Sp.
								</div>
								<div class="description" display-font>
								Senior Advisor to the President for Strategic Initiatives at Duquesne University
								</div>
							</div>
						</div>
						<div class="col s12 m6 l4">
							<div class="testimony-card">
								<div class="photo">
									<img [src]="'./assets/spiritans/photos/Jeffrey-Duaime-June-17-2013_Det_rd_200px.png'"/>
								</div>
								<div class="testimony" display-font>
								"I became a Spiritan to serve the poor. My mission appointment to Haiti opened my eyes to what it means to be church and the many ways that we are enriched by opening ourselves to the revelation of God in the wonderful diversity of the world in which we live."
								</div>
								<div class="name" display-font>
								Fr. Jeff Duaime, C.S.Sp.
								</div>
								<div class="description" display-font>
								First Assistant, Spiritan General Council, Rome
								</div>
							</div>
						</div>
						<div class="col s12 m6 l4">
							<div class="testimony-card">
								<div class="photo">
									<img [src]="'./assets/spiritans/photos/Virginia_Sedor_BPark_JMG_5874.png'"/>
								</div>
								<div class="testimony" display-font>
								"My long relationship with the Spiritans certainly helped bring me closer to the Holy Spirit and our Blessed Mother. I have long admired the Holy Ghost Fathers for their commitment to work with GodÆs poor and for their genuine kindness to all"
								</div>
								<div class="name" display-font>
								Virginia Sedor
								</div>
								<div class="description" display-font>
								First Assistant, Spiritan General Council, Rome
								</div>
							</div>
						</div>												
					</div>
				</div> 
			</div>

		</div>

</screen-content-container>
		
	`,

})


export class HomeScreen {

	_heroImageURL: string = "./assets/spiritans/photos/IMG-20230616-WA0005.jpg"

	constructor() {
	}

	ngOnInit() {
	}

	ngOnDestroy() {
	}

	ngAfterViewInit() {
	}


}
