import {Injectable} from '@angular/core'

@Injectable()
export class TimeZonesService  {

  private _data: any = [];
  

  constructor() {
    this.dataSet()
  }


  public timezones() : any[] {
    return this._data
  }
 
  public search(idString: string): any[] {
    let results: any = []
    for(let i=0;i<this._data.length;i++) {
      let searchStr = idString.toLowerCase()
      let timezone: string = this._data[i]["id"].toLowerCase()
      if(timezone.includes(searchStr) == true) {
        results.push(this._data[i])
      }
    }
    return results
  }

  public get(id: string): any {
    let result: any = {}
    for(let i=0;i<this._data.length;i++) {
      let searchStr = id.toLowerCase()
      let timezone: string = this._data[i]["id"].toLowerCase()
      if(timezone == searchStr) {
        result = this._data[i]
      }
    }
    return result
  }

  

  public timezoneExists(id: string) :boolean {
    let exists = false
    for(let i=0;i<this._data;i++) {
      if(this._data[i]["id"] == id) {
        exists = true
      }
    }
    return exists
  }


  dataSet() {
    this.addTimezone("Africa/Abidjan",0,0,)
    this.addTimezone("Africa/Accra",0,0,)
    this.addTimezone("Africa/Addis Ababa",10800,10800,)
    this.addTimezone("Africa/Algiers",3600,3600,)
    this.addTimezone("Africa/Asmara",10800,10800,)
    this.addTimezone("Africa/Asmera",10800,10800,)
    this.addTimezone("Africa/Bamako",0,0,)
    this.addTimezone("Africa/Bangui",3600,3600,)
    this.addTimezone("Africa/Banjul",0,0,)
    this.addTimezone("Africa/Bissau",0,0,)
    this.addTimezone("Africa/Blantyre",7200,7200,)
    this.addTimezone("Africa/Brazzaville",3600,3600,)
    this.addTimezone("Africa/Bujumbura",7200,7200,)
    this.addTimezone("Africa/Cairo",7200,7200,)
    this.addTimezone("Africa/Casablanca",0,3600,)
    this.addTimezone("Africa/Ceuta",3600,7200,)
    this.addTimezone("Africa/Conakry",0,0,)
    this.addTimezone("Africa/Dakar",0,0,)
    this.addTimezone("Africa/Dar es Salaam",10800,10800,)
    this.addTimezone("Africa/Djibouti",10800,10800,)
    this.addTimezone("Africa/Douala",3600,3600,)
    this.addTimezone("Africa/El Aaiun",0,3600,)
    this.addTimezone("Africa/Freetown",0,0,)
    this.addTimezone("Africa/Gaborone",7200,7200,)
    this.addTimezone("Africa/Harare",7200,7200,)
    this.addTimezone("Africa/Johannesburg",7200,7200,)
    this.addTimezone("Africa/Juba",10800,10800,)
    this.addTimezone("Africa/Kampala",10800,10800,)
    this.addTimezone("Africa/Khartoum",10800,10800,)
    this.addTimezone("Africa/Kigali",7200,7200,)
    this.addTimezone("Africa/Kinshasa",3600,3600,)
    this.addTimezone("Africa/Lagos",3600,3600,)
    this.addTimezone("Africa/Libreville",3600,3600,)
    this.addTimezone("Africa/Lome",0,0,)
    this.addTimezone("Africa/Luanda",3600,3600,)
    this.addTimezone("Africa/Lubumbashi",7200,7200,)
    this.addTimezone("Africa/Lusaka",7200,7200,)
    this.addTimezone("Africa/Malabo",3600,3600,)
    this.addTimezone("Africa/Maputo",7200,7200,)
    this.addTimezone("Africa/Maseru",7200,7200,)
    this.addTimezone("Africa/Mbabane",7200,7200,)
    this.addTimezone("Africa/Mogadishu",10800,10800,)
    this.addTimezone("Africa/Monrovia",0,0,)
    this.addTimezone("Africa/Nairobi",10800,10800,)
    this.addTimezone("Africa/Ndjamena",3600,3600,)
    this.addTimezone("Africa/Niamey",3600,3600,)
    this.addTimezone("Africa/Nouakchott",0,0,)
    this.addTimezone("Africa/Ouagadougou",0,0,)
    this.addTimezone("Africa/Porto-Novo",3600,3600,)
    this.addTimezone("Africa/Sao Tome",0,0,)
    this.addTimezone("Africa/Timbuktu",0,0,)
    this.addTimezone("Africa/Tripoli",7200,7200,)
    this.addTimezone("Africa/Tunis",3600,3600,)
    this.addTimezone("Africa/Windhoek",3600,7200,)
    this.addTimezone("America/Adak",-36000,-32400,)
    this.addTimezone("America/Anchorage",-32400,-28800,)
    this.addTimezone("America/Anguilla",-14400,-14400,)
    this.addTimezone("America/Antigua",-14400,-14400,)
    this.addTimezone("America/Araguaina",-10800,-10800,)
    this.addTimezone("America/Argentina/Buenos Aires",-10800,-10800,)
    this.addTimezone("America/Argentina/Catamarca",-10800,-10800,)
    this.addTimezone("America/Argentina/ComodRivadavia",-10800,-10800,)
    this.addTimezone("America/Argentina/Cordoba",-10800,-10800,)
    this.addTimezone("America/Argentina/Jujuy",-10800,-10800,)
    this.addTimezone("America/Argentina/La Rioja",-10800,-10800,)
    this.addTimezone("America/Argentina/Mendoza",-10800,-10800,)
    this.addTimezone("America/Argentina/Rio Gallegos",-10800,-10800,)
    this.addTimezone("America/Argentina/Salta",-10800,-10800,)
    this.addTimezone("America/Argentina/San Juan",-10800,-10800,)
    this.addTimezone("America/Argentina/San Luis",-10800,-10800,)
    this.addTimezone("America/Argentina/Tucuman",-10800,-10800,)
    this.addTimezone("America/Argentina/Ushuaia",-10800,-10800,)
    this.addTimezone("America/Aruba",-14400,-14400,)
    this.addTimezone("America/Asuncion",-10800,-10800,)
    this.addTimezone("America/Atikokan",-18000,-18000,)
    this.addTimezone("America/Atka",-36000,-32400,)
    this.addTimezone("America/Bahia",-10800,-10800,)
    this.addTimezone("America/Bahia Banderas",-21600,-18000,)
    this.addTimezone("America/Barbados",-14400,-14400,)
    this.addTimezone("America/Belem",-10800,-10800,)
    this.addTimezone("America/Belize",-21600,-21600,)
    this.addTimezone("America/Blanc-Sablon",-14400,-14400,)
    this.addTimezone("America/Boa Vista",-14400,-14400,)
    this.addTimezone("America/Bogota",-18000,-18000,)
    this.addTimezone("America/Boise",-25200,-21600,)
    this.addTimezone("America/Buenos Aires",-10800,-10800,)
    this.addTimezone("America/Cambridge Bay",-25200,-21600,)
    this.addTimezone("America/Campo Grande",-14400,-10800,)
    this.addTimezone("America/Cancun",-18000,-18000,)
    this.addTimezone("America/Caracas",-16200,-16200,)
    this.addTimezone("America/Catamarca",-10800,-10800,)
    this.addTimezone("America/Cayenne",-10800,-10800,)
    this.addTimezone("America/Cayman",-18000,-18000,)
    this.addTimezone("America/Chicago",-21600,-18000,)
    this.addTimezone("America/Chihuahua",-25200,-21600,)
    this.addTimezone("America/Coral Harbour",-18000,-18000,)
    this.addTimezone("America/Cordoba",-10800,-10800,)
    this.addTimezone("America/Costa Rica",-21600,-21600,)
    this.addTimezone("America/Creston",-25200,-25200,)
    this.addTimezone("America/Cuiaba",-14400,-10800,)
    this.addTimezone("America/Curacao",-14400,-14400,)
    this.addTimezone("America/Danmarkshavn",0,0,)
    this.addTimezone("America/Dawson",-28800,-25200,)
    this.addTimezone("America/Dawson Creek",-25200,-25200,)
    this.addTimezone("America/Denver",-25200,-21600,)
    this.addTimezone("America/Detroit",-18000,-14400,)
    this.addTimezone("America/Dominica",-14400,-14400,)
    this.addTimezone("America/Edmonton",-25200,-21600,)
    this.addTimezone("America/Eirunepe",-18000,-18000,)
    this.addTimezone("America/El Salvador",-21600,-21600,)
    this.addTimezone("America/Ensenada",-28800,-25200,)
    this.addTimezone("America/Fort Nelson",-25200,-25200,)
    this.addTimezone("America/Fort Wayne",-18000,-14400,)
    this.addTimezone("America/Fortaleza",-10800,-10800,)
    this.addTimezone("America/Glace Bay",-14400,-10800,)
    this.addTimezone("America/Godthab",-10800,-7200,)
    this.addTimezone("America/Goose Bay",-14400,-10800,)
    this.addTimezone("America/Grand Turk",-14400,-14400,)
    this.addTimezone("America/Grenada",-14400,-14400,)
    this.addTimezone("America/Guadeloupe",-14400,-14400,)
    this.addTimezone("America/Guatemala",-21600,-21600,)
    this.addTimezone("America/Guayaquil",-18000,-18000,)
    this.addTimezone("America/Guyana",-14400,-14400,)
    this.addTimezone("America/Halifax",-14400,-10800,)
    this.addTimezone("America/Havana",-18000,-14400,)
    this.addTimezone("America/Hermosillo",-25200,-25200,)
    this.addTimezone("America/Indiana/Indianapolis",-18000,-14400,)
    this.addTimezone("America/Indiana/Knox",-21600,-18000,)
    this.addTimezone("America/Indiana/Marengo",-18000,-14400,)
    this.addTimezone("America/Indiana/Petersburg",-18000,-14400,)
    this.addTimezone("America/Indiana/Tell City",-21600,-18000,)
    this.addTimezone("America/Indiana/Vevay",-18000,-14400,)
    this.addTimezone("America/Indiana/Vincennes",-18000,-14400,)
    this.addTimezone("America/Indiana/Winamac",-18000,-14400,)
    this.addTimezone("America/Indianapolis",-18000,-14400,)
    this.addTimezone("America/Inuvik",-25200,-21600,)
    this.addTimezone("America/Iqaluit",-18000,-14400,)
    this.addTimezone("America/Jamaica",-18000,-18000,)
    this.addTimezone("America/Jujuy",-10800,-10800,)
    this.addTimezone("America/Juneau",-32400,-28800,)
    this.addTimezone("America/Kentucky/Louisville",-18000,-14400,)
    this.addTimezone("America/Kentucky/Monticello",-18000,-14400,)
    this.addTimezone("America/Knox IN",-21600,-18000,)
    this.addTimezone("America/Kralendijk",-14400,-14400,)
    this.addTimezone("America/La Paz",-14400,-14400,)
    this.addTimezone("America/Lima",-18000,-18000,)
    this.addTimezone("America/Los Angeles",-28800,-25200,)
    this.addTimezone("America/Louisville",-18000,-14400,)
    this.addTimezone("America/Lower Princes",-14400,-14400,)
    this.addTimezone("America/Maceio",-10800,-10800,)
    this.addTimezone("America/Managua",-21600,-21600,)
    this.addTimezone("America/Manaus",-14400,-14400,)
    this.addTimezone("America/Marigot",-14400,-14400,)
    this.addTimezone("America/Martinique",-14400,-14400,)
    this.addTimezone("America/Matamoros",-21600,-18000,)
    this.addTimezone("America/Mazatlan",-25200,-21600,)
    this.addTimezone("America/Mendoza",-10800,-10800,)
    this.addTimezone("America/Menominee",-21600,-18000,)
    this.addTimezone("America/Merida",-21600,-18000,)
    this.addTimezone("America/Metlakatla",-28800,-28800,)
    this.addTimezone("America/Mexico City",-21600,-18000,)
    this.addTimezone("America/Miquelon",-10800,-7200,)
    this.addTimezone("America/Moncton",-14400,-10800,)
    this.addTimezone("America/Monterrey",-21600,-18000,)
    this.addTimezone("America/Montevideo",-10800,-7200,)
    this.addTimezone("America/Montreal",-18000,-14400,)
    this.addTimezone("America/Montserrat",-14400,-14400,)
    this.addTimezone("America/Nassau",-18000,-14400,)
    this.addTimezone("America/New York",-18000,-14400,)
    this.addTimezone("America/Nipigon",-18000,-14400,)
    this.addTimezone("America/Nome",-32400,-28800,)
    this.addTimezone("America/Noronha",-7200,-7200,)
    this.addTimezone("America/North Dakota/Beulah",-21600,-18000,)
    this.addTimezone("America/North Dakota/Center",-21600,-18000,)
    this.addTimezone("America/North Dakota/New Salem",-21600,-18000,)
    this.addTimezone("America/Ojinaga",-25200,-21600,)
    this.addTimezone("America/Panama",-18000,-18000,)
    this.addTimezone("America/Pangnirtung",-18000,-14400,)
    this.addTimezone("America/Paramaribo",-10800,-10800,)
    this.addTimezone("America/Phoenix",-25200,-25200,)
    this.addTimezone("America/Port-au-Prince",-18000,-14400,)
    this.addTimezone("America/Port of Spain",-14400,-14400,)
    this.addTimezone("America/Porto Acre",-18000,-18000,)
    this.addTimezone("America/Porto Velho",-14400,-14400,)
    this.addTimezone("America/Puerto Rico",-14400,-14400,)
    this.addTimezone("America/Rainy River",-21600,-18000,)
    this.addTimezone("America/Rankin Inlet",-21600,-18000,)
    this.addTimezone("America/Recife",-10800,-10800,)
    this.addTimezone("America/Regina",-21600,-21600,)
    this.addTimezone("America/Resolute",-21600,-18000,)
    this.addTimezone("America/Rio Branco",-18000,-18000,)
    this.addTimezone("America/Rosario",-10800,-10800,)
    this.addTimezone("America/Santa Isabel",-28800,-25200,)
    this.addTimezone("America/Santarem",-10800,-10800,)
    this.addTimezone("America/Santiago",-10800,-10800,)
    this.addTimezone("America/Santo Domingo",-14400,-14400,)
    this.addTimezone("America/Sao Paulo",-10800,-7200,)
    this.addTimezone("America/Scoresbysund",-3600,0,)
    this.addTimezone("America/Shiprock",-25200,-21600,)
    this.addTimezone("America/Sitka",-32400,-28800,)
    this.addTimezone("America/St Barthelemy",-14400,-14400,)
    this.addTimezone("America/St Johns",-12600,-9000,)
    this.addTimezone("America/St Kitts",-14400,-14400,)
    this.addTimezone("America/St Lucia",-14400,-14400,)
    this.addTimezone("America/St Thomas",-14400,-14400,)
    this.addTimezone("America/St Vincent",-14400,-14400,)
    this.addTimezone("America/Swift Current",-21600,-21600,)
    this.addTimezone("America/Tegucigalpa",-21600,-21600,)
    this.addTimezone("America/Thule",-14400,-10800,)
    this.addTimezone("America/Thunder Bay",-18000,-14400,)
    this.addTimezone("America/Tijuana",-28800,-25200,)
    this.addTimezone("America/Toronto",-18000,-14400,)
    this.addTimezone("America/Tortola",-14400,-14400,)
    this.addTimezone("America/Vancouver",-28800,-25200,)
    this.addTimezone("America/Virgin",-14400,-14400,)
    this.addTimezone("America/Whitehorse",-28800,-25200,)
    this.addTimezone("America/Winnipeg",-21600,-18000,)
    this.addTimezone("America/Yakutat",-32400,-28800,)
    this.addTimezone("America/Yellowknife",-25200,-21600,)
    this.addTimezone("Antarctica/Casey",28800,28800,)
    this.addTimezone("Antarctica/Davis",25200,25200,)
    this.addTimezone("Antarctica/DumontDUrville",36000,36000,)
    this.addTimezone("Antarctica/Macquarie",39600,39600,)
    this.addTimezone("Antarctica/Mawson",18000,18000,)
    this.addTimezone("Antarctica/McMurdo",43200,46800,)
    this.addTimezone("Antarctica/Palmer",-10800,-10800,)
    this.addTimezone("Antarctica/Rothera",-10800,-10800,)
    this.addTimezone("Antarctica/South Pole",43200,46800,)
    this.addTimezone("Antarctica/Syowa",10800,10800,)
    this.addTimezone("Antarctica/Troll",0,7200,)
    this.addTimezone("Antarctica/Vostok",21600,21600,)
    this.addTimezone("Arctic/Longyearbyen",3600,7200,)
    this.addTimezone("Asia/Aden",10800,10800,)
    this.addTimezone("Asia/Almaty",21600,21600,)
    this.addTimezone("Asia/Amman",7200,10800,)
    this.addTimezone("Asia/Anadyr",43200,43200,)
    this.addTimezone("Asia/Aqtau",18000,18000,)
    this.addTimezone("Asia/Aqtobe",18000,18000,)
    this.addTimezone("Asia/Ashgabat",18000,18000,)
    this.addTimezone("Asia/Ashkhabad",18000,18000,)
    this.addTimezone("Asia/Baghdad",10800,10800,)
    this.addTimezone("Asia/Bahrain",10800,10800,)
    this.addTimezone("Asia/Baku",14400,18000,)
    this.addTimezone("Asia/Bangkok",25200,25200,)
    this.addTimezone("Asia/Beirut",7200,10800,)
    this.addTimezone("Asia/Bishkek",21600,21600,)
    this.addTimezone("Asia/Brunei",28800,28800,)
    this.addTimezone("Asia/Calcutta",19800,19800,)
    this.addTimezone("Asia/Chita",28800,28800,)
    this.addTimezone("Asia/Choibalsan",28800,32400,)
    this.addTimezone("Asia/Chongqing",28800,28800,)
    this.addTimezone("Asia/Chungking",28800,28800,)
    this.addTimezone("Asia/Colombo",19800,19800,)
    this.addTimezone("Asia/Dacca",21600,21600,)
    this.addTimezone("Asia/Damascus",7200,10800,)
    this.addTimezone("Asia/Dhaka",21600,21600,)
    this.addTimezone("Asia/Dili",32400,32400,)
    this.addTimezone("Asia/Dubai",14400,14400,)
    this.addTimezone("Asia/Dushanbe",18000,18000,)
    this.addTimezone("Asia/Gaza",7200,10800,)
    this.addTimezone("Asia/Harbin",28800,28800,)
    this.addTimezone("Asia/Hebron",7200,10800,)
    this.addTimezone("Asia/Ho Chi Minh",25200,25200,)
    this.addTimezone("Asia/Hong Kong",28800,28800,)
    this.addTimezone("Asia/Hovd",25200,28800,)
    this.addTimezone("Asia/Irkutsk",28800,28800,)
    this.addTimezone("Asia/Istanbul",7200,10800,)
    this.addTimezone("Asia/Jakarta",25200,25200,)
    this.addTimezone("Asia/Jayapura",32400,32400,)
    this.addTimezone("Asia/Jerusalem",7200,10800,)
    this.addTimezone("Asia/Kabul",16200,16200,)
    this.addTimezone("Asia/Kamchatka",43200,43200,)
    this.addTimezone("Asia/Karachi",18000,18000,)
    this.addTimezone("Asia/Kashgar",21600,21600,)
    this.addTimezone("Asia/Kathmandu",20700,20700,)
    this.addTimezone("Asia/Katmandu",20700,20700,)
    this.addTimezone("Asia/Khandyga",32400,32400,)
    this.addTimezone("Asia/Kolkata",19800,19800,)
    this.addTimezone("Asia/Krasnoyarsk",25200,25200,)
    this.addTimezone("Asia/Kuala Lumpur",28800,28800,)
    this.addTimezone("Asia/Kuching",28800,28800,)
    this.addTimezone("Asia/Kuwait",10800,10800,)
    this.addTimezone("Asia/Macao",28800,28800,)
    this.addTimezone("Asia/Macau",28800,28800,)
    this.addTimezone("Asia/Magadan",36000,36000,)
    this.addTimezone("Asia/Makassar",28800,28800,)
    this.addTimezone("Asia/Manila",28800,28800,)
    this.addTimezone("Asia/Muscat",14400,14400,)
    this.addTimezone("Asia/Nicosia",7200,10800,)
    this.addTimezone("Asia/Novokuznetsk",25200,25200,)
    this.addTimezone("Asia/Novosibirsk",21600,21600,)
    this.addTimezone("Asia/Omsk",21600,21600,)
    this.addTimezone("Asia/Oral",18000,18000,)
    this.addTimezone("Asia/Phnom Penh",25200,25200,)
    this.addTimezone("Asia/Pontianak",25200,25200,)
    this.addTimezone("Asia/Pyongyang",30600,30600,)
    this.addTimezone("Asia/Qatar",10800,10800,)
    this.addTimezone("Asia/Qyzylorda",21600,21600,)
    this.addTimezone("Asia/Rangoon",23400,23400,)
    this.addTimezone("Asia/Riyadh",10800,10800,)
    this.addTimezone("Asia/Riyadh87",10800,10800,)
    this.addTimezone("Asia/Riyadh88",10800,10800,)
    this.addTimezone("Asia/Riyadh89",10800,10800,)
    this.addTimezone("Asia/Saigon",25200,25200,)
    this.addTimezone("Asia/Sakhalin",36000,36000,)
    this.addTimezone("Asia/Samarkand",18000,18000,)
    this.addTimezone("Asia/Seoul",32400,32400,)
    this.addTimezone("Asia/Shanghai",28800,28800,)
    this.addTimezone("Asia/Singapore",28800,28800,)
    this.addTimezone("Asia/Srednekolymsk",39600,39600,)
    this.addTimezone("Asia/Taipei",28800,28800,)
    this.addTimezone("Asia/Tashkent",18000,18000,)
    this.addTimezone("Asia/Tbilisi",14400,14400,)
    this.addTimezone("Asia/Tehran",12600,16200,)
    this.addTimezone("Asia/Tel Aviv",7200,10800,)
    this.addTimezone("Asia/Thimbu",21600,21600,)
    this.addTimezone("Asia/Thimphu",21600,21600,)
    this.addTimezone("Asia/Tokyo",32400,32400,)
    this.addTimezone("Asia/Ujung Pandang",28800,28800,)
    this.addTimezone("Asia/Ulaanbaatar",28800,32400,)
    this.addTimezone("Asia/Ulan Bator",28800,32400,)
    this.addTimezone("Asia/Urumqi",21600,21600,)
    this.addTimezone("Asia/Vientiane",25200,25200,)
    this.addTimezone("Asia/Vladivostok",36000,36000,)
    this.addTimezone("Asia/Yakutsk",32400,32400,)
    this.addTimezone("Asia/Yekaterinburg",18000,18000,)
    this.addTimezone("Asia/Yerevan",14400,14400,)
    this.addTimezone("Atlantic/Azores",-3600,0,)
    this.addTimezone("Atlantic/Bermuda",-14400,-10800,)
    this.addTimezone("Atlantic/Canary",0,3600,)
    this.addTimezone("Atlantic/Cape Verde",-3600,-3600,)
    this.addTimezone("Atlantic/Faeroe",0,3600,)
    this.addTimezone("Atlantic/Faroe",0,3600,)
    this.addTimezone("Atlantic/Jan Mayen",3600,7200,)
    this.addTimezone("Atlantic/Madeira",0,3600,)
    this.addTimezone("Atlantic/Reykjavik",0,0,)
    this.addTimezone("Atlantic/South Georgia",-7200,-7200,)
    this.addTimezone("Atlantic/St Helena",0,0,)
    this.addTimezone("Atlantic/Stanley",-10800,-10800,)
    this.addTimezone("Australia/ACT",36000,39600,)
    this.addTimezone("Australia/Adelaide",34200,37800,)
    this.addTimezone("Australia/Brisbane",36000,36000,)
    this.addTimezone("Australia/Broken Hill",34200,37800,)
    this.addTimezone("Australia/Canberra",36000,39600,)
    this.addTimezone("Australia/Currie",36000,39600,)
    this.addTimezone("Australia/Darwin",34200,34200,)
    this.addTimezone("Australia/Eucla",31500,31500,)
    this.addTimezone("Australia/Hobart",36000,39600,)
    this.addTimezone("Australia/LHI",37800,39600,)
    this.addTimezone("Australia/Lindeman",36000,36000,)
    this.addTimezone("Australia/Lord Howe",37800,39600,)
    this.addTimezone("Australia/Melbourne",36000,39600,)
    this.addTimezone("Australia/NSW",36000,39600,)
    this.addTimezone("Australia/North",34200,34200,)
    this.addTimezone("Australia/Perth",28800,28800,)
    this.addTimezone("Australia/Queensland",36000,36000,)
    this.addTimezone("Australia/South",34200,37800,)
    this.addTimezone("Australia/Sydney",36000,39600,)
    this.addTimezone("Australia/Tasmania",36000,39600,)
    this.addTimezone("Australia/Victoria",36000,39600,)
    this.addTimezone("Australia/West",28800,28800,)
    this.addTimezone("Australia/Yancowinna",34200,37800,)
    this.addTimezone("Brazil/Acre",-18000,-18000,)
    this.addTimezone("Brazil/DeNoronha",-7200,-7200,)
    this.addTimezone("Brazil/East",-10800,-7200,)
    this.addTimezone("Brazil/West",-14400,-14400,)
    this.addTimezone("CET",3600,7200,)
    this.addTimezone("CST6CDT",-21600,-18000,)
    this.addTimezone("Canada/Atlantic",-14400,-10800,)
    this.addTimezone("Canada/Central",-21600,-18000,)
    this.addTimezone("Canada/East-Saskatchewan",-21600,-21600,)
    this.addTimezone("Canada/Eastern",-18000,-14400,)
    this.addTimezone("Canada/Mountain",-25200,-21600,)
    this.addTimezone("Canada/Newfoundland",-12600,-9000,)
    this.addTimezone("Canada/Pacific",-28800,-25200,)
    this.addTimezone("Canada/Saskatchewan",-21600,-21600,)
    this.addTimezone("Canada/Yukon",-28800,-25200,)
    this.addTimezone("Chile/Continental",-10800,-10800,)
    this.addTimezone("Chile/EasterIsland",-18000,-18000,)
    this.addTimezone("Cuba",-18000,-14400,)
    this.addTimezone("EET",7200,10800,)
    this.addTimezone("EST",-18000,-18000,)
    this.addTimezone("EST5EDT",-18000,-14400,)
    this.addTimezone("Egypt",7200,7200,)
    this.addTimezone("Eire",0,3600,)
    this.addTimezone("Etc/GMT",0,0,)
    this.addTimezone("Etc/GMT+0",0,0,)
    this.addTimezone("Etc/GMT+1",-3600,-3600,)
    this.addTimezone("Etc/GMT+10",-36000,-36000,)
    this.addTimezone("Etc/GMT+11",-39600,-39600,)
    this.addTimezone("Etc/GMT+12",-43200,-43200,)
    this.addTimezone("Etc/GMT+2",-7200,-7200,)
    this.addTimezone("Etc/GMT+3",-10800,-10800,)
    this.addTimezone("Etc/GMT+4",-14400,-14400,)
    this.addTimezone("Etc/GMT+5",-18000,-18000,)
    this.addTimezone("Etc/GMT+6",-21600,-21600,)
    this.addTimezone("Etc/GMT+7",-25200,-25200,)
    this.addTimezone("Etc/GMT+8",-28800,-28800,)
    this.addTimezone("Etc/GMT+9",-32400,-32400,)
    this.addTimezone("Etc/GMT-0",0,0,)
    this.addTimezone("Etc/GMT-1",3600,3600,)
    this.addTimezone("Etc/GMT-10",36000,36000,)
    this.addTimezone("Etc/GMT-11",39600,39600,)
    this.addTimezone("Etc/GMT-12",43200,43200,)
    this.addTimezone("Etc/GMT-13",46800,46800,)
    this.addTimezone("Etc/GMT-14",50400,50400,)
    this.addTimezone("Etc/GMT-2",7200,7200,)
    this.addTimezone("Etc/GMT-3",10800,10800,)
    this.addTimezone("Etc/GMT-4",14400,14400,)
    this.addTimezone("Etc/GMT-5",18000,18000,)
    this.addTimezone("Etc/GMT-6",21600,21600,)
    this.addTimezone("Etc/GMT-7",25200,25200,)
    this.addTimezone("Etc/GMT-8",28800,28800,)
    this.addTimezone("Etc/GMT-9",32400,32400,)
    this.addTimezone("Etc/GMT0",0,0,)
    this.addTimezone("Etc/Greenwich",0,0,)
    this.addTimezone("Etc/UCT",0,0,)
    this.addTimezone("Etc/UTC",0,0,)
    this.addTimezone("Etc/Universal",0,0,)
    this.addTimezone("Etc/Zulu",0,0,)
    this.addTimezone("Europe/Amsterdam",3600,7200,)
    this.addTimezone("Europe/Andorra",3600,7200,)
    this.addTimezone("Europe/Athens",7200,10800,)
    this.addTimezone("Europe/Belfast",0,3600,)
    this.addTimezone("Europe/Belgrade",3600,7200,)
    this.addTimezone("Europe/Berlin",3600,7200,)
    this.addTimezone("Europe/Bratislava",3600,7200,)
    this.addTimezone("Europe/Brussels",3600,7200,)
    this.addTimezone("Europe/Bucharest",7200,10800,)
    this.addTimezone("Europe/Budapest",3600,7200,)
    this.addTimezone("Europe/Busingen",3600,7200,)
    this.addTimezone("Europe/Chisinau",7200,10800,)
    this.addTimezone("Europe/Copenhagen",3600,7200,)
    this.addTimezone("Europe/Dublin",0,3600,)
    this.addTimezone("Europe/Gibraltar",3600,7200,)
    this.addTimezone("Europe/Guernsey",0,3600,)
    this.addTimezone("Europe/Helsinki",7200,10800,)
    this.addTimezone("Europe/Isle of Man",0,3600,)
    this.addTimezone("Europe/Istanbul",7200,10800,)
    this.addTimezone("Europe/Jersey",0,3600,)
    this.addTimezone("Europe/Kaliningrad",7200,10800,)
    this.addTimezone("Europe/Kiev",7200,10800,)
    this.addTimezone("Europe/Lisbon",0,3600,)
    this.addTimezone("Europe/Ljubljana",3600,7200,)
    this.addTimezone("Europe/London",0,3600,)
    this.addTimezone("Europe/Luxembourg",3600,7200,)
    this.addTimezone("Europe/Madrid",3600,7200,)
    this.addTimezone("Europe/Malta",3600,7200,)
    this.addTimezone("Europe/Mariehamn",7200,10800,)
    this.addTimezone("Europe/Minsk",10800,10800,)
    this.addTimezone("Europe/Monaco",3600,7200,)
    this.addTimezone("Europe/Moscow",10800,10800,)
    this.addTimezone("Europe/Nicosia",7200,10800,)
    this.addTimezone("Europe/Oslo",3600,7200,)
    this.addTimezone("Europe/Paris",3600,7200,)
    this.addTimezone("Europe/Podgorica",3600,7200,)
    this.addTimezone("Europe/Prague",3600,7200,)
    this.addTimezone("Europe/Riga",7200,10800,)
    this.addTimezone("Europe/Rome",3600,7200,)
    this.addTimezone("Europe/Samara",14400,14400,)
    this.addTimezone("Europe/San Marino",3600,7200,)
    this.addTimezone("Europe/Sarajevo",3600,7200,)
    this.addTimezone("Europe/Simferopol",7200,10800,)
    this.addTimezone("Europe/Skopje",3600,7200,)
    this.addTimezone("Europe/Sofia",7200,10800,)
    this.addTimezone("Europe/Stockholm",3600,7200,)
    this.addTimezone("Europe/Tallinn",7200,10800,)
    this.addTimezone("Europe/Tirane",3600,7200,)
    this.addTimezone("Europe/Tiraspol",7200,10800,)
    this.addTimezone("Europe/Uzhgorod",7200,10800,)
    this.addTimezone("Europe/Vaduz",3600,7200,)
    this.addTimezone("Europe/Vatican",3600,7200,)
    this.addTimezone("Europe/Vienna",3600,7200,)
    this.addTimezone("Europe/Vilnius",7200,10800,)
    this.addTimezone("Europe/Volgograd",10800,10800,)
    this.addTimezone("Europe/Warsaw",3600,7200,)
    this.addTimezone("Europe/Zagreb",3600,7200,)
    this.addTimezone("Europe/Zaporozhye",7200,10800,)
    this.addTimezone("Europe/Zurich",3600,7200,)
    this.addTimezone("Factory",0,0,)
    this.addTimezone("GB",0,3600,)
    this.addTimezone("GB-Eire",0,3600,)
    this.addTimezone("GMT",0,0,)
    this.addTimezone("GMT+0",0,0,)
    this.addTimezone("GMT-0",0,0,)
    this.addTimezone("GMT0",0,0,)
    this.addTimezone("Greenwich",0,0,)
    this.addTimezone("HST",-36000,-36000,)
    this.addTimezone("Hongkong",28800,28800,)
    this.addTimezone("Iceland",0,0,)
    this.addTimezone("Indian/Antananarivo",10800,10800,)
    this.addTimezone("Indian/Chagos",21600,21600,)
    this.addTimezone("Indian/Christmas",25200,25200,)
    this.addTimezone("Indian/Cocos",23400,23400,)
    this.addTimezone("Indian/Comoro",10800,10800,)
    this.addTimezone("Indian/Kerguelen",18000,18000,)
    this.addTimezone("Indian/Mahe",14400,14400,)
    this.addTimezone("Indian/Maldives",18000,18000,)
    this.addTimezone("Indian/Mauritius",14400,14400,)
    this.addTimezone("Indian/Mayotte",10800,10800,)
    this.addTimezone("Indian/Reunion",14400,14400,)
    this.addTimezone("Iran",12600,16200,)
    this.addTimezone("Israel",7200,10800,)
    this.addTimezone("Jamaica",-18000,-18000,)
    this.addTimezone("Japan",32400,32400,)
    this.addTimezone("Kwajalein",43200,43200,)
    this.addTimezone("Libya",7200,7200,)
    this.addTimezone("MET",3600,7200,)
    this.addTimezone("MST",-25200,-25200,)
    this.addTimezone("MST7MDT",-25200,-21600,)
    this.addTimezone("Mexico/BajaNorte",-28800,-25200,)
    this.addTimezone("Mexico/BajaSur",-25200,-21600,)
    this.addTimezone("Mexico/General",-21600,-18000,)
    this.addTimezone("Mideast/Riyadh87",0,0,)
    this.addTimezone("Mideast/Riyadh88",0,0,)
    this.addTimezone("Mideast/Riyadh89",0,0,)
    this.addTimezone("NZ",43200,46800,)
    this.addTimezone("NZ-CHAT",45900,49500,)
    this.addTimezone("Navajo",0,0,)
    this.addTimezone("PRC",0,0,)
    this.addTimezone("PST8PDT",0,0,)
    this.addTimezone("Pacific/Apia",46800,50400,)
    this.addTimezone("Pacific/Auckland",43200,46800,)
    this.addTimezone("Pacific/Bougainville",39600,39600,)
    this.addTimezone("Pacific/Chatham",45900,49500,)
    this.addTimezone("Pacific/Chuuk",36000,36000,)
    this.addTimezone("Pacific/Easter",-18000,-18000,)
    this.addTimezone("Pacific/Efate",39600,39600,)
    this.addTimezone("Pacific/Enderbury",46800,46800,)
    this.addTimezone("Pacific/Fakaofo",46800,46800,)
    this.addTimezone("Pacific/Fiji",43200,46800,)
    this.addTimezone("Pacific/Funafuti",43200,43200,)
    this.addTimezone("Pacific/Galapagos",-21600,-21600,)
    this.addTimezone("Pacific/Gambier",-32400,-32400,)
    this.addTimezone("Pacific/Guadalcanal",39600,39600,)
    this.addTimezone("Pacific/Guam",36000,36000,)
    this.addTimezone("Pacific/Honolulu",-36000,-36000,)
    this.addTimezone("Pacific/Johnston",-36000,-36000,)
    this.addTimezone("Pacific/Kiritimati",50400,50400,)
    this.addTimezone("Pacific/Kosrae",39600,39600,)
    this.addTimezone("Pacific/Kwajalein",43200,43200,)
    this.addTimezone("Pacific/Majuro",43200,43200,)
    this.addTimezone("Pacific/Marquesas",-34200,-34200,)
    this.addTimezone("Pacific/Midway",-39600,-39600,)
    this.addTimezone("Pacific/Nauru",43200,43200,)
    this.addTimezone("Pacific/Niue",-39600,-39600,)
    this.addTimezone("Pacific/Norfolk",39600,39600,)
    this.addTimezone("Pacific/Noumea",39600,39600,)
    this.addTimezone("Pacific/Pago Pago",-39600,-39600,)
    this.addTimezone("Pacific/Palau",32400,32400,)
    this.addTimezone("Pacific/Pitcairn",-28800,-28800,)
    this.addTimezone("Pacific/Ponape",39600,39600,)
    this.addTimezone("Pacific/Port Moresby",36000,36000,)
    this.addTimezone("Pacific/Rarotonga",-36000,-36000,)
    this.addTimezone("Pacific/Saipan",36000,36000,)
    this.addTimezone("Pacific/Samoa",-39600,-39600,)
    this.addTimezone("Pacific/Tahiti",-36000,-36000,)
    this.addTimezone("Pacific/Tarawa",43200,43200,)
    this.addTimezone("Pacific/Tongatapu",46800,46800,)
    this.addTimezone("Pacific/Truk",36000,36000,)
    this.addTimezone("Pacific/Wake",43200,43200,)
    this.addTimezone("Pacific/Wallis",43200,43200,)
    this.addTimezone("Pacific/Yap",36000,36000,)
    this.addTimezone("Poland",3600,7200,)
    this.addTimezone("Portugal",0,3600,)
    this.addTimezone("ROC",28800,28800,)
    this.addTimezone("ROK",32400,32400,)
    this.addTimezone("Singapore",28800,28800,)
    this.addTimezone("Turkey",7200,10800,)
    this.addTimezone("UCT",0,0,)
    this.addTimezone("US/Alaska",-32400,-28800,)
    this.addTimezone("US/Aleutian",-36000,-32400,)
    this.addTimezone("US/Arizona",-25200,-25200,)
    this.addTimezone("US/Central",-21600,-18000,)
    this.addTimezone("US/East-Indiana",-18000,-14400,)
    this.addTimezone("US/Eastern",-18000,-14400,)
    this.addTimezone("US/Hawaii",-36000,-36000,)
    this.addTimezone("US/Indiana-Starke",-21600,-18000,)
    this.addTimezone("US/Michigan",-18000,-14400,)
    this.addTimezone("US/Mountain",-25200,-21600,)
    this.addTimezone("US/Pacific",-28800,-25200,)
    this.addTimezone("US/Pacific-New",-28800,-25200,)
    this.addTimezone("US/Samoa",-39600,-39600,)
    this.addTimezone("UTC",0,0,)
    this.addTimezone("Universal",0,0,)
    this.addTimezone("W-SU",10800,10800,)
    this.addTimezone("WET",0,3600,)
    this.addTimezone("Zulu",0,0,)
  }


  private addTimezone(id: string, offset: number, dst: number) {
    if(this.timezoneExists(id) == false) {
      let rec = {
        id: id,
        offset: offset,
        dst: dst
      }
      this._data.push(rec)
    } 
  }

}
