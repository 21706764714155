import {
	Component,
	ViewEncapsulation,
	ViewChild,
	Input,
	ChangeDetectorRef
} from '@angular/core';

import { UINavigation } from '@coreui/services/ui.navigation.service';

@Component({
	selector: 		'pm-main-routes',
	inputs: 			['route'],
	encapsulation: ViewEncapsulation.None,

	template:	`

			<div [ngSwitch]=this.uiNavigation.activeView>

				<div *ngSwitchCase="'home'">
					<home-screen></home-screen>>
				</div>

				<div *ngSwitchCase="'signup'">
				</div>

				<div *ngSwitchCase="'travel'">
					<travel-screen></travel-screen>
				</div>
				<div *ngSwitchCase="'travel01'">
					<travel01-screen></travel01-screen>
				</div>

				<div *ngSwitchCase="'dining'">
					<dining01-screen></dining01-screen>
				</div>

				<div *ngSwitchCase="'icons'">
					<icons-screen></icons-screen>
				</div>

				<div *ngSwitchCase="'discover'">
					<discover-screen></discover-screen>
				</div>

				<div *ngSwitchCase="'collections.pictures.manager'">
					<imagegallery-screen></imagegallery-screen>
				</div>
				<div *ngSwitchCase="'collections.gradients'">
					<gradients-collection-screen></gradients-collection-screen>
				</div>
				<div *ngSwitchCase="'collections.colors'">
					<colors-collection-screen></colors-collection-screen>
				</div>

				
				
			</div>

	`,

})


export class MainRoutes {
	@Input()
    set route(value: string) { this.setRoute(value); }

	 uiNavigation: UINavigation
	_route: string = "home"
	_opacity: number = 1

	samplePic1: string = "/assets/sample/media/indigo-restaurant.jpg"
	samplePic2: string = "/assets/sample/media/coco-mama.png"


	private setRoute(route: string): void {
		setTimeout(() => {
			this._opacity = 0
			setTimeout(() => {
				this._route = route
				this._opacity = 1
			}, 150);
		}, 10);
	}

	constructor(private _uiNavigation: UINavigation) {
		this.uiNavigation = _uiNavigation
	}

	ngOnInit() {
	}

	ngOnDestroy() {
	} 

	ngAfterViewInit() {

	}


}
