import {
	Component,
	ViewEncapsulation,
	ViewChild,
	ElementRef,
	HostListener,
	ChangeDetectorRef,
	Input
} from '@angular/core';

import { EventsService } from '../../services/event.service'
import { UILayout } from '../../services/ui.layout.service'


@Component({
	selector: 		'screen-content-container',
	encapsulation: ViewEncapsulation.None,
	inputs: ['nomargins'],
	template:	`

		<div class="scrollable-div-container" 
				[style.height.px]=this.containerHeight 
				[style.paddingLeft.px]=this.margins
				[style.paddingRight.px]=this.margins
				view-height
				scrollEventDetect>
			<ng-content></ng-content>
		</div>
	`,

})


export class ScreenContentContainer {
	
	@Input()
	set nomargins(value: boolean) {
	  this.setNoMargins(value);
	}

	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		this.recalculate()
	}


	private changeDetectorRef: ChangeDetectorRef 
    private eventsService: EventsService
	uiLayout: UILayout
	containerHeight: number | undefined = window.innerHeight
	noMargins: boolean = false
	margins: number = 0

	constructor(private ref: ChangeDetectorRef, private _eventsService: EventsService, private _uiLayout: UILayout) {
		this.changeDetectorRef = ref
		this.eventsService = _eventsService
		this.uiLayout = _uiLayout

		let self = this;
		this.eventsService.on('onRefresh', function (args: any) {
			self.recalculate()
		});		
	}


	setNoMargins(value: boolean) {
		this.noMargins = value
		this.recalculate()
	}

	recalculate() {
		this.containerHeight = window.innerHeight
		this.margins = this.uiLayout.windowMargin
		if(this.noMargins==true) {
			this.margins=0
		}
		this.changeDetectorRef.detectChanges()
	}

	ngAfterViewInit() {
		this.recalculate()
	}

	ngOnDestroy() {
	}


}
